//FIXME: This needs to be refactored to use standard js functions within connect
//BEGIN C506689: script specific for claim export. It needs to be refactored eventually.
function retrieveContent(status, ftaId) {
  jQuery.ajax({
    url: '/app/claimoffline/search/export/statuscontent',
    type: 'GET',
    data: {status: status, ftaId: ftaId},
    success: function (data) {
      jQuery('#claimMultiExportStatusContainter').html(data);
    }
  });
}

function getRequestStatus(tryNumber, requestId) {
  jQuery.ajax({
    url: '/app/claimoffline/search/export/status',
    type: 'GET',
    data: {requestId: requestId},
    success: function (data) {
      var response = JSON.parse(data);
      if (response.status == 'FAILED') {
        retrieveContent('failed', response.ftaId);
      } else if (response.status == 'PROCESSING' && tryNumber < 4) {
        retrieveContent('creating_file', response.ftaId);
        setTimeout(function () {
          getRequestStatus(tryNumber + 1, requestId);
        }, 4000);
      } else if (response.status == 'PROCESSING' && tryNumber >= 4) {
        retrieveContent('available_later', response.ftaId);
      } else if (response.status == 'DONE') {
        retrieveContent('download', response.ftaId);
      } else {
        retrieveContent('failed', response.ftaId);
      }
    },
    error: function (jqXHR, textStatus, errorThrown) {
      retrieveContent('failed', response.ftaId);
    }
  });
}
ht.declare('#claimMultiExportStatusContainter', function () {
  var requestId = $(this).data('requestId');
  getRequestStatus(1, requestId);
});
//END C506689
