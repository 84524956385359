// This gives us the ability to alter elements from the DOM based on events for the given element
ht.declare('.alterTargetOnEvent', function () {
  var opts = $(this).metadata().alterTargetOnEventOpts;
  if (
    opts != null &&
    opts.targetSelector != null &&
    opts.event != null &&
    opts.method != null
  ) {
    var args = opts.args != null ? opts.args : [];
    $(this).on(opts.event, function (evt) {
      var target = $(opts.targetSelector);
      var m = target[opts.method];
      m.apply(target, args);
    });
  }
});

//clear a text input when the input with this class changes
ht.declare('.clearTextInputOnEvent', function () {
  var opts = $(this).metadata().alterTargetOnEventOpts;
  if (opts != null && opts.targetSelector != null && opts.event != null) {
    $(this).on(opts.event, function (evt) {
      var target = $(opts.targetSelector);
      target.val('');
      target.trigger('change');
    });
  }
});
