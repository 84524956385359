ht.updateRequiredFields = function (context) {
  // mark labels for required fields
  var context = context != null ? context : $('form.applyValidate');
  context.find('input, select, textarea').each(function () {
    var inputName = $(this).attr('name');
    var inputId = $(this).attr('id');
    //both name and id take id or just id
    if (
      (inputName != null &&
        $.trim(inputName) != '' &&
        inputId != null &&
        $.trim(inputId) != '') ||
      (inputId != null && $.trim(inputId) != '')
    ) {
      var property = inputId;
    } else if (inputName != null && $.trim(inputName) != '') {
      //just name
      var property = inputName;
    }
    if (property) {
      if (
        $(this).data('ruleRequired') &&
        $(this).attr('aria-required') != 'true'
      ) {
        $(this).attr('aria-required', 'true');
        context
          .find("label[for='" + property + "']")
          .not('label.error, label:has(em.required)')
          .append(
            "&nbsp;<em class='required ht1-InputRequiredAsterisk ht2-InputRequiredAsterisk'>*</em>"
          );
      } else if ($(this).attr('aria-required') == 'true') {
        $(this).removeAttr('aria-required');
        context
          .find("label[for='" + $(this).attr('name') + "'] > em.required")
          .remove();
      }
    }
  });
};
