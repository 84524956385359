ht.populateCarousels = function () {
  $('.carousel.carouselSlideTarget').each(function () {
    var target = $(this),
      targetId = target.attr('id'),
      carouselInner = target.find('.carousel-inner'),
      carouselIndicators = target.find('.carousel-indicators'),
      itemSelector =
        ".carouselSlideContainer[data-carousel-target='#" +
        targetId +
        "'] .item";

    if (targetId && $(itemSelector).length > 0) {
      if (carouselInner.length < 1) {
        carouselInner = $('<div class="carousel-inner" />');
        target.append(carouselInner);
      }
      if (carouselIndicators.length < 1) {
        carouselIndicators = $('<ol class="carousel-indicators" />');
        target.append(carouselIndicators);
      }
      $(itemSelector).each(function () {
        carouselInner.append($(this));
        carouselIndicators.append(
          $(
            '<li data-target="#' +
              targetId +
              '" data-slide-to="' +
              carouselIndicators.find('li').length +
              '"> </li>'
          )
        );
      });

      //TODO: set an optional flag or this. default with no arrows
      //add directional arrows
      carouselInner.after(
        "<a class='carousel-control left icon-chevron-left' href='#" +
          targetId +
          "' data-slide='prev'></a><a class='carousel-control right icon-chevron-right' href='#" +
          targetId +
          "' data-slide='next'></a>"
      );
      carouselInner.find('.item').removeClass('active');
      carouselInner.find('.item:first-of-type').addClass('active');
      carouselIndicators.find('li').removeClass('active');
      carouselIndicators.find('li:first-of-type').addClass('active');
      if (target.data('rideOnPopulation') && $.fn.carousel != null) {
        target.carousel({
          interval: 5000,
          pause: 'hover',
          wrap: true
        });
      }
    }
  });
};
