var has = Object.prototype.hasOwnProperty;
function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}
function objectForEach(obj, cb) {
  if (isObject(obj)) {
    return Object.entries(obj).forEach(function (objProperty) {
      var key = objProperty[0];
      var value = objProperty[1];
      return cb(key, value);
    });
  }
}
function deepMerge(target, sources) {
  if (!sources.length) {
    return target;
  }
  var source = sources.shift();
  if (isObject(target) && isObject(source)) {
    Object.entries(source).forEach(function (sourceObjectEntry) {
      var sourceObjectKey = sourceObjectEntry[0];
      var sourceObjectValue = sourceObjectEntry[1];
      if (isObject(sourceObjectValue)) {
        if (!target[sourceObjectKey]) {
          target[sourceObjectKey] = {};
        }
        deepMerge(target[sourceObjectKey], [sourceObjectValue]);
      } else {
        target[sourceObjectKey] = sourceObjectValue;
      }
    });
  }
  return deepMerge(target, sources);
}
function getDefaults() {
  return {
    headers: {
      'X-ClientApp': 'app',
      'X-ClientIdentity': htc.global.clientState.activeIdentity,
      'X-ClientLocale': htc.global.clientState.locale
    },
    timeout: 30000
  };
}

function Hp2WebAPIBase(
  config,
  transformRequestCallback,
  transformDataCallback,
  transformResponseCallback
) {
  this.defaults = config;
  this.transformRequest = transformRequestCallback;
  this.transformData = transformDataCallback;
  this.transformResponse = transformResponseCallback;
}

Hp2WebAPIBase.prototype.request = function (config) {
  var requestConfig = deepMerge({}, [this.defaults, config]);
  var transformRequest = this.transformRequest;
  var transformData = this.transformData;
  var transformResponse = this.transformResponse;

  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    xhr.open(requestConfig.method, requestConfig.url, true);
    xhr.timeout = requestConfig.timeout;
    transformRequest(requestConfig, xhr);
    var requestBody = requestConfig.data
      ? transformData(requestConfig.data)
      : null;

    objectForEach(requestConfig.headers, function (key, value) {
      if (
        typeof requestBody === 'undefined' &&
        key.toLowerCase() === 'content-type'
      ) {
        delete requestConfig.headers[key];
      } else {
        xhr.setRequestHeader(key, value);
      }
    });

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(transformResponse(xhr.response));
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.send(requestBody);
  });
};

/* This is inside a DOMContentLoaded function because
 * we need to instantiate our Hp2 api helper with values from
 * htc.global.clientState, which isn't available
 * until DOM content has been loaded. */
document.addEventListener('DOMContentLoaded', function () {
  if (has.call(window, 'htc')) {
    ht.Hp2JSONAPI = new Hp2WebAPIBase(
      getDefaults(),
      function (config, request) {
        request.setRequestHeader('Content-Type', 'application/json');
      },
      function (data) {
        return JSON.stringify(data);
      },
      function (response) {
        return JSON.parse(response);
      }
    );
  }
});

/* Usage - It just returns a promise */

/* Below is an example of a single request
ht.Hp2JSONAPI.request({
  url: "/web-api/ust/v1/commprefs/updateMemberPreferences",
  method: "POST",
  data: payload
}).then(function() {
  ht.modalOpen({
    modalWidth: '400px',
    modalHeight: '300px',
    modalContent: 'The request has been successful!'
  });
}).catch(function() {
  ht.modalOpen({
    modalWidth: '400px',
    modalHeight: '300px',
    modalContent: 'Uh oh, something bad happened and guess what, its all your fault.'
  });
});
*/

/* Those promises can be iterated over. Below is an example of three requests, and we wait until all 3 promises resolve until we do something with the payloads. */
/*
var listTransportsRequest = ht.Hp2JSONAPI.request({
  url: "/web-api/ust/v1/commprefs/listTransports",
  method: "POST",
  data: {}
});

var listLanguagesRequest = ht.Hp2JSONAPI.request({
  url: "/web-api/ust/v1/commprefs/listLanguages",
  method: "POST",
  data: {}
});

var listAuxillariesRequest = ht.Hp2JSONAPI.request({
  url: "/web-api/ust/v1/commprefs/listAuxiliary",
  method: "POST",
  data: {}
});

Promise.all([listTransportsRequest, listLanguagesRequest, listAuxillariesRequest]).then(function(values) {
  // We have values from all 3 requests here..
});
*/

/* Configs can be changed at the instance or individual request level */

/* helper level - will need to "export" / add to global namespace in the same
 * DomContentLoaded callback as above */
/*
var myXMLAPIConfig = getDefaults();
myXMLAPIConfig.timeout = 60000;
myXMLAPIConfig.headers["Accept-Encoding"] = "gzip, deflate, br";
ht.myXMLAPIWithLongTimeoutAndAnotherHeader = new Hp2WebAPIBase(myXMLAPIConfig,
  function (config, request) {
    request.setRequestHeader("Content-Type", "application/xml");
  },
  function (data) {
    return data
  },
  function (response) {
    return response;
  });
*/
/* individual level - config for an individual req will override the instance config */
/*
ht.Hp2JSONAPI.request({
  url: "/web-api/ust/v1/commprefs/listAuxiliary",
  method: "GET",
  data: {},
  timeout: 5000
});
*/
