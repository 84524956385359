// Magic Responsive Tables
// Converts tables into responsive tables. Please Read this Wiki article on how to make a table responsive: https://wiki.healthtrio.com/wiki/index.php/Responsive_Tables
// Originally Written by Austin Mutschler - December 2018
// Don't hate me if it's broken. This isn't meant to be a permanent solution. This is simply getting us by until our tables can be rewritten in React

jQuery(function () {
  function convertTable(tableElement, headerClass, tableNumber, i) {
    var table = tableElement;
    var headerArrayPointer;
    var headerArray;
    var tableChildren;
    var afterHeader = false;
    var rowCounter = 0;
    /* Whether the table has most if not all columns
     * on control, so we can't specify one primary column. */
    var hasUnreliableTableHeaders = jQuery(table)
      .find(headerClass)[0]
      .dataset.hasOwnProperty('unreliableTableHeaders');

    // This accounts for FTL tables that use both thead and tbody
    if (hasTHead(table)) {
      headerArrayPointer = getTHeadHeaderPointers(table);
      headerArray = getTHeadHeaderInnerText(headerArrayPointer);
      tableChildren = getTBodyChildren(table);
      afterHeader = true;
    } else {
      headerArray = getTableHeaders(table, headerClass);
      headerArrayPointer = jQuery(table).find(headerClass)[0].cells;
      tableChildren = table.getElementsByTagName('tbody')[0].children;
    }

    var rowHeaderIdentifier = getRowHeaderIdentifier(
      headerArrayPointer,
      hasUnreliableTableHeaders
    );
    var tableObject = {};

    // Creates Responsive Table Container
    var responsiveContainer = document.createElement('div');
    responsiveContainer.className =
      'responsiveTableContainer responsiveTableContainer_' + i;

    for (var i = 0; i < tableChildren.length; i++) {
      var childrenDataObject = tableChildren[i];

      if (
        childrenDataObject.className.indexOf(
          headerClass.substring(1, headerClass.length)
        ) >= 0 &&
        afterHeader === false
      ) {
        afterHeader = true;
        continue;
      }

      if (afterHeader) {
        tableObject = addDataToObject(
          tableObject,
          headerArray,
          childrenDataObject,
          rowCounter
        );
        rowCounter++;
      }

      // This puts the content above the header in the table at the top of the responsive table. This is used mainly for pages that rely on table data like "Claim Status Search Results for HP022112801"
      else {
        var clonedNode = childrenDataObject.cloneNode(true);
        // Todo: This is probably not robust enough to handle case in comment above
        var preserveTagsInRow =
          (clonedNode.hasAttribute(
            'data-responsive-table-preserve-tags-in-row'
          ) &&
            clonedNode.dataset.responsiveTablePreserveTagsInRow) ||
          false;
        var header = document.createElement('div');
        header.className = 'responsiveTable_aboveHeader';
        if (preserveTagsInRow) {
          jQuery(header).append(jQuery(clonedNode).find('*:not(td)'));
        } else {
          jQuery(header).append(clonedNode.textContent.trim());
        }
        jQuery(responsiveContainer).append(header);
      }
    }

    createResponsiveTable(
      tableObject,
      rowHeaderIdentifier,
      responsiveContainer,
      tableElement,
      headerArray,
      tableNumber
    );
  }

  function getTBodyChildren(table) {
    var tbodyChildrenArrayOfObjects;

    for (var i = 0; i < table.children.length; i++) {
      if (table.children[i].nodeName === 'TBODY') {
        tbodyChildrenArrayOfObjects = table.children[i];
      }
    }
    return tbodyChildrenArrayOfObjects.children;
  }

  function getTHeadHeaderPointers(table) {
    var theadChildrenArrayOfObjects;

    for (var i = 0; i < table.children.length; i++) {
      if (table.children[i].nodeName === 'THEAD') {
        theadChildrenArrayOfObjects = table.children[i].children[0].children;
        break;
      }
    }

    return theadChildrenArrayOfObjects;
  }

  function hasTHead(table) {
    for (var i = 0; i < table.children.length; i++) {
      if (table.children[i].nodeName === 'THEAD') {
        return true;
      }
    }
    return false;
  }

  function getTHeadHeaderInnerText(headerArrayPointer) {
    var theadHeaderArray = [];

    for (var i = 0; i < headerArrayPointer.length; i++) {
      theadHeaderArray.push(headerArrayPointer[i].innerText.trim()); // Adding trim due to span element inside thead returning extra white space
    }
    return theadHeaderArray;
  }

  function getTableHeaders(table, headerClass) {
    var headerArray = [];

    var headerCells = jQuery(table).find(headerClass)[0].cells;
    var colspanString = '';

    for (var i = 0; i < headerCells.length; i++) {
      var currHeader = headerCells[i];

      if (i === 0 && currHeader.hasAttribute('colspan')) {
        colspanString = '-|- colspan=' + currHeader.getAttribute('colspan');
      }

      // Checks if the current or children element nodeNames are in the special node name array. If they are it passes it to our specialNode handler which returns the appropriate header
      if (currHeader.children.length > 0) {
        if (isSpecialNode(currHeader.children[0])) {
          headerArray.push(
            specialNodeTypeHandler(currHeader.children[0], true) + colspanString
          );
          continue;
        } else if (
          currHeader.children[0].nodeName === 'A' &&
          currHeader.children[0].children.length > 0
        ) {
          if (isSpecialNode(currHeader.children[0])) {
            headerArray.push(
              specialNodeTypeHandler(currHeader.children[0].children[0], true) +
                colspanString
            );
            continue;
          } else {
            headerArray.push(
              currHeader.innerText.trim().replace(/\s\s+|\t+|\n+/g, '') +
                colspanString
            );
          }
        } else if (currHeader.children[0].children.length > 0) {
          if (isSpecialNode(currHeader.children[0].children[0])) {
            headerArray.push(
              specialNodeTypeHandler(currHeader.children[0].children[0], true) +
                colspanString
            );
            continue;
          } else {
            headerArray.push(
              currHeader.innerText.trim().replace(/\s\s+|\t+|\n+/g, '') +
                colspanString
            );
          }
        } else {
          headerArray.push(
            currHeader.innerText.trim().replace(/\s\s+|\t+|\n+/g, '') +
              colspanString
          );
        }
      } else {
        headerArray.push(
          currHeader.innerText.trim().replace(/\s\s+|\t+|\n+/g, '') +
            colspanString
        );
      }
    }

    return headerArray;
  }

  function isSpecialNode(element) {
    var specialNodeNameArray = [
      'IMG',
      'I',
      'BUTTON',
      'BTN',
      'INPUT',
      'FORM',
      'A'
    ];
    var specialClassArray = ['button', 'btn'];

    if (specialNodeNameArray.indexOf(element.nodeName) >= 0) {
      return true;
    } else {
      // Checks if the element has special class
      for (var i = 0; i < element.classList.length; i++) {
        if (specialClassArray.indexOf(element.classList[i]) >= 0) {
          return true;
        }
      }
    }
    return false;
  }

  // This function is called to convert a special node type HEADER to text or to handle it in a special way like inputs
  function specialNodeTypeHandler(element, textOnly) {
    if (textOnly) {
      switch (element.nodeName) {
        case 'IMG':
          if (element.hasAttribute('title')) {
            return element.getAttribute('title');
          } else if (element.hasAttribute('alt')) {
            return element.getAttribute('alt');
          } else {
            return 'IMG';
          }

        case 'I':
          if (element.hasAttribute('title')) {
            return element.getAttribute('title');
          } else if (element.hasAttribute('alt')) {
            return element.getAttribute('alt');
          } else if (element.hasAttribute('class')) {
            return (
              element.getAttribute('class').charAt(0).toUpperCase +
              element.getAttribute('class').substr(1).toLowerCase()
            );
          } else {
            return 'Icon';
          }

        case 'INPUT':
          if (
            element.hasAttribute('type') &&
            element.getAttribute('type') === 'checkbox'
          ) {
            if (element.hasAttribute('name')) {
              return 'Select';
            }
          } else if (element.hasAttribute('title')) {
            return element.getAttribute('title');
          } else {
            return 'INPUT';
          }

        case 'A':
          if (element.innerText.length > 0) {
            return element.innerText;
          } else if (element.hasAttribute('title')) {
            return element.getAttribute('title');
          } else {
            return 'Link';
          }
      }
    }
  }

  function getRowHeaderIdentifier(
    headerArrayPointer,
    hasUnreliableTableHeaders
  ) {
    // Checks for Primary Data Header Attribute
    for (var i = 0; i < headerArrayPointer.length; i++) {
      if (
        headerArrayPointer[i].dataset.hasOwnProperty('responsiveHeaderPrimary')
      ) {
        // This checks to see if the default Header element is a special node type. If it is, it grabs it's alt or title text
        if (headerArrayPointer[i].children.length > 0) {
          if (isSpecialNode(headerArrayPointer[i].children[0])) {
            return specialNodeTypeHandler(
              headerArrayPointer[i].children[0],
              true
            );
          }
        }

        return headerArrayPointer[i].innerText
          .trim()
          .replace(/\s\s+|\t+|\n+/g, '');
      }
    }

    /*
    If no primary data attribute is set, pick the first column as the data primary header. If the table has unreliable table
    headers (most if not all headers are on control), then don't
    show the error
    */

    if (!hasUnreliableTableHeaders) {
      console.error(
        'Error: No data-responsive-header-primary HTML Attribute Found. Please add data-responsive-header-primary attribute to the primary Table Header HTML Element. Defaulting to First Column Header.'
      );
    }

    return headerArrayPointer[0].innerText.trim().replace(/\s\s+|\t+|\n+/g, '');
  }

  function addDataToObject(
    tableObject,
    headerArray,
    childrenDataObject,
    rowCounter
  ) {
    tableObject[rowCounter] = {};
    var colSpaceCounter = 0;
    var colspanElement = undefined;
    // Looks through Children Element and adds data to data array
    for (var i = 0; i < childrenDataObject.children.length; i++) {
      var dataPointer = tableObject[rowCounter];
      var currTDChild = childrenDataObject.children[i];
      var rowData;

      // Checks if the colspan of the first Column header is equal to the current data colspan. If the header has the same colspan as the data do not assume it is a Totals row

      // Totals Column Check
      if (
        isColspanMismatch(currTDChild, headerArray, i) &&
        childrenDataObject.children.length > 1
      ) {
        // Removes colspan text from header array
        if (headerArray[i].indexOf('-|- colspan=') >= 0) {
          headerArray[i] = headerArray[i].split('-|- colspan=')[0];
        }

        colspanElement = currTDChild;
        colSpaceCounter = colspanElement.getAttribute('colspan') - 1;
      }

      // Handles full width items
      else if (
        isColspanMismatch(currTDChild, headerArray, i) &&
        childrenDataObject.children.length > 0
      ) {
        colspanElement = currTDChild;
      }

      // This takes care of cases when there are specialNode children in the current TD. We still need to check currTDCHild for colspan mismatch
      if (
        isPassthroughElement(currTDChild) &&
        currTDChild.children.length > 0
      ) {
        rowData = createPassthroughObject(currTDChild);
      }

      // The elements that are not passthrough could be just regular text or a specialNode we don't account for
      else {
        rowData = currTDChild.innerText
          .trim()
          .replace(/\s\s+|\t+|^\n+|\n\n+/g, ' ')
          .trim();
      }

      // If the Totals row data is in the current column, override the default column header in Javascript object
      if (colspanElement != undefined && i === 0) {
        // This checks if there is no text in the row next to what the program believes is a totals row, it adds the Total header for you
        // This can become problematic if there is a table that happens to have a first colspan that looks like a total but is something else

        if (
          colspanElement.innerText.trim().length <= 0 &&
          !isPassthroughElement(colspanElement)
        ) {
          dataPointer['Special Name | Override'] = 'Total';
        }
        // If the colspanElement is a specialNode like a button, input, or form
        else if (isPassthroughElement(colspanElement)) {
          dataPointer['Special Name | Override'] = 'Controls';
          dataPointer['Action'] = rowData;
        } else if (
          colspanElement.innerText.trim() === 'Totals' ||
          colspanElement.innerText.trim() === 'Total' ||
          colspanElement.innerText.trim() === 'Totales'
        ) {
          dataPointer['Special Name | Override'] =
            colspanElement.innerText.trim();
        } else {
          dataPointer['Special Column | Override'] =
            colspanElement.innerText.trim();
        }
        continue;
      } else {
        dataPointer[headerArray[i + colSpaceCounter]] = rowData;
      }
    }

    return tableObject;
  }

  // Passthrough Functionality
  function isPassthroughElement(element) {
    var allElementChildren = element.getElementsByTagName('*');

    for (var i = 0; i < allElementChildren.length; i++) {
      if (isSpecialNode(allElementChildren[i])) {
        return true;
      }
    }
    return false;
  }

  function createPassthroughObject(element) {
    var passthroughObject = document.createElement('div');
    var preserveTagsInCell =
      (element.hasAttribute('data-responsive-table-preserve-tags-in-cell') &&
        element.dataset.responsiveTablePreserveTagsInCell) ||
      false;
    passthroughObject.setAttribute(
      'data-responsive-table-preserve-tags-in-cell',
      preserveTagsInCell
    );
    for (var i = 0; i < jQuery(element).contents().length; i++) {
      var currElement = jQuery(element).contents()[i];

      // If the current element is a Text Node
      if (currElement.nodeType === 3) {
        var textNodeSpan = document.createElement('span');
        textNodeSpan.innerText = currElement.textContent.trim();
        jQuery(passthroughObject).append(textNodeSpan);
      } else {
        jQuery(passthroughObject).append(currElement.cloneNode(true));
      }
    }
    return passthroughObject;
  }

  function isColspanMismatch(currTDChild, headerArray, currIndex) {
    if (
      currTDChild.hasAttribute('colspan') &&
      currIndex === 0 &&
      headerArray[currIndex].indexOf('-|- colspan=') >= 0
    ) {
      if (
        currTDChild.getAttribute('colspan') !=
        headerArray[currIndex].split('-|- colspan=')[1]
      ) {
        return true;
      }
    } else if (
      currTDChild.hasAttribute('colspan') &&
      currIndex === 0 &&
      headerArray[currIndex].indexOf('-|- colspan=') === -1
    ) {
      return true;
    }
    return false;
  }

  function noColumns(currRow, headerArray) {
    if (headerArray[0].indexOf('-|- colspan=') >= 0) {
      headerArray[0] = headerArray[0].split('-|- colspan=')[0];
    }

    for (var i = 0; i < headerArray.length; i++) {
      if (currRow[headerArray[i]]) {
        return false;
      }
    }
    return true;
  }

  function createResponsiveTable(
    tableObject,
    rowHeaderIdentifier,
    responsiveContainer,
    tableElement,
    headerArray,
    tableNumber
  ) {
    var uniqueRowNumber = 0;
    var uniqueTableClass = 'table-' + tableNumber;
    var expandCollapseAllElement = document.createElement('div');
    expandCollapseAllElement.className =
      'mainCollapser ' +
      uniqueTableClass +
      " toggleDisplay {toggleDisplayOpts:{ targetSelector: '.rows." +
      uniqueTableClass +
      "', elementToggle:true, toggleClasses:'open closed', toggleElement:'.collapser." +
      uniqueTableClass +
      "'}} closed";
    jQuery(responsiveContainer).append(expandCollapseAllElement);

    for (var row in tableObject) {
      var currRow = tableObject[row];
      // Exceptions list. This could be turned into a function later
      // If the row is empty, skip it
      if (jQuery.isEmptyObject(currRow)) {
        continue;
      }

      var uniqueRowClass = 'row-' + uniqueRowNumber;
      var dataContainer = document.createElement('div');
      dataContainer.className = 'dataContainer';
      var rowsElement = document.createElement('div');
      rowsElement.className = 'rows ' + uniqueTableClass + ' ' + uniqueRowClass;
      rowsElement.style.display = 'none';

      var currColumnNumber = 0;

      for (var key in currRow) {
        // Creates DOM Elements Needed
        // TODO: Create Filter Button if time permits

        var rowWrapper = document.createElement('div');
        rowWrapper.className = 'rowContainer';
        var rowDescription = document.createElement('span');
        rowDescription.className = 'dataTitle';
        var rowValue = document.createElement('span');
        rowValue.className = 'dataValue';

        if (currColumnNumber === 0) {
          // Creates the new Head Elements with the Collapse functionality
          var dataHeaderContainer = document.createElement('div');
          dataHeaderContainer.className =
            "dataHeaderContainer toggleDisplay {toggleDisplayOpts:{ targetSelector: '." +
            uniqueRowClass +
            '.' +
            uniqueTableClass +
            "', elementToggle:true, toggleClasses:'open closed', toggleElement:'.collapser" +
            uniqueRowNumber +
            '.' +
            uniqueTableClass +
            "'}}";

          var dataHeader = document.createElement('span');
          dataHeader.className = 'dataHeader';
          var arrow = document.createElement('i');
          arrow.className = 'fa fa-sort-desc';
          arrow.style.fontSize = '24px';
          arrow.style.marginLeft = '10px';
          var dataToggle = document.createElement('div');
          dataToggle.className =
            'collapser collapser' +
            uniqueRowNumber +
            ' ' +
            uniqueTableClass +
            ' closed';
          jQuery(dataToggle).append(arrow);
          // Handles if the Header Identifier is not in the Javascript object. This occurs when the row has No Data Available, No records found, totals, or the row may have buttons.
          if (
            noColumns(currRow, headerArray) &&
            key !== 'Special Name | Override'
          ) {
            dataHeader.innerText = currRow[key];
            jQuery(dataHeaderContainer).append(dataHeader);
            jQuery(dataContainer).append(dataHeaderContainer);
            jQuery(dataContainer).append(rowsElement);
            jQuery(responsiveContainer).append(dataContainer);
            currColumnNumber++;
            continue;
          } else if (key === 'Special Column | Override') {
            dataHeader.innerHTML = currRow[key];
            jQuery(dataHeaderContainer).append(dataHeader);
            jQuery(dataContainer).append(dataHeaderContainer);
            currColumnNumber++;
            continue;
          } else if (key === 'Special Name | Override') {
            dataHeader.innerHTML = currRow[key];
            jQuery(dataHeaderContainer).append(dataHeader);
            jQuery(dataHeaderContainer).append(dataToggle);
            jQuery(dataContainer).append(dataHeaderContainer);
            currColumnNumber++;
            continue;
          } else if (currRow[rowHeaderIdentifier] === undefined) {
            dataHeader.innerText = '';
          }
          // Checks if the current primary column data is regular text or a specialNode
          else if (currRow[rowHeaderIdentifier].innerText === undefined) {
            dataHeader.innerText = currRow[rowHeaderIdentifier];
          } else {
            var preserveTagsInCell =
              (currRow[rowHeaderIdentifier].hasAttribute(
                'data-responsive-table-preserve-tags-in-cell'
              ) &&
                currRow[rowHeaderIdentifier].dataset
                  .responsiveTablePreserveTagsInCell) ||
              false;
            if (preserveTagsInCell) {
              dataHeader.innerHTML = currRow[rowHeaderIdentifier].innerHTML;
            } else {
              dataHeader.innerText = currRow[rowHeaderIdentifier].innerText;
              dataHeader.innerText = dataHeader.innerText
                .replace(/&lt;br&gt;/g, '')
                .replace(/\s\s+/g, ' '); // Some headers have extra white space and <br>'s
            }
          }

          jQuery(dataHeaderContainer).append(dataHeader);
          jQuery(dataHeaderContainer).append(dataToggle);
          jQuery(dataContainer).append(dataHeaderContainer);
        }

        // Appends dataValue to new DOM Element
        var dataValue = currRow[key];
        jQuery(rowValue).append(dataValue);

        // Appends the Row Descriptor to new DOM Element
        rowDescription.innerText = key.trim();
        jQuery(rowWrapper).append(rowDescription);

        jQuery(rowWrapper).append(rowValue);
        jQuery(rowsElement).append(rowWrapper);
        currColumnNumber++;
      }

      jQuery(dataContainer).append(rowsElement);
      jQuery(responsiveContainer).append(dataContainer);
      uniqueRowNumber++;
    }

    // Injects content after to table class
    jQuery(tableElement).parent().append(responsiveContainer);
    jQuery(responsiveContainer).insertAfter(tableElement);
  }

  function addResponsiveEventHandlers() {
    var individualCollpasers = jQuery('.collapser');

    if (individualCollpasers.length > 0) {
      for (var i = 0; i < individualCollpasers.length; i++) {
        var collapser = jQuery(jQuery(individualCollpasers)[i]);

        collapser.click(function () {
          var currCollapser = jQuery(this)[0];
          var currTableClass = '';

          for (var i = 0; i < currCollapser.classList.length; i++) {
            var currClass = currCollapser.classList[i];
            if (currClass.indexOf('table-') >= 0) {
              currTableClass = currCollapser.classList[i];
              break;
            }
          }

          if (currTableClass.length > 0) {
            // Waits 100 ms before executing to give the function that handles the actual toggling of classes to execute. If this is not here we will be checking before the collapser class changes
            setTimeout(function () {
              var specificMainCollapserObject =
                '.mainCollapser.' + currTableClass;

              allCollapserStatusUpdater(
                currTableClass,
                specificMainCollapserObject
              );
            }, 100);
          }
        });
      }
    }

    jQuery('.mainCollapser').click(function () {
      var specificMainCollapserObject = jQuery(this)[0];

      setTimeout(function () {
        var currTableClass = '';

        for (var i = 0; i < specificMainCollapserObject.classList.length; i++) {
          if (specificMainCollapserObject.classList[i].indexOf('table-') >= 0) {
            currTableClass = specificMainCollapserObject.classList[i];
            break;
          }
        }
        allCollapserStatusUpdater(currTableClass, specificMainCollapserObject);
      }, 100);
    });

    function allCollapserStatusUpdater(
      currTableClass,
      specificMainCollapserObject
    ) {
      if (allCollapsersOpen(currTableClass)) {
        jQuery(specificMainCollapserObject).removeClass('open closed');
        jQuery(specificMainCollapserObject).addClass('open');
      } else if (oneCollapserClosed(currTableClass)) {
        jQuery(specificMainCollapserObject).removeClass('open closed');
        jQuery(specificMainCollapserObject).addClass('closed');
      }
    }

    // Iterate through the current table collapsers to check if all are open or all are closed
    function allCollapsersOpen(currTableClass) {
      var listOfCollapsers = jQuery('.collapser.' + currTableClass);

      for (var i = 0; i < listOfCollapsers.length; i++) {
        var currCollapser = listOfCollapsers[i];

        for (var j = currCollapser.classList.length - 1; j >= 0; j--) {
          if (currCollapser.classList[j] === 'closed') {
            return false;
          }
        }
      }
      return true;
    }

    function oneCollapserClosed(currTableClass) {
      var listOfCollapsers = jQuery('.collapser.' + currTableClass);

      for (var i = 0; i < listOfCollapsers.length; i++) {
        var currCollapser = listOfCollapsers[i];

        for (var j = currCollapser.classList.length - 1; j >= 0; j--) {
          if (currCollapser.classList[j] === 'closed') {
            return true;
          }
        }
      }
      return false;
    }
  }

  function renderTable({regen = false} = {}) {
    var tableIdentifier = '.responsiveTable';
    var headerIdentifier = '.responsiveTableHeader';

    // We only want to render if window is less than 950px
    var isMobile = jQuery(window).width() < 950;

    if (
      jQuery(tableIdentifier).length !== 0 &&
      jQuery(headerIdentifier).length !== 0
    ) {
      var tableNumber;
      for (var i = 0; i < jQuery(tableIdentifier).length; i++) {
        tableNumber = i;
        var table = jQuery(tableIdentifier)[i];
        var responsiveTable = jQuery('body').find(
          '.responsiveTableContainer_' + i
        );
        if (regen) {
          responsiveTable.remove();
        }
        var hasTable = responsiveTable.length;

        // Render if not rendered and mobile width
        if (!hasTable && isMobile) {
          convertTable(table, headerIdentifier, tableNumber, i);
        } else if (!isMobile) {
          responsiveTable.remove();
        }
      }
      addResponsiveEventHandlers();
    }
  }
  // Setup mutation observer for rebuilding Mobile Table when desktop table changes
  const elementsToObserve = document.querySelectorAll('.responsiveTable');

  const mutationConfig = {attributes: true, childList: true, subtree: true};

  const mutationCallback = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        renderTable({regen: true});
      }
    }
  };

  const observer = new MutationObserver(mutationCallback);

  // Call our render function when the page loads
  // And call again on resize because we render based on size

  function init() {
    var timeout = false;

    // Start observing the desktop table for changes
    elementsToObserve.forEach((targetNode) =>
      observer.observe(targetNode, mutationConfig)
    );

    renderTable();
    jQuery(window).on('resize', function () {
      // Only call render when we stop resizing
      clearTimeout(timeout);
      timeout = setTimeout(renderTable, 250);
    });
  }

  init();
});
