// class useTemplate - these clone a template element in the DOM
// and then insert the clone before the template and make it visible.
// Attributes of the cloned element can be modified
// opts.hide allows the template to be hidden on after/before
// opts.prune removes any element that uses the ID of a before or after selecto
$('body').on(
  'click',
  'a.useTemplate, button.useTemplate, input[type=button].useTemplate, li.useTemplate',
  function () {
    var opts = $(this).metadata();
    if (opts.templateSelector) {
      var cloned = ht.applyTemplate($(opts.templateSelector));
      //TODO: TJS ht.placement();
      if (opts.appendSelector) {
        $(opts.appendSelector).append(cloned);
      } else if (opts.prependSelector) {
        $(opts.prependSelector).prepend(cloned);
      } else if (opts.beforeSelector) {
        if (opts.hide) {
          $(opts.beforeSelector).hide();
        }
        if (opts.prune) {
          // remove any existing element with the same ID
          $('#' + cloned[0].id).remove();
        }
        $(opts.beforeSelector).before(cloned);
      } else if (opts.afterSelector) {
        if (opts.hide) {
          $(opts.afterSelector).hide();
        }
        if (opts.prune) {
          // remove any existing element with the same ID
          $('#' + cloned[0].id).remove();
        }
        $(opts.afterSelector).after(cloned);
      } else if (opts.replaceSelector) {
        $(opts.replaceSelector).replaceWith(cloned);
      } else {
        $(opts.templateSelector).before(cloned);
      }
      ht.processDeclarations(cloned);
      cloned.find(':input:enabled:first').focus();
    }
    return false;
  }
);
