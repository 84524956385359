// closer for activity/status messages
$('body').on('click', 'a.closeMessage', function () {
  $(this).hide();
  $(this)
    .parent('.messageBox')
    .animate({height: 0, opacity: 0}, 'fast', function () {
      $(this).hide();
    });
  return false;
});
