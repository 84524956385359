/***************************************************************************
 * Figure out if this is a navigation within the page or an ajax navigation
 **************************************************************************/
ht.handleAnchorNav = function (evt) {
  var anchorName = evt.value.replace(/^\/?(.*)$/, '$1');
  var targetAnchor = document.getElementById(anchorName);
  if (targetAnchor != null) {
    ht.scrollToTarget(anchorName);
  }
  return targetAnchor;
};
