/*
 * If opts contains a nonempty tip, get the HTML content from the tip node and show it when mouseenter el, and
 * hide it when mouseleave.
 * @param {HTMLElement} el - The DOM node which the tooltip is referencing / hovering over.
 * @param {Object[]} opts - Object containing options which will help rendering the popover.
 * @param {string} [opts[].tip=.tooltip] - The selector for the popup tooltip.
 * @param {string} [opts[].placement=top] - Specifies popover position.
 * @param {string} [opts[].trigger=manual] - Specifies how popup is triggered.
 * @param {boolean} [opts[].html=true] - Specifies whether to accept HTML tags in the popover.
 *
 * */
ht.popover = function (el, opts) {
  var mouseLeaveDelay = opts.mouseLeaveDelay ? opts.mouseLeaveDelay : 20;
  var timeoutIds = [];
  var siblingTimeoutId = 0;
  var resetTimeout = function () {
    while (timeoutIds.length > 0) {
      clearTimeout(timeoutIds.pop());
    }
  };
  if ($(opts.tip).length > 0) {
    var toInject = $.extend(opts, {
      content: function () {
        return $(opts.tip).html();
      }
    });
    $(el)
      .popover(toInject)
      .on('click', function (e) {
        e.preventDefault();
      })
      .on('mouseenter', function () {
        resetTimeout();
        var _this = this;
        $(el).popover('show');
        $(el)
          .siblings('.popover')
          .on('mouseleave', function () {
            var tid = setTimeout(function () {
              $(_this).popover('hide');
              resetTimeout();
            }, mouseLeaveDelay);
            timeoutIds.push(tid);
          });
      })
      .on('mouseleave', function () {
        var _this = this;
        var tid = setTimeout(function () {
          if (!$('.popover:hover').length) {
            $(_this).popover('hide');
            resetTimeout();
          }
        }, mouseLeaveDelay);
        timeoutIds.push(tid);
      });
  }
};
