// Resets a form with the added option to ignore resetting a specific class
$('body').on('click', '.resetForm', function () {
  var opts = $(this).metadata().resetFormOpts;
  if (opts) {
    var ignoreClass = opts.ignoreClass;
    var targetSelector = opts.targetSelector;
    var ignore = ':button, :submit, :reset, :hidden';
    if (ignoreClass) {
      ignore = ignore + ', input[class=' + ignoreClass + ']';
    }

    $(':input', targetSelector)
      .not(ignore)
      .val('')
      .removeAttr('checked')
      .removeAttr('selected');
  }
});
