$('body').on('click', 'a.useLabel', function () {
  var opts = $(this).metadata();
  if (opts.templateSelector) {
    var newLi = $(
      '<li><label>' +
        opts.label +
        '</label><span>' +
        opts.displayName +
        '</span></li>'
    );
    $(opts.templateSelector).prepend(newLi);
  }
  return false;
});
