//uses input to turn tinyMCE on and off
$('body').on('click', 'input.toggleWYSIWYG', function () {
  // grabs the metadata
  var opts = $(this).metadata().tinymceOpts;
  if (tinymce.enabled == undefined || !tinymce.enabled) {
    // creates tinyMCE when the enable button is pushed
    ht.createTinyMCE(opts);
    // changes button text to disable
    $(this).val('Disable Rich Text Editor');
    // sets a flag to say tinyMCE is enabled
    tinymce.enabled = true;
  } else {
    // removes tinyMCE
    tinymce.remove(opts.selector);
    // changes button text to enable
    $(this).val('Enable Rich Text Editor');
    // sets a flag to say tinyMCE is disabled
    tinymce.enabled = false;
  }
  return false;
});
