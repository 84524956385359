ht.geocode = function (form) {
  var fullAddress = '';
  var queryNewGeocode = false;
  var previouslyGeocoded = false;
  var searchOnZipAlone = true;
  var opts = form.metadata().geoCodeOpts;

  //loop through each geoCodeSeletor and determine if the value exists or has changed
  $(opts.geoCodeSelector).each(function () {
    var addressComponentValue = $(this).val();
    if (addressComponentValue != '') {
      fullAddress += addressComponentValue + ' ';
      var addressComponentOldValue = $('body').data($(this).attr('name'));
      if (addressComponentValue != addressComponentOldValue) {
        queryNewGeocode = true;
      }
      // set value so that we don't geocode again if we don't have to
      $('body').data($(this).attr('name'), addressComponentValue);
      previouslyGeocoded = true;
      if ($(this).attr('name').indexOf('zip') < 0) {
        searchOnZipAlone = false;
      }
    }
  });

  if (previouslyGeocoded && queryNewGeocode === false) {
    return;
  } else if (queryNewGeocode) {
    //change the value of the sort ddlb
    if (opts.sortOpts) {
      ht.setDDLB(opts.sortOpts);
    }
    if (searchOnZipAlone) {
      /*leaving fullAddress as just the zip code value is ambiguous; to avoid some zip codes not getting
          geocoded as result (CSM1-13227), I'm specifying that we're looking for zip codes here*/
      fullAddress = 'zip ' + fullAddress;
    }

    //do the geocode magic with the given parameters
    loadGoogleMaps(this, function () {
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({address: fullAddress}, function (results, status) {
        var lat = results[0].geometry.location.lat();
        var long = results[0].geometry.location.lng();
        $(opts.targetLat).val(lat);
        $(opts.targetLong).val(long);

        //double check that the form is valid before submitting if form has class applyValidate
        if ($(form).hasClass('applyValidate')) {
          var validate = $(form).validate();
          if (!$.isEmptyObject(validate.invalid)) {
            return false;
          }
        }

        //if called from a submit, submit the form
        if (form != null) {
          form.get(0).querySelector('input[type="submit"]').click();
        }
      });
    });
    /*send back true because you need to wait for the google geocode callback to run
          This is handy when preventing a form from submitting too soon*/
    return true;
  } else {
    $(opts.targetLat).val('');
    $(opts.targetLong).val('');
  }
};
