ht.requireChangesToSubmit = function (clicked, submitButton, $) {
  // check if the submit button has an itemsChanged property
  if ($(submitButton).metadata().hasOwnProperty('itemsChanged')) {
    if (clicked in $(submitButton).metadata()['itemsChanged']) {
      // the clicked item is being reverted to its original value, so remove it from itemsChanged
      delete $(submitButton).metadata()['itemsChanged'][clicked];
    } else {
      // mark that the clicked item has changed from its original value
      $(submitButton).metadata()['itemsChanged'][clicked] = true;
    }
  } else {
    // add an itemsChanged property to the submit button
    $(submitButton).metadata()['itemsChanged'] = {};
    $(submitButton).metadata()['itemsChanged'][clicked] = true;
  }

  // disable submit button if no items have been changed
  if (Object.keys($(submitButton).metadata()['itemsChanged']).length === 0) {
    $(submitButton).attr('disabled', 'disabled');
  } else {
    $(submitButton).removeAttr('disabled');
  }
};
