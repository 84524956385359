//do hidey showy
//targetSelector: the element that you want to show
//elementToggle: set true in the metadata if you want to do hidey showy, or leave it empty to display element only
$('body').on('click', '.toggleDisplay', function (e) {
  var opts =
    $(this).data('toggleDisplay') || $(this).metadata().toggleDisplayOpts;
  e.preventDefault();
  ht.toggle(opts);
  if (opts.toggleClasses) {
    ht.toggleClass({
      toggleElement: opts.toggleElement,
      toggleClasses: opts.toggleClasses,
      hardToggle: opts.hardToggle ? opts.hardToggle : null
    });
  }
});
