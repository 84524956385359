// close the twistys
ht.declare('dl.twisty', function () {
  $(this)
    .find('dt:not(.lite)')
    .each(function () {
      $(this).attr('tabindex', '0');
    });
  $(this)
    .find('dt:not(:has(a.twisty)), dd')
    .each(function () {
      $(this).addClass('twistClosed');
    });
  $(this).on('keypress click', 'dt:not(.lite)', ht.twist);
  $(this).on('click', 'dt.lite a.twisty', function (evt) {
    $(this).parents('dt.lite').toggleClass('hideBorder');
    ht.twist.apply($(this).parents('dt.lite'), [evt]);
    return false;
  });
});
