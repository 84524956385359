/***************************************************************************
 * localAjaxComplete is a function used to examine the results from all ajax
 * calls. In the event of an error, it determines what to do, on a local
 * element level, i.e. whether to display an error or let something higher
 * up the chain handle it.
 **************************************************************************/
ht.localAjaxComplete = function (responseText, textStatus, XMLHttpRequest) {
  ht.processDeclarations($(this));
  if (responseText.indexOf('displayError') > 1) {
    var returnData = responseText.replace(/<script(.|\s)*?\/script>/g, '');
    var toInsert = $('<div />').append(returnData);
    toInsert = toInsert.find('.displayError');
    if (toInsert.size() > 0) {
      $(this).html(toInsert).show().trigger('AjaxElementLoad');
      ht.log('ajax error: ', $(this));
    }
  }
};
