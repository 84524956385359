ht.buildMultiMarkers = function (opts, map) {
  loadGoogleMaps(this, function () {
    var markerBaseOpts = ht.setMarkerDefaults();
    ht.homeMarkerCheck(map, markerBaseOpts.bounds, opts.folderId);
    var numberOfMarkers = $('.markers').length;
    var bounds = new google.maps.LatLngBounds();

    const markers = [];

    //for each marker on the page
    $('.markers').each(function (index) {
      //get the individual marker metadata
      var markerMetadata = $(this).metadata().points;

      var markerInfo = ht.setMarkerInfo(
        {
          lat: markerMetadata.lat,
          long: markerMetadata.long,
          folderId: opts.folderId
        },
        index,
        opts
      );
      //build marker

      var marker = new google.maps.Marker({
        map: map,
        position: markerInfo.latlng,
        icon: markerInfo.markerImg,
        shadow: markerBaseOpts.markerShadow,
        label: markerInfo.label,
        id: index,
        title: markerBaseOpts.infoWindow
          ? markerMetadata.title
          : 'View map for ' + markerMetadata.title,
        zIndex: index
      });
      //if you want to display an infowindow add an eventListener, set the content and display on click
      if (opts.displayInfoWindow) {
        google.maps.event.addListener(marker, 'click', function () {
          markerBaseOpts.infoWindow.setContent(
            $('#' + markerMetadata.infoId).html()
          );
          markerBaseOpts.infoWindow.open(map, marker);
        });
        //if you want to modal up from Marker add an eventListener, open the modal and build the new map/marker on click
      } else if (opts.modalFromMap) {
        var modalFromMapOpts = opts.modalFromMap;
        google.maps.event.addListener(marker, 'click', function () {
          ht.modalOpen({
            modalId: modalFromMapOpts.modalId,
            modalWidth: modalFromMapOpts.modalWidth
          });
          var modalOpts = {
            lat: markerMetadata.lat,
            long: markerMetadata.long,
            markerIndex: index,
            infoId: markerMetadata.infoId,
            modalId: modalFromMapOpts.modalId,
            infoSection: modalFromMapOpts.infoSection,
            folderId: opts.folderId
          };
          ht.buildSingleMarker(modalOpts);
        });
      }
      //sets center of map on click of marker
      if (opts.panToMarker) {
        google.maps.event.addDomListener(
          document.getElementById('detailsPinList' + index),
          'click',
          function () {
            map.setCenter(markerInfo.latlng);
          }
        );
      }
      //scroll to list item (mobile)
      if (opts.listItemFocus) {
        google.maps.event.addListener(marker, 'click', function () {
          $('.provListItem').removeClass('targeted');
          var listElement = '#' + markerMetadata.listItem;
          $('body').scrollTo(listElement, 400);
          $(listElement).addClass('targeted');
        });
      }

      //extends the perimeter of map to show all markers
      bounds.extend(marker.getPosition());
      if (numberOfMarkers == 1) {
        map.setCenter(markerInfo.latlng);
        map.setZoom(15);
      }

      markers.push(marker);
    });
    //reset map to orignal position
    if (opts.resetDOMId) {
      google.maps.event.addDomListener(
        document.getElementById(opts.resetDOMId),
        'click',
        function (e) {
          map.fitBounds(bounds);
          ht.preventDefault(e);
        }
      );
    }
    //resize map
    //google.maps.event.trigger(map, "resize");

    if (numberOfMarkers > 1) {
      //fit the map to the given bounds
      //http://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        var extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.002,
          bounds.getNorthEast().lng() + 0.002
        );
        var extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.002,
          bounds.getNorthEast().lng() - 0.002
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      map.setCenter(bounds.getCenter());
      map.setZoom(8);
    }

    const markerCluster = new markerClusterer.MarkerClusterer({map, markers});
  });
};
