//add spans for css3 styled radio buttons and checkboxes
ht.declare(
  'input[type=checkbox], input[type=radio]',
  function () {
    // Only add Spans if radio buttons and checkboxes are outside of Content and the input doesnt have a dontwrap attribute
    var dontWrap = $(this).data('dontwrap');
    if (
      !$(this).closest('.contentcell').length &&
      !$(this).closest('.epCPSCategorySelect').length &&
      !dontWrap
    ) {
      $(this).wrap(
        '<span class="ht1-CheckableWrapper ht2-CheckableWrapper ht-checkable-wrapper"></span>'
      );
      $(this).after('<span />');
    }
  },
  null,
  '#controlsContainer input[type=checkbox], #controlsContainer input[type=radio]'
);
