/***************************************************************************
 * used for ajaxLink and ajaxSelect to fire off ajax call
 * replaces the contents of a specified element with the
 * results of a call to the uri specified
 **************************************************************************/
ht.triggerAjaxNavigate = function (elem) {
  var insideModal = $('#modal').is(':visible');
  var md = elem.metadata();
  var opts = {
    url: elem.attr('href') || md.url,
    resultSelector: md.resultSelector
  };

  // log a warning if there's no managedNav parent
  if (insideModal && !elem.closest('.managedNav').length) {
    ht.log('.ajaxLink may require div.managedNav element.');
  }

  if (md.addToQueryString) {
    opts.data = '';
    $(md.addToQueryString).each(function (i, elem) {
      opts.data += $(elem).serialize() + '&';
    });
  }

  if (md.ajaxNavigateOpts) {
    $.extend(opts, md.ajaxNavigateOpts);
  }

  // warn against URLs containing .page
  if (insideModal && /\.page/.test(opts.url)) {
    ht.log(
      'ajaxNavigate URL does not require .page extension if you are inside a modal. A reload will be forced. Here is the suspect link:',
      this
    );
  }

  // try to trigger the event on any selected resource specified
  if (md.selector) {
    $(md.selector).trigger('ajaxNavigate', opts);
  } else {
    // or fall back on any container that knows how to handle this
    elem.trigger('ajaxNavigate', opts);
  }
};
