$('body').on('click', 'a.printActive', function () {
  var opts = $('.toggleActiveWrapper')
    .find('.active')
    .metadata().printActiveOpts;
  $('.tabContainer').find('.printSection').removeClass('gmnoprint');
  $.each(opts.print, function () {
    var id = this.toString();
    $(id).addClass('gmnoprint');
  });
  ht.printPage();
});
