(function () {
  var selects = document.querySelectorAll(
    "select[data-using-enhanced-multiselect='true'][multiple='true']"
  );
  for (var i = 0; i < selects.length; i++) {
    var dataset = selects[i].dataset || {};
    var placeholder = dataset.placeholder || 'None Specified';
    new SlimSelect({
      select: selects[i],
      closeOnSelect: false,
      placeholder: placeholder,
      showSearch: selects[i].options.length > 25,
      hideSelectedOption: true
    });
  }
})();
