if (ht.CentralExchange) {
  ht.declare('.updateCount', function () {
    var self = this;
    var textOpts = $(self).metadata().updateTextOpts;

    ht.CentralTopic('updateCount').subscribe(function (opts) {
      if (textOpts && textOpts.type == opts.type) {
        if (opts.textToUpdate) {
          $(opts.textToUpdate).text(function (i, txt) {
            return txt.replace(/\d+/, opts.count);
          });
        }
        if ($(opts.titleToUpdate).is('[data-original-title]')) {
          $(opts.titleToUpdate).attr('data-original-title', function (i, txt) {
            return txt.replace(/\d+/, opts.count);
          });
        }
        if ($(opts.titleToUpdate).is('[title]')) {
          $(opts.titleToUpdate).attr('title', function (i, txt) {
            return txt.replace(/\d+/, opts.count);
          });
        }
        if (parseInt(opts.count) == 1) {
          $(opts.titleToUpdate).parent('a').removeAttr('style');
        }
      }
    });
  });
}
