ht.declare('form.storeDataOnload', function () {
  var opts = $(this).metadata().dataOpts;
  for (i = 0; i < opts.dataContainer.length; i++) {
    $(opts.dataContainer[i]).each(function () {
      if ($(this).val() != '') {
        $('body').data($(this).attr('name'), $(this).val());
      }
    });
  }
});
