//set input value and submit
$('body').on('click', '.setValueSubmit', function () {
  var setValueOpts = $(this).metadata().setValueOpts;
  if (
    setValueOpts != null &&
    setValueOpts.inputTarget != null &&
    setValueOpts.inputValue
  ) {
    $(setValueOpts.inputTarget).val(setValueOpts.inputValue);
    if (setValueOpts.submitForm) {
      $(this).parents('form').submit();
    }
  }
  return false;
});
