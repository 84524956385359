//TODO: This should only run when the modal is first loaded, not everytime the DOM changes
ht.declare('div.cloneOnLoad', function () {
  var defaults = {
    placement: 'appendTo'
  };
  var metadata = $(this).metadata().cloneOpts;
  var opts = $.extend({}, defaults, metadata);
  //TODO:this should use ht.placement
  if (opts.placement == 'insertAfter') {
    var cloned = $(opts.cloneSelector).clone(true).insertAfter($(this));
  } else {
    var cloned = $(opts.cloneSelector).children().clone(true).appendTo($(this));
  }

  ht.processDeclarations(cloned);
  //TODO: This should be handled via metadata and conditionally
  $('div#resultWrapper').find('table').removeClass('displayNone');
});
