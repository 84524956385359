// Deal with removable, deletable elements
ht.declare('.deleteContainer', function () {
  if ($(this).find('a.removeElement').length < 1) {
    var self = $(this);
    var removeElem = $('<a>X</a>');

    var opts = $.extend({}, self.metadata(), self.data('removeOpts'));
    if (opts.elementSwap) {
      removeElem.data('removeOpts', opts);
    }
    if (opts.removeSelector) {
      removeElem.attr(
        'class',
        "ht2-DeletableChipRemoveButton removeElement {removeParent:'.deleteContainer', removeSelector:'" +
          opts.removeSelector +
          "'}"
      );
    } else if (opts.showElement) {
      removeElem.attr(
        'class',
        "ht2-DeletableChipRemoveButton removeElement {removeParent:'.deleteContainer', showElement:'" +
          opts.showElement +
          "'}"
      );
    } else {
      removeElem.attr(
        'class',
        "ht2-DeletableChipRemoveButton removeElement {removeParent:'.deleteContainer'}"
      );
    }
    removeElem.attr('href', '#');
    self.append(removeElem);
  }
});
