//IE iframe hack
ht.waitForIframe = function () {
  var iframe = $.data($('iframe')[0], 'iframe');
  //when the iframe has finished loading in IE recalculate the height
  if (iframe[0].readyState == 'complete') {
    //the iframe is ready so calculate the height
    iframe.height(iframe.contents().find('body').height() + 35);
    $.removeData($('iframe')[0], 'iframe');
    ht.hideLoading('body');
  } else {
    setTimeout(ht.waitForIframe, 200);
  }
};
