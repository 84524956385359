/***************************************************************************
 * Calculates the editors for RTF tinymce to keep in order to avoid
 * problems with mobile loading. If the editor has a class "keepEditorActive"
 * then the editor will be kept (see the problem with removing them all for
 * preview messages in CSM1-6618),else it will be removed and the kept editors
 * will be assigned to an empty array.
 **************************************************************************/
ht.calculateEditorsToKeep = function () {
  if (typeof tinymce !== 'undefined' && tinymce.editors.length > 0) {
    tinymce.triggerSave();
    var calculateEditorsToKeep = function (editor) {
      return $('#' + editor.id).hasClass('keepEditorActive');
    };
    //explicitly destroy the editor that doesn't pass the calculateEditorsToKeep test
    for (edId in tinymce.editors) {
      if (!calculateEditorsToKeep(tinymce.editors[edId])) {
        tinymce.editors[edId].destroy();
      }
    }
  }
};
