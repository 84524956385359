//adds an input element, For example: Used to dynamically add file upload inputs to a page as needed
$('body').on('click', 'a.addInput', function () {
  var opts = $(this).metadata().addInputOpts;
  if (opts) {
    var newClass = opts.newClass != null ? opts.newClass : '';
    var input = $(
      "<input type='" +
        opts.inputType +
        "' name='" +
        opts.name +
        "' class='" +
        newClass +
        "' />"
    );
    var uniqueID = opts.name;

    if (opts.requiresUniqueId) {
      // use recursion to find a random number and ensures that id is not used in the document
      var getUniqueID = function (name) {
        var tempID = name + Math.random();
        if ($('#' + tempID).length === 0) {
          return tempID;
        } else {
          return getUniqueID(name);
        }
      };
      uniqueID = getUniqueID(opts.name);
      input.attr('id', uniqueID);
    }

    //adds the input to the page
    input.appendTo(opts.targetSelector);

    //add wrapping element
    if (opts.encapsulatingNode) {
      input.wrap(
        '<' +
          opts.encapsulatingNode +
          " class='ht2-Layout-InputEncapsulatingNode'>"
      );
    }

    //add remove link/functionality
    if (opts.removable) {
      if (opts.encapsulatingNode) {
        $(
          "<a href='#' class='ht2-Link removeElement {removeParent: \"" +
            opts.encapsulatingNode +
            '"}\'>Remove</a>'
        ).insertAfter(input);
      } else {
        $(
          "<a href='#' class='ht2-Link toRemove removeElement {removeSelector: \".toRemove\"}'>Remove</a>"
        ).insertAfter(input);
      }
    }

    if (opts.maxFileSize) {
      input.attr(
        'data-rule-filesize',
        "{'maxsize':" + opts.maxFileSize + ",'unit':'mb'}"
      );
    }

    //add validation
    if (opts.required) {
      //TODO: change this to add attribute
      input.addClass('required');
      if (opts.removable && opts.errorMessage) {
        $(
          "<label class='ht2-ErrorText error' for='" +
            uniqueID +
            "' style='display: none;'> " +
            opts.errorMessage +
            '</label>'
        ).insertAfter('a.removeElement:last');
      }
    }

    //Hides the file input on mobile and clicks on it, prompting the user to select a file.
    //Once the file is selected, it sets a label to the file's name.
    if (opts.mobileInput && opts.inputType === 'file') {
      input.hide();
      var nameLabel = $('<span></span>');
      nameLabel.insertAfter(input);
      input.change(() => {
        nameLabel.text(input[0].files[0].name);
      });
      input.click();
    }

    return false;
  }
});
