//forces a form to run the geocode logic when submitted
$('form.forceGeocode').on('submit', function () {
  //added for mobile faceted search
  if (localStorage.mobile != null) {
    if (localStorage.noGeoLocate == null) {
      if ($('#provZip').val() != '') {
        $('.latLong').removeClass('geocodeParam');
        $('#provZip').addClass('geocodeParam');
      } else {
        if ($('.latLong').hasClass('geoCodeParam')) {
          $('#provZip').removeClass('geocodeParam');
        } else {
          $('.latLong').addClass('geocodeParam');
        }
      }
    }
  }

  var stateValueInSearch = $('select#state').val();
  var currentAddressStateInput = $('input#addressState');

  // Set the addressState to be the myState search parameter before submission.
  // So that response is updated with queried state value.
  if (stateValueInSearch != '') {
    currentAddressStateInput.val(stateValueInSearch);
  }

  var pendingCoordinates = ht.geocode($(this));
  var opts = $(this).metadata().geoCodeOpts;
  // the following lines are to allow the geocode call back to run before the form is submitted
  // However, this unbinding call is causing form validation to be removed (if it's included)
  // so let's not do it if we want to keep form validation.
  if (pendingCoordinates && !opts.preventUnbindingSubmit) {
    $(this).unbind('submit');
    return false;
  } else if (pendingCoordinates && opts.preventUnbindingSubmit) {
    return false;
  }
});
