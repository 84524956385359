//TODO: Find where this is used TJS
$('body').on('click', 'a.ajaxPaginate', function () {
  var opts = {
    url: $(this).attr('href'),
    resultSelector: $(this).metadata().resultSelector
  };
  //ht.getAjaxNavigateOpts($(this), opts);
  $.ajax({
    dataType: 'html',
    async: false,
    cache: false,
    url: opts.url,
    success: function (html) {
      $('div' + opts.resultSelector).html(
        $(html)
          .find('div' + opts.resultSelector)
          .html()
      );
    }
  });
  return false;
});
