//shows single marker on map in modal window
$('body').on('click', 'a.enlargeMapPin', function () {
  var opts = $(this).metadata().mapPinOpts;
  ht.modalOpen({modalId: opts.modalId, modalWidth: opts.modalWidth});
  if (opts.directions) {
    ht.getDirections(opts);
  } else {
    ht.buildSingleMarker(opts);
  }

  return false;
});
