$('body').on('click', 'a.resetFacet', function () {
  var opts = $(this).metadata().resetFacetOpts;
  //clears the closest form data from the link/button clicked
  var form = $(this).closest('form');
  ht.resetFacet(form);
  if (opts && opts.defaultDistance) {
    form
      .find('select[name="distance"] option:selected')
      .val(opts.defaultDistance);
    form.find('select[name="sortType"] option:selected').val(opts.currentSort);
    form.find('input[name="clear"]').val(true);
  }
  if (opts && opts.submitForm) {
    form.submit();
  }
  return false;
});
