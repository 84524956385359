var googleMapsLoadStarted = false;
function loadGoogleMaps(thisValue, callback) {
  googleMapsLoaded.then(callback.bind(thisValue));
  if (googleMapsLoadStarted) return;

  googleMapsLoadStarted = true;
  var googleMapsScriptId = 'gmap_script';
  if (document.getElementById(googleMapsScriptId)) {
    googleMapsLoaded.resolve();
    return;
  }
  var head = document.getElementsByTagName('head')[0];

  window.googleMapsReady = function () {
    googleMapsLoaded.resolve();
  };

  var scriptEl = document.createElement('script');
  scriptEl.id = googleMapsScriptId;
  scriptEl.src = googleMapsApiUrl + '&callback=googleMapsReady';
  head.insertBefore(scriptEl, head.lastChild);
}
