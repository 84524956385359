$(document).on('keydown', 'textarea.autocomplete', function () {
  var opts = $(this).metadata().autocompleteOpts;
  if (opts && opts.actionSelector) {
    var availableActions = [];
    $(opts.actionSelector).each(function () {
      availableActions.push($(this).text());
    });
  } else {
    var availableActions = $('input.autoCompleteString')
      .attr('value')
      .split('|');
  }
  $(this).autocomplete({
    appendTo: ht.smartuishell ? '#smartuiLegacyAppendable' : null,
    source: function (request, response) {
      // delegate back to autocomplete, but extract the last term
      response(
        $.ui.autocomplete.filter(
          availableActions,
          request.term.split(' ').pop()
        )
      );
    },
    focus: function () {
      // prevent value inserted on focus
      return false;
    },
    select: function (event, ui) {
      //get all values in textarea
      var terms = this.value.split(' ');
      // remove the current input from the array
      terms.pop();
      // add the selected item
      terms.push(ui.item.value);
      // add placeholder to get a space in between conditions
      terms.push('');
      //join all items and replace the value
      this.value = terms.join(' ');
      return false;
    }
  });
});
