ht.popupConsumer = function (consumer) {
  $(document).bind('popupResult', function (evt) {
    var consumerOpts = consumer.metadata().popupConsumerOpts;
    var toInject = evt.html;

    if (consumerOpts.resultSelector && consumerOpts.resultSelector.length > 0) {
      var toInject = $('<div />').append(evt.html);
      toInject = toInject.find(consumerOpts.resultSelector);
    }
    if (consumerOpts.replaceTarget) {
      $(consumerOpts.targetSelector).html(toInject);
    } else {
      $(consumerOpts.targetSelector).append(toInject);
    }
    if (consumer.is('input[type=checkbox]')) {
      consumer.attr('checked', 'checked');
    }
    if (
      consumerOpts.elementSwap &&
      $(toInject).find(consumerOpts.elementSwap.countSelector).length > 0
    ) {
      ht.elementSwap(consumerOpts.elementSwap);
    }
    ht.processDeclarations($(consumerOpts.targetSelector));

    $(consumerOpts.targetSelector)
      .find('.appendIndexToName')
      .each(function (index, element) {
        var curElementName = $(element).attr('name');
        if (/-\d+$/gm.test(curElementName) == false) {
          $(element).attr('name', curElementName + '-' + index);
        }
      });
    consumer.focus();
  });
};
