/*
  a span with a class of 'setLocalIsoTime' and has a data-date attribute set as an iso date
    will have its innerHTML set to the localized date
  Freemarker example:
  Use iso_m_u built-in in order to get iso form
  <span
    class="setLocalIsoDateTime"
    data-date="${asOfDate?string.iso_m_u}"
    data-format="MM/DD/YYYY"
    data-property="html"
    >
  </span>
*/
ht.declare('.setLocalIsoDateTime', function () {
  var dateString = $(this).data().date || new Date().toISOString(); //default to current time
  var dateFormat = $(this).data().format || 'MM/DD/YYYY';
  var dateProperty = $(this).data().property || 'html';
  var localizedDateString = moment(dateString).format(dateFormat);
  switch (dateProperty) {
    case 'html': // changing the inner html of the selected element
      $(this).html(localizedDateString);
      break;
    case 'input': // changing the value of the selected element (for inputs)
      $(this).val(localizedDateString);
      break;
    default:
      console.error(
        "assigned jQuery class 'dateProperty' for setLocalIsoDateTime is not valid"
      );
  }
  return;
});
