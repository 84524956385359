// ajax-based dependent
ht.declare('div.ajaxDependent, select.ajaxDependent', function () {
  var dependentEl = $(this);
  var dependentOpts = dependentEl.metadata().dependentOpts;
  if (dependentOpts != undefined) {
    var dependsOnSel = dependentOpts['dependsOn'];
    var dependsURL = dependentOpts['dependsURL'];
    var resultSelector = dependentOpts['resultSelector'];
    var hide = dependentOpts['hideContainer'];
    var submitOnComplete = false;

    if (dependsOnSel.length > 0 && dependsURL.length > 0) {
      $(dependsOnSel).on('keydown', function (e) {
        //if enter is pressed prevent default action and trigger the change event
        if (e.which == 13) {
          ht.preventDefault(e);
          $(dependsOnSel).trigger('change');
          //set submitOnCompete true so that the form acts as expected and submits after the ajax completes
          submitOnComplete = true;
        }
      });
      $(dependsOnSel).on('change', function () {
        var submitVars = $(dependsOnSel).serialize();
        var ajaxOpts = ht.getAjaxBaseOpts({
          url: dependsURL,
          data: submitVars,
          success: function (result) {
            var returnData = ajaxOpts.evaluateScript
              ? result
              : result.replace(/<script(.|\s)*?\/script>/g, '');

            var toInsert = returnData;
            if (resultSelector != undefined) {
              toInsert = $('<div />').append(returnData);
              toInsert = toInsert.find(resultSelector);
            }

            if (hide != undefined) {
              if ($(hide).is(':visible') && toInsert.length < 2) {
                $(hide).hide();
              } else {
                $(hide).show();
              }
            }

            if (toInsert.length > 0) {
              dependentEl.html(toInsert).trigger('AjaxElementLoad');
              if (dependentOpts.resultSelectedValue !== undefined) {
                dependentEl.val(dependentOpts.resultSelectedValue);
              }
            }
          },
          complete: function () {
            //submits parent for on complete if enter was pressed
            if (submitOnComplete) {
              $(dependsOnSel).closest('form').submit();
            }
          }
        });
        $.ajax(ajaxOpts);
      });
    }
  }
});
