// when certain dynamic content is loaded, we want to automatically
// scroll to it
$(document).on('AjaxElementLoad', 'html,body', function (evt, extraParams) {
  if (
    extraParams &&
    extraParams.hasOwnProperty('disableOnLoadPositionScroll') &&
    extraParams.disableOnLoadPositionScroll
  ) {
    return;
  }
  // desired scroll position is 20px above the element that was loaded
  var desiredOffset = $(evt.target).offset().top - 20;
  desiredOffset = desiredOffset > 0 ? desiredOffset : 0;
  if (desiredOffset < $(this).scrollTop()) {
    $(this).animate({scrollTop: desiredOffset}, 500);
  }
});
