/***************************************************************************
 * Since we use anchor notation to do ajax navigation, we need to handle
 * this in a special manner.  This method will scroll to the target
 **************************************************************************/
ht.scrollToTarget = function (targetId) {
  try {
    // find target by id
    var target = $('#' + targetId);
    // find where the target lives
    var elementPos = $(target).offset().top;
    // scroll to the position
    $(window).scrollTop(elementPos);
  } catch (e) {
    ht.log(e);
  }
};
