// verifyAction buttons and links
ht.declare(
  'input.verifyAction, button.verifyAction, a.verifyAction',
  function () {
    if ($(this).metadata().verifyActionMessage) {
      var oldSerialized = $(this).parents('form').serialize();
      $(this).on('click', function () {
        if (
          $(this).metadata().alwaysVerify ||
          oldSerialized != $(this).parents('form').serialize()
        ) {
          var answer = window.confirm($(this).metadata().verifyActionMessage);
          return answer;
        } else {
          return true;
        }
      });
    }
  }
);
