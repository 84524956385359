if (ht.CentralExchange) {
  //take action when I frame is in various states
  ht.declare('iframe.syncSize', function () {
    var self = this;
    var isLoading = false;
    ht.CentralTopic('iframeloading').subscribe(function () {
      //show loading while we load iframe contents
      if (!isLoading) {
        isLoading = true;
        ht.showLoading('body');
      }
    });
    ht.CentralTopic('iframeloaded').subscribe(function () {
      $(self).height(self.contentDocument.body.offsetHeight + 35);
      ht.hideLoading();
      isLoading = false;
    });
  });
}
