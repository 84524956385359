if (ht.CentralExchange) {
  $('body').on(
    'click',
    'a.publishSessionRenew, button.publishSessionRenew',
    function () {
      ht.CentralTopic('RenewSession').publish(true);
      if ($(this).data('wasFocused')) {
        $(this).data('wasFocused').focus();
      }
      return false;
    }
  );
}
