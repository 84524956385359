// csvhelper will work for the following categorization templates
var csvHelperWhitelist = [
  'providerisinzipcode',
  'memberaddresszip',
  'providerspecialty',
  'userswithpracticetin',
  'providerisinzipcode',
  'providerspecialty',
  'memberclaimdiagnosis'
];
//See connect/connect-htcweb-impl/src/main/java/com/healthtrio/htcweb/web/app/appconf/categorization/ConditionConfig.java . Catman templates can only have <256 entries.
var maxTemplateEntries = 256;

function getTemplateNode(event) {
  return $(event.target).parent().clone();
}
function isUniqueCallback(value, index, self) {
  return self.indexOf(value) === index;
}
function getCurrentValues(event) {
  return $('.csvHelper')
    .not(event.target)
    .map(function () {
      return $(this).val();
    })
    .toArray();
}

function getCSV(event) {
  return event.target.value.split(',');
}

function wipeList() {
  $('#createRuleWrapper .detailList li.workingParamValueListItem').remove();
}

function generateItemToInject(value, template, paramNum, index) {
  var componentToInject = template.clone();
  var inputName = 'p:' + paramNum + ':' + index;
  $(componentToInject).children('.csvHelper').attr('name', inputName);
  $(componentToInject).children('.csvHelper').val($.trim(value));
  $(componentToInject).children('label.error').attr('for', inputName);
  $(componentToInject).children('.removeElement').show();
  return componentToInject;
}
function reinjectListItem(value, template, paramNum, index) {
  var componentToInject = generateItemToInject(
    value,
    template,
    paramNum,
    index
  );
  $('#createRuleWrapper .detailList li.operatorParamListItem').after(
    componentToInject
  );
}

function injectEmptyInput(template, paramNum, index) {
  var componentToInject = generateItemToInject('', template, paramNum, index);
  $('#createRuleWrapper .detailList li:nth-last-child(2)').after(
    componentToInject
  );
}
function reinjectList(values, template, paramNum) {
  for (var index = values.length - 1; index >= 0; index--) {
    reinjectListItem(values[index], template, paramNum, index);
  }
  // add back in an empty input at the end if possible
  if (values.length + 1 < maxTemplateEntries) {
    injectEmptyInput(template, paramNum, values.length);
  }
}

$(document).on('blur keypress', '.csvHelper', function (event) {
  if (event.type === 'keypress' && event.which !== 13) {
    return;
  }

  var template = $(this).data('template');
  var paramNum = $(this).data('paramnum');
  var modalId = $(this).data('modalid');

  var isCSV = event.target.value.includes(',');
  var csvHelperEnabled = csvHelperWhitelist.includes(template);

  if (csvHelperEnabled && isCSV) {
    var templateNode = getTemplateNode(event);
    var currentValues = getCurrentValues(event);
    var commaSeparatedValues = getCSV(event);
    var currentAndCommaSeparatedValues = $.merge(
      currentValues.slice(),
      commaSeparatedValues.slice()
    ).filter(isUniqueCallback);

    if (currentAndCommaSeparatedValues.length > maxTemplateEntries) {
      ht.modalOpen({modalId: modalId});
      wipeList();
      reinjectList(currentValues, templateNode, paramNum);
    } else {
      wipeList();
      reinjectList(currentAndCommaSeparatedValues, templateNode, paramNum);
    }
  }
});
