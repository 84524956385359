ht.processDeclarations = function (context) {
  try {
    // only fire if there are actual nodes
    if ($(context).length > 0) {
      ht._declarations = ht._declarations
        ? ht._declarations
        : $.Callbacks('memory');
      ht._declarations.fire(context);
    }
  } catch (e) {
    ht.log('ht.processDeclarations exception', context, e);
  }
};
