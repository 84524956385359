$(document).on('change', '.toggleCheckElements', function () {
  var opts = $(this).metadata().toggleCheckElementsOpts;

  var targetSelector = $(opts.targetSelector);
  var sourceSelector = $(opts.sourceSelector);

  if ($(sourceSelector).is(':checked')) {
    targetSelector.prop('checked', false);
  } else {
    targetSelector.prop('checked', true);
  }
});
