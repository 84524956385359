/* Plugins for tinymce
 *
 * advlist: enables you to set number formats and bullet types
 * autolink: Normalizes behaviour with IE that produces links when you simply type links in the content window.
 * link: enables you to insert links
 * code: gives the user a modal popup to hand code html
 * image: adds a insert image button and menu item
 * lists: normalizes list behaviour between browsers
 * textcolor: adds the forecolor/backcolor button controls
 *
 * !!!!!!!!!!!!!!!!!!!!IMORPOTANT!!!!!!!!!!!!!!!!!
 * DO NOT USE autoresize, IT CAUSES ERRORS WHEN TOGGLING
 * autoresize: automatically resizes the editor to the content inside it
 */
ht.createTinyMCE = function (options) {
  var opts = {
    selector: '.ftlhtmleditor'
  };
  // if options exist overwrite the default options
  opts = $.extend(opts, options);
  tinymce.init({
    selector: opts.selector,
    entity_encoding: 'numeric',
    statusbar: false,
    plugins: 'advlist autolink link image lists textcolor code',
    menubar: 'edit insert view format',
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 26pt 36pt',
    toolbar1:
      'formatselect | fontselect | fontsizeselect | forecolor backcolor',
    toolbar2:
      'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link image | code',
    relative_urls: false,
    // By default, skinning assets are fetched from the CDN. We need to override
    // that so that we can increase the specificity of the CSS selectors.
    skin_url: '/lib/suicompat-tinyMceSkin',
    content_style: `
           body {
             font-size: 12pt;
           }
         `,
    setup: function (editor) {
      editor.on('click', function (event) {
        if (opts.autofocusId) {
          // class example=> ftlhtmleditor {tinymceOpts: {autofocusId:'messageBody'}}
          tinymce.get(opts.autofocusId).focus(); // if editor clicked, focus the editable area
        }
      });

      editor.on('blur', function (event) {
        $(editor.getElement()).valid();
      });
    }
  });
};
