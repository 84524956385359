if (ht.smartuishell) {
  // Fix the positioning of this special copyright footer in Code Search
  ht.declare('#daDiv', function () {
    if (!$('#Shell-nav').hasClass('ht-Shell-nav--left')) return;

    var footerContainer = $('<div />')
      .appendTo('#Shell-functionalArea')
      .addClass('ht-legacy');

    var paddingY = $('#mainContentWrapper').css('padding-top');
    var paddingX = $('#mainContent').css('padding-left');

    $('#Shell-main').css({position: 'relative'});
    $('#daDiv')
      .appendTo(footerContainer)
      .css('padding', paddingY + ' ' + paddingX);
  });
}
