ht.itemCounter = function (counterElement, value = ':checked') {
  var opts = $(counterElement).metadata().itemParams;
  var itemToCount = $(opts.itemToCount).length;
  var counter = 0;
  for (var i = 0; i < itemToCount; i++) {
    if ($($(opts.itemToCount)[i]).is(value)) {
      counter += 1;
    }
  }

  $(opts.targetSelector).html('(' + counter + ')');
};
