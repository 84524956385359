//create runtime patches for our livequery libs

//check to see if the livequery plugin is include. Mobile no longer uses livequery
if ($.livequery) {
  // patch livequery to allow for external halting of the process
  $.extend($.livequery, {
    halted: false,
    halt: function () {
      $.livequery.halted = true;
    },
    resume: function () {
      $.livequery.halted = false;
    }
  });
  var oldRun = $.livequery.run;
  $.livequery.run = function () {
    if (!$.livequery.halted) {
      oldRun.apply(this, arguments);
    }
  };

  // patch validate to use the livequery.halt functionality
  var oldForm = $.validator.prototype.form;
  $.validator.prototype.form = function () {
    $.livequery.halt();
    try {
      var r = oldForm.apply(this, arguments);
    } catch (e) {
      // not much for us to do if something went wrong
      ht.log(e);
    } finally {
      $.livequery.resume();
      return r;
    }
  };
}
