function intToChar(i) {
  return (
    (i >= 26 ? intToChar(((i / 26) >> 0) - 1) : '') +
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0]
  );
}

ht.setMarkerInfo = function (posData, index, opts) {
  var markerImgURL =
    index === 'Home'
      ? '/images/ex/maps/markerHome.svg'
      : '/images/ex/maps/marker.svg';
  var markerLabel = index === 'Home' ? '' : intToChar(index);
  var markerLabelColor = Object.prototype.hasOwnProperty.call(
    opts || {},
    'markerLabelColor'
  )
    ? opts.markerLabelColor
    : '#000';
  var markerImg = {
    url: markerImgURL,
    size: new google.maps.Size(26, 34),
    scaledSize: new google.maps.Size(26, 34),
    labelOrigin: new google.maps.Point(14, 13)
  };
  var markerInfo = {
    //set the postion of the marker
    latlng: new google.maps.LatLng(posData.lat, posData.long),
    markerImg: markerImg,
    scaledSize: new google.maps.Size(100, 100)
  };
  if (index !== 'Home') {
    markerInfo.label = {
      text: markerLabel,
      fontWeight: 'bold',
      color: markerLabelColor
    };
  }
  //merge the base opts with the passed in opts
  opts = $.extend({}, markerInfo, opts);
  return opts;
};
