// generic template for rendering JSON as HTML
ht.jsonTmpl = function (jsonData, opts) {
  var defaults = {
    noDataMsg: 'No data to display',
    topLevelClass: 'jsonView'
  };
  opts = $.extend({}, defaults, opts);

  function renderItem(item) {
    var itemInnards;
    if ($.isArray(item)) {
      itemInnards = item.length ? renderArray(item) : opts.noDataMsg;
    } else if ($.isPlainObject(item)) {
      itemInnards = renderObj(item);
    } else {
      item = typeof item === 'undefined' || item === '' ? opts.noDataMsg : item;
      itemInnards = item.toString();
    }
    return itemInnards;
  }

  function renderArray(arr) {
    var result = $('<ul />');
    $.each(arr, function (k, v) {
      $('<li />').append(renderItem(v)).appendTo(result);
    });
    return result;
  }

  function renderObj(obj) {
    var result = $('<dl />');
    $.each(obj, function (k, v) {
      $('<dt />').append(k).appendTo(result);
      $('<dd />').append(renderItem(v)).appendTo(result);
    });
    return result;
  }

  return $('<div />').addClass(opts.topLevelClass).append(renderItem(jsonData));
};
