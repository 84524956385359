ht.getDirections = function (opts) {
  loadGoogleMaps(this, function () {
    var directionDisplay;
    var rendererOptions = {draggable: true};
    var directionService = new google.maps.DirectionsService();

    directionDisplay = new google.maps.DirectionsRenderer(rendererOptions);
    var homeLat = $('#newLat').val();
    var homeLong = $('#newLong').val();
    var start = homeLat + ' , ' + homeLong;
    var end = opts.lat + ' , ' + opts.long;

    var mapOptions = {
      zoom: 7,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: new google.maps.LatLng(homeLat, homeLong)
    };
    var map = new google.maps.Map(
      document.getElementById('modalMap'),
      mapOptions
    );

    directionDisplay.setMap(map);
    directionDisplay.setPanel(document.getElementById('directionsPanel'));

    var request = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.DRIVING
    };

    directionService.route(request, function (response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        $('#directionsPanel').empty();
        directionDisplay.setDirections(response);
      }
    });
  });
};
