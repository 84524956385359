// Fix inconsistancies in buttons *temporary solution*
// TODO: Run a reg-ex search/replace on the app to fix buttons

// back buttons
$('body').on(
  'click',
  '#back:button, button.back, input[type=button].back, a.back',
  function () {
    history.back();
    return false;
  }
);
