$('body').on(
  'click',
  'a.popupDataExchange, button.popupDataExchange',
  function () {
    var self = $(this),
      selector =
        self.data('exchangeSelector') != null
          ? self.data('exchangeSelector')
          : self;
    ht.CentralTopic('popupDataExchange').publish(selector);
  }
);
