//at least on checkbox must be checked to complete the for submit
$('form.requireCheckedOnSubmit').on('submit', function () {
  var opts = $(this).metadata().requireCheckedOpts;
  var n = $(this).find('input:checked').length;

  if (n == 0) {
    if (opts) {
      alert(opts.message);
    }
    return false;
  }
});
