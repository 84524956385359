/***************************************************************************
 *combineSiblingValues function allows for combining values of multiple html elements
 * into a hidden input for form submit
 * */
ht.combineSiblingValues = function (siblingDependent) {
  var newValue = '';
  var siblings = $(siblingDependent).metadata().siblingDependentOpts.selectors;
  var length = siblings.length;
  for (var i = 0; i < length; i++) {
    newValue += $(siblingDependent).siblings(siblings[i]).val();
  }
  $(siblingDependent).val(newValue);
};
