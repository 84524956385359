//twitter bootstrap tooltip
ht.declare('.tb-tooltip', function () {
  var self = $(this);
  self.tooltip({container: 'body'});
  //if pub/sub is supported
  if (ht.CentralExchange) {
    //subscribe to orientation change
    ht.CentralTopic('orientaitionChange').subscribe(function () {
      //remove the tooltip BEFORE the container is deleted
      self.tooltip('hide');
    });
  }
});

//TODO: Should we bind the click in the ht.declare()?
$('body').on('click', '.tb-tooltip', function () {
  var self = $(this);
  self.tooltip('hide');
});
