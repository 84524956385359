/**
 * Resize the modal (if there is one) after an update there is a listener
 * set up on document load which listens for "ajaxComplete" and it will call
 * this function in case there happens to be a modal that needs adjusting.
 */
ht.resizeModal = function (modal) {
  if (modal == undefined || modal == null || modal.length == 0) {
    modal = $('.blockUI.blockMsg');
    if (modal.length == 0) {
      // nothing to do
      return;
    }
  }

  var element = $(modal);
  var width = element.width();
  var bodyWidth = $('body').width();
  var bodyOffset = $('body').offset();
  var screenWidth = bodyWidth + bodyOffset.left * 2;
  element.css({
    position: 'absolute',
    left: screenWidth / 2 - width / 2,
    //use absolute so the modal never goes negative
    top: Math.abs(
      $(window).height() / 2 - element.height() / 2 + $(window).scrollTop()
    )
  });
  return element;
};
