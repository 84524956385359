/***************************************************************************
 * handleAjaxAddressChange - when the ajax portion of the address changes
 * (the part after the #) this handler is called to determine the correct
 * action
 **************************************************************************/
ht.handleAjaxAddressChange = function (evt) {
  if (ht.handleAnchorNav(evt) == null) {
    // check if this address is tied to a specific element
    if (
      ht.parseUrlQuery(evt.value).ajaxNavId &&
      ht.parseUrlQuery(evt.value).ajaxNavId != $(this).attr('id')
    ) {
      return; // continue propagation
    }

    var reqUrl = ht.translateAjaxUrl(evt.value, this);
    if (
      $(this).hasClass('reloadable') ||
      reqUrl != $(this).data('currentAjaxUrl')
    ) {
      // never add this to history. It was triggered by a history call
      $(this).trigger('ajaxNavigate', {url: reqUrl, addToHistory: false});
    }
  }
};
