//FIXME: This could probabably use a smartshell conditional
// Deal with top nav
$(document).on(
  'focusin',
  '#dropNav ul#navigationList > li.navHeader',
  function () {
    $(this).addClass('sfhover');
  }
);
$(document).on(
  'focusout',
  '#dropNav ul#navigationList > li.navHeader',
  function () {
    $(this).removeClass('sfhover');
  }
);
