//TODO: make this re-useable
ht.declare('input.ajaxAutocomplete', function () {
  var opts = $(this).metadata().ajaxAutocompleteOpts || {};
  var url = './autoCompleteSearchSubmit';
  if (opts.includeSpecialties) {
    url = './autoCompleteSearchIncludingSpecialtiesSubmit';
  }
  if (opts.isRefineSearch) {
    url = url + '?refineByMember=true';
  }
  // Compile Handlebars templates for autocomplete results
  var tmpls = {
    address: ht.compileHandlebars({source: '#tmpl-address'}),
    npi: ht.compileHandlebars({source: '#tmpl-npi'}),
    practice: ht.compileHandlebars({source: '#tmpl-practice'}),
    pid: ht.compileHandlebars({source: '#tmpl-pid'}),
    specialty: ht.compileHandlebars({source: '#tmpl-specialty'}),
    license: ht.compileHandlebars({source: '#tmpl-license'})
  };

  $(this)
    .autocomplete({
      appendTo: ht.smartuishell ? '#smartuiLegacyAppendable' : null,
      source: function (request, response) {
        var data = 'searchString=' + request.term;

        //queryString isn't always defined, if it is use it, other wise omitt.
        if ($('#queryString').val()) {
          var data = data + $('#queryString').val();
        }

        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          data: data,
          success: function (data) {
            var resultSet = $.merge(
              data.AUTOCOMPLETE_RESULTS.PROVIDER_MAP || [],
              data.AUTOCOMPLETE_RESULTS.SPECIALTY_MAP || []
            );
            response(resultSet);
          }
        });
      },
      minLength: 3,
      focus: function (e) {
        // prevent value inserted on focus
        ht.preventDefault(e);
      },
      select: function (e, ui) {
        if (ui.item.type == 'PROVIDER') {
          //go to provider detail page
          //Sometimes Start Row and Query String are not defined, make sure they are defined and append them to the URL before going. They are not always necessary.
          if ($('#selectPcp').val()) {
            var sessionURL =
              './providerDetailSelectPcp.page?returnUrlKey=' +
              $('#returnUrlKey').val() +
              '&providerID=' +
              ui.item.value.replace('<em>', '').replace('</em>', '') +
              '&sequenceNumber=' +
              ui.item.sequence +
              '&latitude=' +
              ui.item.latitude +
              '&longitude=' +
              ui.item.longitude;
          } else {
            var sessionURL =
              './providerDetail.page?providerID=' +
              ui.item.value.replace('<em>', '').replace('</em>', '') +
              '&sequenceNumber=' +
              ui.item.sequence +
              '&latitude=' +
              ui.item.latitude +
              '&longitude=' +
              ui.item.longitude;
          }

          //sometimes the med group id contains &
          // sometimes it's not even there...
          if (ui.item.medgroup) {
            var medGroupId = encodeURIComponent(ui.item.medgroup);
            sessionURL = sessionURL + '&medgroupID=' + medGroupId;
          }

          if ($('#ajaxStartRow').val()) {
            var sessionURL =
              sessionURL + '&startRow=' + $('#ajaxStartRow').val();
          }

          if ($('#queryString').val()) {
            var sessionURL = sessionURL + $('#queryString').val();
          }

          // Checks if an initTab value is present. There are 3 tabs that can be used details, contactinformation, and locations. These can be changed/set with the i18n label cpd.details.inittab
          if ($('#searchString').length) {
            if ($('#searchString').data('init-tab').trim().length) {
              var sessionURL =
                sessionURL +
                '&initTab=' +
                $('#searchString').data('init-tab').trim();
            }
          }

          window.location = ht.setSessionUrl(sessionURL);
        } else if (ui.item.type == 'SPECIALTY') {
          //set the value of the provider specialty ddlb
          // Fix by Josh to handle case matching problems
          $(this).val('');
          var found = false;
          $("select[name='providerSpecialty'] option").each(function () {
            if ($(this).val().toLowerCase() == ui.item.value) {
              $(this).prop('selected', 'selected');
              found = true;
            } else {
              $(this).removeProp('selected');
            }
          });
          if (!found) {
            if ($("select[name='providerSpecialty']").length > 0) {
              $("select[name='providerSpecialty']").append(
                "<option value='" +
                  ui.item.value +
                  "' selected >" +
                  ui.item.display.replace('<em>', '').replace('</em>', '') +
                  '</option>'
              );
            } else {
              $(this)
                .closest('form')
                .append(
                  "<input name='providerSpecialty' type='hidden' value='" +
                    ui.item.value +
                    "'/>"
                );
            }
          }
          $(this).closest('form').submit();
        } else {
          //set this value in the input box
          $(this).val(ui.item.label);
          //$("select[name='sortType'] option:selected").val('SCORE');
        }
        //prevent ui-autocomplete default actions
        ht.preventDefault(e);
      }
    })
    .data('uiAutocomplete')._renderItem = function (ui, item) {
    var result = $('<li />')
      .addClass('autocompleteResult')
      .data('item.autocomplete', item);
    var link = $('<a />').addClass('autocompleteResult-link').appendTo(result);
    var additionalInfo = '';
    link.append(
      '<span class="autocompleteResult-name">' +
        '<h3>' +
        item.display +
        '</h3>' +
        '<p>' +
        item.medgroup_name +
        '</p>' +
        '<p>' +
        item.address1 +
        '</p>' +
        '<p>' +
        item.address2 +
        '</p>' +
        '<p>' +
        item.city +
        ', ' +
        item.state +
        ' ' +
        item.zip +
        '</p>' +
        '</span>'
    );

    // Expects the JSON result to have the matched term wrapped in
    // an <em> tag; so whichever field has that <em> tag includes the match.
    // Code below find the matched field (if not name, which is displayed by default)
    // add displays it.
    function hasEm(str) {
      return /\<em\>/.test(str);
    }
    // check medgroup_name and address,
    // prioritizing medgroup_name and not displaying both
    var addressStr = item.address1;
    fullAddress =
      item.address1 && item.address2
        ? item.address1 + ', ' + item.address2
        : item.address2
        ? item.address2
        : item.address1;
    if (hasEm(item.medgroup_name) && item.medgroup_name !== item.display) {
      additionalInfo = tmpls.practice({data: item.medgroup_name});
    } else if (hasEm(fullAddress)) {
      additionalInfo = tmpls.address({data: fullAddress});
    }
    // check ids
    if (hasEm(item.npi)) {
      additionalInfo = tmpls.npi({data: item.npi});
    } else if (hasEm(item.value)) {
      additionalInfo = tmpls.pid({data: item.value});
    }

    if (hasEm(item.license)) {
      additionalInfo = tmpls.license({data: item.license});
    }
    // if type is "provider" ignore;
    // if specialty, display that
    if (item.type.toLowerCase() === 'specialty') {
      additionalInfo = tmpls.specialty();
    }

    link.append(additionalInfo);

    return result.appendTo(this.menu.element);
  };
});
