// formLink replaces a form element with the text inside of a link
// element
// then if it it is a validating form, validate this field
$('body').on('click', 'a.formLink', function () {
  var sel = $(this).metadata().selector;
  if (sel) {
    $(sel)
      .val($(this).html())
      .parents('.applyValidate')
      .validate()
      .element($(sel));
    return false;
  }
});
