if (ht.CentralExchange) {
  $('#sessionWarning').each(function () {
    var self = this;
    ht.CentralTopic('SessionPhase').subscribe(function (phaseName) {
      if (phaseName == 'Warning') {
        $(self).show();
        var wasFocused = $(':focus');
        $('.publishSessionRenew').data('wasFocused', wasFocused);
        $('.publishSessionRenew').focus();
      } else if (phaseName == 'Normal' || phaseName == 'Expired') {
        $(self).hide();
      }
    });
  });
}
