$('.switchGenders').on('click', function () {
  var opts = $(this).metadata().gender;
  if (opts == 'male') {
    $('.female').hide();
    $('.male').show();
    $('#body-male-front').show();
    $('#body-male-back').hide();
  } else {
    $('.male').hide();
    $('#body-female-front').show();
    $('#body-female-back').hide();
    $('.female').show();
  }
});
