/*
 * Toggle hidden or visible element.
 * @param {HTMLElement} el - The DOM node which the tooltip is referencing / hovering over.
 * @param {Object[]} opts - Object containing options.
 * @param {string} opts[].targetSelector - The selector for the element to be toggled.
 * @param {boolean} opts[].elementToggle - Whether the element is toggleable when visible. Set to true if you want to do
 * hidey showey.
 * @param {Object[]} targetCssOnVisibleComplete - Object containing key value pairs where key is css property and value
 *  is the value of that property.
 * */
ht.toggle = function (opts) {
  var el = $(opts.targetSelector);

  var onShowComplete = function () {
    ht.syncLayoutDimensions;
    if (opts.hasOwnProperty('targetCssOnVisibleComplete')) {
      el.css(opts.targetCssOnVisibleComplete);
    }
  };
  if (el.is(':hidden') && !opts.hideTarget) {
    opts.noSlide
      ? el.show(400, onShowComplete)
      : el.slideDown('slow', onShowComplete);
  } else if ((el.is(':visible') && opts.elementToggle) || opts.hideTarget) {
    opts.noSlide
      ? el.hide(400, ht.syncLayoutDimensions)
      : el.slideUp('slow', ht.syncLayoutDimensions);
  }
  return false;
};
