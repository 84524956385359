$(document).on(
  'blur',
  "input[data-password-input-on-blur='true']",
  function () {
    $(this).attr({type: 'password'});
  }
);

$(document).on(
  'focus',
  "input[data-password-input-on-blur='true']",
  function () {
    $(this).attr({type: 'text'});
  }
);
/* Convert to password input on page load. */
$("input[data-password-input-on-blur='true']").attr({type: 'password'});
