/***************************************************************************
 * showLoading - places a loading indicator in the element passed in and
 * sets it to be the size of el
 **************************************************************************/
ht.showLoading = function (el, clearLoadingPopupOnClick) {
  if (el == undefined || $(el).length == 0) {
    el = 'body';
  }
  var loadingEl;
  if (ht.smartuishell) {
    var i18nLoading = ht.smartuishell
      ? htc.global.messages['common.loading']
      : htc.global.messages.loading;

    loadingEl = $(
      '<div id="legacyLoadingDivContainer" class="ht-legacy">' +
        '<div id="htcLoadingDiv" class="htcLoadingDiv ht2-InterstitialLoadingOverlay"><div class="clearfix ht2-InterstitialLoadingOverlayInner">' +
        '<strong class="loading ht2-InterstitialLoadingOverlayLoadingText">' +
        i18nLoading +
        '</strong>' +
        '</div></div></div>'
    );
  } else {
    var i18nLoading = ht.smartuishell
      ? htc.global.messages['common.loading']
      : 'Loading...';
    loadingEl = $(
      '<div id="htcLoadingDiv" class="htcLoadingDiv ht2-InterstitialLoadingOverlay"><div class="clearfix ht2-InterstitialLoadingOverlayInner">' +
        '<strong class="loading ht2-InterstitialLoadingOverlayLoadingText">' +
        i18nLoading +
        '</strong>' +
        '</div></div>'
    );
  }

  var pend = loadingEl.css({
    position: 'absolute',
    width: '100%',
    height: $(el).height(),
    'min-height': '100%',
    top: '0',
    left: '0',
    'background-color': '#fff',
    opacity: '0.75',
    'z-index': '9999'
  });
  $(el).append(pend);

  if (clearLoadingPopupOnClick) {
    $('body').on('click', '#htcLoadingDiv', function () {
      $(this).remove();
    });
  }
};
