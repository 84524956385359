// ajaxLink replaces the contents of a specified element with the
// results of a call to
// the uri specified in the link
// **************
// WARNING: .ajaxLink must inside a .managedNav div to work if inside a modal
// (.managedNav listens for the ajaxNavigate event)
// **************
$('body').on('click', 'a.ajaxLink', function (e) {
  e.preventDefault();
  var self = $(this);
  ht.triggerAjaxNavigate(self);
});
