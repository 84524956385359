//TODO: This can easily be refactored to be resuseable
//css dropdown stuff
$('ul.dropdown-menu')
  .find('form')
  .click(function (e) {
    e.stopPropagation();
  });

function updateDropDown(e) {
  var desiredVal = e.target.text;
  $(e.delegateTarget)
    .parent('.dropdown')
    .children('a')
    .first()
    .text(desiredVal);
}

$('.dropdown-menu' && '.update-display-value').on('click', updateDropDown);
