ht.placement = function (opts) {
  if (opts.placementSelector && opts.target) {
    switch (opts.placementPosition.toLowerCase()) {
      case 'append':
        $(opts.placementSelector).append(opts.target);
        break;
      case 'prepend':
        $(opts.placementSelector).prepend(opts.target);
        break;
      case 'before':
        //before *propbably* doesnt work with ht.processDeclarations
        $(opts.placementSelector).before(opts.target);
        break;
      case 'after':
        //after doesnt work with ht.processDeclarations
        $(opts.placementSelector).after(opts.target);
        break;
      case 'replacewith':
        $(opts.placementSelector).replaceWith(opts.target);
        break;
      default:
        //TODO: why isnt append the default?
        $(opts.placementSelector).before(opts.target);
        break;
    }
    ht.processDeclarations($(opts.placementSelector));
  } else {
    console.log('Missing required ht.placement param');
  }
};
