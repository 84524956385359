// IMPORTANT: This event code needs to be run after many other events
// are put into the queue so that
// if other actions need to be taken on elements within the popup, they
// will remain. Event
// handlers are acted upon synchronously
//closes generic modal window using block ui plugin jquery_block_ui.js
$('body').on('click', '.modalClose, div.blockOverlay', function (event) {
  var self = $(this);
  var closeBtnOpts = $('.modalClose:visible').data('closeOpts');
  // closeBtnOpts can have the following params:
  // closeMsgSelector - a complete selector "#idOfElement", ".classname", etc to show without closing modal if it's not already showing
  // closeMsgSkipIfMissing - a complete selector that, if element not present (length 0), allow closing

  //if we have close button metadata and an anchor tag, presumably the close x, or a div, presumably the block ui blocker div are clicked
  //show the closeMsgSelector, should be a container div and return
  //IMPORTANT - A button or input with the class of modalClose will skip this logic, this was done intentionally as a way to force close a modal within the verify container
  if (
    event.originalEvent !== undefined &&
    closeBtnOpts &&
    (self.get(0).nodeName.toLowerCase() === 'a' ||
      self.get(0).nodeName.toLowerCase() === 'div') &&
    (!closeBtnOpts.closeMsgSkipIfMissing ||
      $(closeBtnOpts.closeMsgSkipIfMissing).length > 0)
  ) {
    if (
      closeBtnOpts.closeMsgSelector &&
      !$(closeBtnOpts.closeMsgSelector).is(':visible')
    ) {
      $(closeBtnOpts.closeMsgSelector).show();
      return false;
    } else {
      return false;
    }
  }

  if (self.hasClass('keepOpen')) {
    return false;
  } else {
    $.unblockUI({
      onUnblock: function () {
        if (self.parents('#modal').length > 0) {
          ht.CentralTopic('modalClosed').publish(self.parents('#modal'));
        } else {
          ht.CentralTopic('modalClosed').publish();
        }
        //hide h30 loader, used for ajax closeModalOnSuccess
        ht.hideLoading();
        //If there are any tinymce editors in the modal, remove them
        ht.calculateEditorsToKeep();
      }
    });
    // the following is just to make sure we don't have listeners still waiting for old results
    $(document).unbind('popupResult');

    //for mobile
    if (localStorage.mobile != null) {
      $('#contextNavButton').removeClass('open');
      $('#contextNav').css('display', 'none');
    }
    return false;
  }
});
//when you tab off cancel in a modal close it
$(document).on('keypress', '.modalClose', function (e) {
  //check if tab key was pressed
  var keyCode = e.keyCode || e.which;
  if (keyCode == 9) {
    //trigger click on modalClose
    $(this).trigger('click');
    e.stopPropagation();
    ht.preventDefault(e);
  }
});
