// HTML5 support container
ht.support = ht.support ? ht.support : {};
try {
  ht.support.localStorage =
    'localStorage' in window && window['localStorage'] !== null;
  if (ht.support.localStorage) {
    window.localStorage.setItem('ht-storage-available', 1);
    window.localStorage.getItem('ht-storage-available');
    window.localStorage.removeItem('ht-storage-available');
  }
} catch (e) {
  ht.support.localStorage = false;
}
ht.support.localStorageEvent =
  window.addEventListener && ht.support.localStorage;
