ht.declare('form', function () {
  if ($(this).attr('action') && jQuery(this).data('xsessProcessed') == null) {
    if (
      $(this).attr('method') &&
      $(this).attr('method').toUpperCase() == 'POST'
    ) {
      // we use the explicit call to set the node because IE has issues with $.attr when an element named action is present in the form
      var newAction = ht.setSessionUrl($(this).attr('action'));
      $(this)[0].getAttributeNode('action').nodeValue = newAction;
    } else {
      var myHost = (window.location + '').match(ht.urlRegex)[8];
      var r = $(this).attr('action').match(ht.urlRegex);
      // do not add this to the URL for any external GET requests
      if (r[8] == undefined || r[8] == myHost || r[8] == '') {
        $(this).append(
          "<input type='hidden' name='xsesschk' value='" +
            ht.getSessionToken() +
            "' />"
        );
      }
    }
    jQuery(this).data('xsessProcessed', true);
  }
  $(this)
    .find('input.preventSubmit, button.preventSubmit')
    .on('keypress', function (e) {
      if (e.which == 13) {
        return false;
      }
    });
  $(this).on(
    'click',
    'button.clearForm, input[type=button].clearForm, a.clearForm',
    function () {
      var opts = $(this).metadata().clearFormOpts;
      //clears the closest form data from the link/button clicked
      var form = $(this).closest('form');
      form.clearForm();
      if (opts && opts.submitForm) {
        form.submit();
      }
      return false;
    }
  );
});
