if (ht.CentralExchange) {
  //sub/pub to get iframe states(loading, ready, etc)
  ht.declare('iframe', function () {
    if (this !== top) {
      var iframe = $(this);
      if (iframe) {
        iframe.contentDocument = document; //normalization: some browsers don't set the contentDocument, only the contentWindow

        $(window.parent.document).ready(function () {
          //wait for parent to make sure it has jQuery ready
          ht.CentralTopic('iframeloading').publish();
          ht.CentralTopic('iframeready').publish();
        });

        iframe.on('load', function () {
          //kind of unnecessary, but here for completion
          ht.CentralTopic('iframeloaded').publish();
        });

        iframe.on('unload', function (e) {
          //not possible to prevent default
          ht.CentralTopic('iframeunloaded').publish();
        });

        iframe.on('beforeunload', function () {
          ht.CentralTopic('iframebeforeunload').publish();
        });
      }
    }
  });
}
