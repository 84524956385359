/***************************************************************************
 * parseUrlQuery - simple utility function to return an object representing
 * the query part of the url
 */
ht.parseUrlQuery = function (urlQueryString) {
  var queryObj = {};

  $.each(
    (urlQueryString.match(ht.urlRegex)[14] || '').split('&'),
    function (i, p) {
      p = unescape(p.replace(/\+/g, ' ')).match(/^([^=]*)(?:=)?(.*)$/im);
      if (p[1].length > 0) {
        queryObj[p[1]] = queryObj[p[1]]
          ? queryObj[p[1]] instanceof Array
            ? (queryObj[p[1]].push(p[2]), queryObj[p[1]])
            : [queryObj[p[1]], p[2]]
          : p[2];
      }
    }
  );
  return queryObj;
};
