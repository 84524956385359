/***************************************************************************
 * declare - function to handle changes in the DOM in order to attach
 *   behavior. Basically a replacement for livequery in many cases.
 *   When an element matching selector enters the DOM, entryCall is called
 *   with the element set as "this" for that method.
 **************************************************************************/
ht.declare = function (selector, handler, forceContext, notSelector) {
  ht._declarations = ht._declarations
    ? ht._declarations
    : $.Callbacks('memory');
  ht._declarations.add(function (context) {
    try {
      context = forceContext ? forceContext : context;
      if (notSelector) {
        $(selector, context).not(notSelector).each(handler);
      } else {
        $(selector, context).each(handler);
      }
    } catch (e) {
      ht.log('ht.declare exception', selector, handler, e);
    }
  });
};
