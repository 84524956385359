if (ht.CentralExchange) {
  ht.declare('#SMCUnread', function () {
    var self = this;
    ht.CentralTopic(
      'UnreadSecureMessageCount',
      ht.CentralMessagingManager.messagingFlags
    ).subscribe(function (unreadCount) {
      // set the text of this to the value
      $(self).text(unreadCount);
      // then check to see if a parent of this needs to have a class changed
      var hasUnread = unreadCount > 0;
      if (hasUnread) {
        $(self).parents('a#messageCenter').addClass('newMessages');
      } else {
        $(self).parents('a#messageCenter').removeClass('newMessages');
      }
    });
  });
}
