/***************************************************************************
 validateAsync uses a call to a remote resource to validate form input.
 disableIds - a string of input IDs, separated by spaces, you'd like to
 make disabled if an error is returned
 **************************************************************************/
ht.validateAsync = function (value, element, options) {
  var validator = this;
  var defaultOptions = {
    submitForm: false,
    successClass: 'ajaxValidateSuccess',
    failureClass: 'ajaxValidateFailure'
  };
  var syncReturnVal = null;
  options = $.extend(defaultOptions, options);
  var async = options.forceSync ? false : true;
  // all labels targeting the input -- could be more than one
  var errLbl = $(element)
    .parents('form')
    .find("label.error[for='" + element.name + "']");

  if (options.url) {
    //store the original error message on first run
    if ($(element).data('orignalErrMsg') == null) {
      $(element).data('orignalErrMsg', errLbl);
    }

    if ($(element).data('prevVal')) {
      // see if the previous was the same value
      if ($(element).data('prevVal') == value) {
        return $(element).data('prevStat');
      }
      // see if we need to cancel a request
      if ($(element).data('pendingAsync')) {
        $(element).data('pendingAsync').abort();
        $(element).removeData('pendingAsync');
      }
    }
    // book keeping
    $(element).data('prevVal', value);
    $(element).data('prevStat', 'pending');

    // establish data to be sent
    var data = {};
    if (options.submitForm) {
      data = $(element).parents('form').serialize();
    } else {
      data[element.name] = value;
    }
    // now make the actual request
    $(element).data(
      'pendingAsync',
      $.ajax({
        url: options.url,
        type: 'POST',
        async: async,
        data: data,
        dataType: 'html',
        success: function (result) {
          // get error messages from the result
          var errorContainer = $(result)
            .filter('*.' + options.failureClass)
            .add($(result).find('*.' + options.failureClass));
          if (errorContainer.length == 0) {
            $(element).data('prevStat', true);
            //$(element).valid();
            errLbl.remove();
            var orignalErrMsg = $(element).data('orignalErrMsg');
            $(element).parent().append(orignalErrMsg);
            errLbl.hide();
            if (options.disableIds) {
              var opts = options.disableIds;
              var idsToDisable = opts.split(' ');
              idsToDisable.forEach(function (item) {
                var id = '#' + item;
                $(id).removeAttr('disabled');
              });
            }
            syncReturnVal = true;
          } else {
            $(element).data('prevStat', false);
            errors = {};
            errors[element.name] = ht.i18n.validate.StandardError;
            validator.invalid[element.name] = true;
            // now update the error label to have the
            // appropriate errors sent back from the server
            var newLabel = $(errorContainer).find('label.error');
            if (options.disableIds) {
              var opts = options.disableIds;
              var idsToDisable = opts.split(' ');
              idsToDisable.forEach(function (item) {
                var id = '#' + item;
                $(id).attr('disabled', 'true');
              });
            }
            if (newLabel.length) {
              $(newLabel).attr('for', element.name);
              // replace old errors with new ones,
              // ensuring that new one end up in the same DOM location
              $(newLabel).insertBefore(errLbl.first());
              errLbl.remove();
            } else {
              errLbl.empty().append(errorContainer);
            }
            validator.showErrors(errors);
            syncReturnVal = false;
          }
          ht.processDeclarations(errorContainer);
        },
        error: function (xhr, textStatus, errorThrown) {
          ht.log(errorThrown);
        },
        complete: function () {
          $(element).removeData('pendingAsync');
        }
      })
    );

    return async ? 'pending' : syncReturnVal;
  } else {
    return 'dependency-mismatch';
  }
};
