ht.ieCompatibilityCheck = function () {
  var agentStr = navigator.userAgent;
  var compatibilityMode = false;
  if (agentStr.indexOf('Trident/7.0') > -1) {
    if (agentStr.indexOf('MSIE 7.0') > -1) {
      compatibilityMode = true; //IE10
    }
  } else if (agentStr.indexOf('Trident/6.0') > -1) {
    if (agentStr.indexOf('MSIE 7.0') > -1) {
      compatibilityMode = true; //IE10
    }
  } else if (agentStr.indexOf('Trident/6.0') > -1) {
    if (agentStr.indexOf('MSIE 7.0') > -1) {
      compatibilityMode = true; //IE9
    }
  } else if (agentStr.indexOf('Trident/4.0') > -1) {
    if (agentStr.indexOf('MSIE 7.0') > -1) {
      compatibilityMode = true; //IE8
    }
  }
  return compatibilityMode;
};
