$(function () {
  // Create top scrollBar to place above our responsive table
  function topScrollBar(uid, table, wrapper) {
    var contentPlaceholder = document.createElement('div');
    contentPlaceholder.className = 'stickyScrollBar_inner';
    contentPlaceholder.style.width = table.scrollWidth + 'px';
    contentPlaceholder.style.height = '1px';
    var scrollBar = document.createElement('div');
    scrollBar.className = 'stickyScrollBar stickyScrollBar_' + uid;
    scrollBar.style.overflowX = 'scroll';
    scrollBar.style.bottom = '0';
    scrollBar.style.margin = 'auto';

    scrollBar.appendChild(contentPlaceholder);

    // Async, use setTimeout to move handler to end of stack
    setTimeout(function () {
      handleScrollBarPosition(table, scrollBar, wrapper);
    }, 1);
    window.addEventListener('scroll', function () {
      handleScrollBarPosition(table, scrollBar, wrapper);
    });
    window.addEventListener('resize', function () {
      handleScrollBarPosition(table, scrollBar, wrapper);
      contentPlaceholder.style.width = table.scrollWidth + 'px';
    });
    return scrollBar;
  }

  function handleScrollBarPosition(table, scrollBar, wrapper) {
    var wrapperWidth = $(wrapper).css('width');
    var viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    // Get table top and bottom relative to top of viewport
    var tableTop = $(table).offset().top - $(window).scrollTop() + 50;
    var tableBottom =
      $(table).offset().top + $(table).outerHeight() - $(window).scrollTop();
    // Make scrollbar sticky to bottom of view port
    if (viewportHeight >= tableTop && viewportHeight <= tableBottom) {
      scrollBar.style.position = 'fixed';
      scrollBar.style.width = wrapperWidth;
      scrollBar.style.display = 'block';
    } else {
      scrollBar.style.display = 'none';
    }
  }

  // Sync top scroll bar with table scroll
  function syncScrollEvent(table, topScrollBarElement) {
    $(topScrollBarElement).scroll(function () {
      $(table).scrollLeft($(topScrollBarElement).scrollLeft());
    });
    $(table).scroll(function () {
      $(topScrollBarElement).scrollLeft($(table).scrollLeft());
    });
  }

  var tableIdentifier = '.scrollingTable';
  function doIt() {
    if (jQuery(tableIdentifier).length !== 0) {
      for (var i = 0; i < jQuery(tableIdentifier).length; i++) {
        tableNumber = i;
        table = jQuery(tableIdentifier)[i];

        // create wrapper container
        var wrapper = document.createElement('div');
        wrapper.className = 'scrollingTableWrapper ' + table.id;
        // insert wrapper before el in the DOM tree
        table.parentNode.insertBefore(wrapper, table);
        // move el into wrapper
        wrapper.appendChild(table);

        var topScrollBarElement = topScrollBar(i, table, wrapper);
        var scrollBar = jQuery('body').find('.stickyScrollBar_' + i);
        var hasScrollBar = scrollBar.length;

        // Add top scroll bar
        if (!hasScrollBar) {
          scrollBar.remove();
          jQuery(wrapper).after(topScrollBarElement);
          // Sync scrolling
          syncScrollEvent(wrapper, topScrollBarElement);
        }
      }
    }
  }
  doIt();
});
