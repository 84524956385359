// set forms to use validation

ht.declare('form.applyValidate', function () {
  var self = $(this);
  // mark labels for required fields
  ht.updateRequiredFields(self);
  var customValidationMethod = self.metadata().customValidationMethod;
  var customPartialValidationMethod =
    self.metadata().customPartialValidationMethod;
  var opts = {
    ignore: '.navButton',
    showErrors: function (errorMap, errorList) {
      $.validator.prototype.defaultShowErrors.apply(this, arguments);
      ht.updateValidateErrors(self);
    }
  };
  // prevent user from submitting a form more than once
  var preventResubmit = self.hasClass('preventResubmit');
  if (preventResubmit) {
    self.data('isSubmitted', false);
  }

  if (self.hasClass('ajaxForm') || self.hasClass('useMetaValidate')) {
    opts.meta = 'validate';
  }
  if (customValidationMethod == null) {
    ht.formValidationState.reset();
    self.submit(function () {
      // first check if there form itself is specifying that certain fields MUST exist
      if (self.hasClass('requireInputExists')) {
        var reqSelectors = self.metadata().requireInputSelectors;
        if ($.isArray(reqSelectors) && reqSelectors.length > 0) {
          var retVal = true;
          for (var i = 0; i < reqSelectors.length; i++) {
            var selectorObj = reqSelectors[i];
            if ($(selectorObj.inputSelector, self).length < 1) {
              ht.formValidationState.set(false);
              $(selectorObj.errorSelector, self).show();
              if (selectorObj.highlightErrorWrapper) {
                $(selectorObj.errorWrapper, self).addClass('error');
              }
              //This is added by JBW for moving to top of screen when error is displayed as per request in ticket
              $('html, body').animate({scrollTop: 0}, 0);
              // Now make sure that if this element enters the document tree, we remove the error
              ht.declare(
                selectorObj.inputSelector,
                function () {
                  var inputName = $(this).prop('name');
                  if (inputName) {
                    $(
                      'label.requireInputExistsError[for~=' + inputName + ']',
                      self
                    ).hide();
                  }
                  if (selectorObj.highlightErrorWrapper) {
                    $(selectorObj.errorWrapper, self).removeClass('error');
                  }
                },
                self
              );
              retVal = false;
            }
          }
          if (!retVal) {
            ht.hideLoading('body');
            return retVal;
          }
          $('label.requireInputExistsError', self).hide();
        }
      }
      var isFormValid = self.validate(opts).form();
      if (customPartialValidationMethod) {
        var partialValidateResult =
          window[customPartialValidationMethod](arguments);
        if (isFormValid && !partialValidateResult) {
          isFormValid = false;
        }
      }

      if (isFormValid && preventResubmit) {
        if (self.data('isSubmitted')) {
          ht.formValidationState.set(false);
          return false;
        }
        self.data('isSubmitted', true);
      }
      if (!isFormValid) {
        ht.hideLoading('body');
      }
      ht.formValidationState.set(isFormValid);
      return isFormValid;
    });
    self.validate(opts);
  } else {
    // custom validation method will get called on form submit
  }
});
