//Commm Prefs admin radio button Default on hide/show
$('body').on('click', '.enableEvent', function () {
  if ($(this).hasClass('require')) {
    $(this)
      .parents('label')
      .siblings('label.default')
      .children('input')
      .removeAttr('checked');
    $(this)
      .parents('label')
      .siblings('label.default')
      .children('input')
      .attr('disabled', 'disabled');
  } else {
    $(this)
      .parents('label')
      .siblings('label.default')
      .children('input')
      .removeAttr('disabled');
  }
});
