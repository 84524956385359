$('body').on(
  'click mousedown',
  "a[href]:not('.close, .modalOpen')",
  function () {
    if ($(this).data('xsessProcessed') == null) {
      $(this).attr('href', ht.setSessionUrl($(this).attr('href')));
      $(this).data('xsessProcessed', true);
    }
  }
);
