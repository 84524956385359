// This will allow the user to target a selector to change its value based on an option selection changing
// from a select item. (ie a hidden input)

$(document).on('change', '.selectValue', function () {
  var self = this;
  var opts = $(self).metadata().selectValueOpts;

  var targetSelector = $(opts.targetSelector);

  if (opts.parse !== null && opts.parse !== undefined) {
    parse = opts.parse;
  } else {
    parse = function (s) {
      return s;
    };
  }

  var selectedValue = $(this.options[this.selectedIndex]).prop('value');

  targetSelector.val(parse(selectedValue));
});
