/***************************************************************************
 * globalAjaxComplete is a function used to examine the results from all
 * ajax calls globally.
 **************************************************************************/
ht.globalAjaxComplete = function () {
  try {
    if (arguments[1].responseText) {
      // Because of these lines, NO AJAX CALLS CAN CONTAIN THE
      // #CentralSessionInit automatically appended by @page ----
      // so ALL AJAX CALLS CANNOT USE THE .PAGE EXTENSION
      // (if fact, currently the response cannot contain the strings
      // 'sessionExpired' or 'forceLogout' at all)
      if (
        arguments[1].responseText.indexOf('sessionExpired') > 1 &&
        arguments[1].responseText.indexOf('forceLogout') > 1
      ) {
        ht.log(
          'Reloading because the AJAX response contains strings "sessionExpired" or "forceLogout". Make sure you are not requesting a URL with the .page extension.'
        );
        ht.forceLogout();
      }
    }
  } catch (e) {
    ht.log(e);
  }
};
