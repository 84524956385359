$(document).on('blur keyup change', '.enableSubmitOnValidate', function () {
  var self = $(this);
  var opts = self.metadata().enableSubmitOnValidateOpts;
  var submitSelector = opts.submitSelector;
  var valid = self
    .validate({errorPlacement: function (error, element) {}})
    .checkForm();
  if (valid) {
    $(submitSelector).prop('disabled', false);
  } else {
    $(submitSelector).prop('disabled', 'disabled');
  }
});
