// dependent selects
//
// TODO: Look into removing this as a duplicate of ht.dependentSelect above
ht.declare('select.dependentSelect', function () {
  var depSel = $(this);
  // Find all of the options, clone them and clear the dependent
  // select (in JQuery 1.4, detach would be better).
  var depOptions = $('option.dependentSelectOption', depSel).clone(true).end();
  depSel.empty();
  // Find the select that controls the dependent select and add a
  // change function
  var controlSelID = depSel.metadata().dependsOn;
  $('#' + controlSelID).on('change', function () {
    var controlSel = $(this);
    // The value for the controlling select
    var selectedValue = $('option:selected', controlSel).val();
    // Remove all options from the dependent select and add only the
    // ones that match the selected value
    depSel.empty();
    depOptions.each(function () {
      var availableFor = $(this).metadata().availableFor;
      if (availableFor == selectedValue) {
        $(this).appendTo(depSel);
      }
    });

    // Set the selected option to be the first item in the list.
    depSel.children('option:first').attr('selected', 'true');
  });
  // force a change initially to get the values correct.
  $('#' + controlSelID).trigger('change');
});
