$('body').on('click', '.elementSwapper', function () {
  var self = $(this);
  var opts = self.data('swapOpts');
  if (opts) {
    ht.elementSwap(opts);
  }

  if (opts.focusTarget) {
    $(opts.focusTarget).focus().select();
  }
});
