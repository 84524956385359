/***************************************************************************
 * our custom validation fucntions
 **************************************************************************/

/***************************************************************************
 * validatePreventDataLoss tests to make sure that if the validating form is
 * being submitted, then the other forms on the page should not have data
 * filled in
 **************************************************************************/
ht.validatePreventDataLoss = function (value, element) {
  // this tests to make sure that if the validating form is being
  // submitted,
  // then the other forms on the page should not have data filled in
  if (
    $(
      'form.dataLossSource input:radio:checked, form.dataLossSource input:checkbox:checked, form.dataLossSource input:text:filled'
    ).length > 0
  ) {
    return false;
  } else {
    return true;
  }
};
