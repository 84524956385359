//makes a larger version of the map in a modal window
$('body').on('click', '#enlargeModalMap', function () {
  loadGoogleMaps(this, function () {
    var opts = $(this).metadata().modalMapOpts;
    var map = new google.maps.Map(
      document.getElementById('modalMap'),
      ht.setMapDefaults()
    );
    ht.modalOpen({modalId: opts.modalId, modalWidth: opts.modalWidth});
    $('#' + opts.infoSection).empty();
    $('#' + opts.resetDOMId)
      .parent('div')
      .show();
    ht.buildMultiMarkers(opts, map);
    return false;
  });
});
