//TODO: Find out where this is used and test performance with ht.declare TJS
// dependent display based on selectors
ht.declare('.dependentDisplay', function () {
  var self = $(this);
  var defaultOpts = {displayIf: '', hideIf: ''};
  var opts = $.extend(defaultOpts, self.metadata().dependentDisplayOpts);
  if (opts.displayIf != '') {
    try {
      // We put this into a livequery so that elements entering the dom can trigger changes
      $(opts.displayIf).livequery(
        opts.displayIf,
        document,
        function () {
          // displayIf entering dom
          self.show();
        },
        function () {
          // displayIf exiting dom
          if ($(opts.displayIf).length > 0) {
            self.show();
          } else {
            self.hide();
          }
        }
      );
      if ($(opts.displayIf).length == 0) {
        self.hide();
      }
    } catch (e) {
      ht.log('Selector failed:' + opts.displayIf);
    }
  }
  if (opts.hideIf != '') {
    try {
      // We put this into a livequery so that elements entering the dom can trigger changes
      $(opts.hideIf).livequery(
        opts.hideIf,
        document,
        function () {
          //  entering dom
          self.hide();
        },
        function () {
          // hideIf exiting dom
          if ($(opts.hideIf).length > 0) {
            self.hide();
          } else {
            self.show();
          }
        }
      );
      if ($(opts.hideIf).length == 0) {
        self.show();
      }
    } catch (e) {
      ht.log('Selector failed:' + opts.hideIf);
    }
  }
});
