//if we get the modal from an ajax call and the modal contains an iframe no need to inject it back in the parent
// blockUI.history causes issues with iframes, so remove the history data
// JJ 4/17/2017 removed iframe check. if isAjax, content of modal will be built correctly. Only removing history
// if there is an iframe makes it so that the procedure search modal isn't removed from history before the interqual
// (iframe-containing) modal is opened, which means the interqualModal class isn't applied and the size of the modal is
// wrong. Removing the iframe check accounts for this. Every time an isAjax modal is opened, it's created from scratch.
ht.removeBlockUiHistory = function (opts) {
  if (opts.isAjax) {
    $(window).removeData('blockUI.history');
  }
};
