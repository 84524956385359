/*TODO: Provider Directory map stuff THIS WILL NEED TO BE REFACTORED TO BE MORE REUSABLE  */
$('body').on('click', '#tabs ul li, .toggleTabs', function () {
  var opts = $(this).metadata().tabOpts;

  if ($(this).hasClass('responsiveTab') && $(this).hasClass('activeState')) {
    // This Applies the Toggles for opening and closing the tabs Like a menu.
    ht.toggleResponsiveTabClass($(this));
  }

  if (opts) {
    $(opts.dataWrapper).children().attr('aria-hidden', 'true').hide();
    $(opts.targetSelector).removeAttr('aria-hidden').show();
    if (opts.excludeDisplay) {
      $(opts.targetSelector).children().not(opts.excludeDisplay).show();
    } else {
      $(opts.targetSelector).children().show();
    }
    //if tab value is passed in
    if (opts.updateActiveTab) {
      ht.toggleActive($(opts.updateActiveTab), opts.activeClass);
    } else {
      ht.toggleActive($(this), opts.activeClass);
    }

    if (opts.renderedOffscreen) {
      $('.renderOffscreen').removeClass('renderOffscreen');
    }
    return false;
  }
});
