ht.elementSwap = function (opts) {
  if (opts.selectorsToShow || opts.selectorsToHide) {
    $(opts.selectorsToHide).each(function (i, elem) {
      var self = $(elem);
      var domRef = self.get(0);
      if (domRef) {
        var tagName = domRef.tagName.toLowerCase();
        if (
          tagName === 'input' &&
          domRef.type !== 'undefined' &&
          domRef.type.toLowerCase() === 'text'
        ) {
          self.val('');
          self.siblings('.error').hide();
        }
        //Ability to default radio or checkbox back to being originally selected
        if (self.data('defaultInputOpts')) {
          var elemOpts = self.data('defaultInputOpts');
          if (elemOpts.targetSelector && elemOpts.defaultProp) {
            $(elemOpts.targetSelector).prop(
              elemOpts.defaultProp,
              elemOpts.defaultProp
            );
          }
        }
      }
      self.prop('disabled', 'disabled').hide();
    });
    $(opts.selectorsToShow).each(function (i, elem) {
      $(elem).prop('disabled', false).show();
    });
  }
};
