//If the div contains the class automodal, it will open as soon as the page loads
ht.declare('div.autoModal', function () {
  $.blockUI.defaults.css = {};
  var messageEl = $(this).addClass('ht-legacy');
  if ($('div.autoModal').length == 1) {
    $.blockUI({message: messageEl});
  } else {
    $.blockUI({
      message:
        'You have multiple autoModals on this page. Only one autoModal permitted per page.',
      css: {
        background: '#fff',
        padding: '15px',
        width: '490px',
        border: '3px solid #AAA'
      }
    });
  }
});
