// conditionally enable or disable form elements based on other values
ht.declare('.dependentDisable', function () {
  var dependentEl = $(this);
  var dependentRules = dependentEl.metadata().dependentRules;
  if (
    dependentRules != undefined &&
    $.isArray(dependentRules) &&
    dependentRules.length > 0
  ) {
    var checkField = function () {
      var enableElement = true;
      var dependentValue = dependentEl.val();

      for (var i = 0; i < dependentRules.length; i++) {
        var rule = dependentRules[i];
        var sel = rule['dependsOn'];
        var enableMatch = rule['enableMatch'];
        var enableValue = rule['enableValue'];
        var enableWithValue = rule['enableWithValue'];
        var enableWhenChecked = rule['enableWhenChecked'];
        var disableMatch = rule['disableMatch'];
        var disableValue = rule['disableValue'];

        if (sel != undefined && $(sel).length > 0) {
          //TODO: rework this, I'm sure Josh has a better solution for toggling states if a value is present
          if (enableWithValue) {
            if ($.trim($(sel).val()) != '') {
              enableElement = true;
            } else {
              enableElement = false;
            }
          } else if (enableWhenChecked) {
            if ($(sel).is(':checked')) {
              enableElement = true;
            } else {
              enableElement = false;
            }
          } else {
            if (disableMatch) {
              enableElement =
                enableElement && $(sel).val().match(disableMatch) == null;
              if (disableValue != undefined && !enableElement) {
                dependentValue = disableValue;
              }
            }
            if (enableMatch) {
              enableElement =
                enableElement && $(sel).val().match(enableMatch) != null;
              if (enableValue != undefined && enableElement) {
                dependentValue = enableValue;
              }
            }
          }
        }
      }

      enableElement
        ? dependentEl.removeAttr('disabled')
        : dependentEl.attr('disabled', 'disabled');
      dependentEl.val(dependentValue);
    };
    checkField();
    for (var i = 0; i < dependentRules.length; i++) {
      var rule = dependentRules[i];
      var sel = rule['dependsOn'];
      $(sel).on('change click', checkField);
    }
  }
});
