$(document).on('keydown', 'input.autocompleteDiv', function () {
  var availableActions = $('input.autoCompleteString').attr('value').split('|');

  var results = {};
  var acArray = new Array();
  var length = availableActions.length;
  for (i = 0; i < length; i++) {
    results[i] = {};

    if (availableActions[i].includes(',,includes_comma,,')) {
      results[i]['label'] = availableActions[i].split(',,includes_comma,,')[0];
      results[i]['value'] = availableActions[i].split(',,includes_comma,,')[1];
    } else {
      results[i]['label'] = availableActions[i].split(',')[0];
      results[i]['value'] = availableActions[i].split(',')[1];
    }

    acArray.push(results[i]);
  }

  $(this).autocomplete({
    appendTo: ht.smartuishell ? '#smartuiLegacyAppendable' : null,
    source: function (request, response) {
      // delegate back to autocomplete, but extract the last term
      response(
        $.ui.autocomplete.filter(acArray, request.term.split(' ').pop())
      );
    },
    focus: function (e) {
      // prevent value inserted on focus
      ht.preventDefault(e);
    },
    select: function (event, ui) {
      var opts = $(this).metadata().autoCompleteOpts;
      if (opts.entryPoint != null) {
        $(this)
          .parent()
          .children(' :last-child:not(a)')
          .before(
            "<span class='deleteContainer ht2-DeletableChip ht2-Layout-DeletableChip'><span>" +
              ui.item.label +
              "</span><input type='hidden' name='searchDesc' value='" +
              ui.item.label +
              "' /><input type='hidden' name='searchId' value='" +
              ui.item.value +
              "' /><a id='" +
              ui.item.value +
              "' href='#' class=\"removeElement ht2-DeletableChipRemoveButton {removeParent:'.deleteContainer', reRun:'true'}\">X</a></span>"
          );
        $(this).val('');

        //This only happens from the Summary page
        $(this).parents('form').submit();

        var showId = 'benefits' + ui.item.value;
        $('#' + showId).addClass('displayed');
        $('.benefitContainer').each(function () {
          if ($(this).attr('id') != showId && !$(this).hasClass('displayed')) {
            $(this).addClass('hide');
          }
          if ($(this).attr('id') == showId && $(this).hasClass('hide')) {
            $(this).removeClass('hide');
          }
        });
        return false;
      } else {
        $(this)
          .parent()
          .children(' :last-child:not(a)')
          .before(
            "<span class='deleteContainer ht2-DeletableChip ht2-Layout-DeletableChip'><span>" +
              ui.item.label +
              "</span><input type='hidden' name='recipient" +
              opts.name +
              "Id' value='" +
              ui.item.value +
              "' /><input type='hidden' name='recipient" +
              opts.name +
              "Name'  value='" +
              ui.item.label +
              "' /><a href='#' class=\"removeElement ht2-DeletableChipRemoveButton {removeParent:'.deleteContainer'}\">X</a></span>"
          );
        $(this).val('');
        return false;
      }
    }
  });
});
