/**
 * Toggle the targeted elements by the selector within the designated container.
 *
 *
 * @param {HTMLElement} container - The container whose form controls get toggled.
 * @param {object} options
 * @param {String} [options.selector = 'input, textarea, button'] - jQuery selector
 *   for the form controls to toggle.
 */
ht.toggleFormControls = function (container, options) {
  var defaults = {
      selector: 'input, textarea, button, checkbox'
    },
    opts = $.extend({}, defaults, options),
    disableProp = opts.enable ? '' : 'disabled';

  $(container)
    .find(opts.selector)
    .each(function (i) {
      if ($(this).prop('disabled')) {
        disableProp = '';
      } else {
        disableProp = 'disabled';
      }
      $(this).prop('disabled', disableProp);
    });
};
