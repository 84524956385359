//tooltip that can have html put into it
ht.declare(
  '.htmlTooltipLabel',
  function () {
    var self = this;
    //set defaults first
    var defaultOpts = {
      tip: '.htmlTooltip',
      placement: 'top',
      trigger: 'manual',
      html: true,
      mouseLeaveDelay: 20
    };

    var md = $(self).metadata().toolTipOpts;
    var opts = $.extend(defaultOpts, md);

    if ($(opts.tip).length > 0) {
      //build popup
      ht.popover(self, opts);
      ht.CentralTopic('elementRemoved').subscribe(function () {
        //remove the popover BEFORE the container is deleted
        $(self).popover('hide');
      });

      //if this is a label, find input and attach the popup to the element as well
      if ($(this).is('label')) {
        if ($('input#' + $(self).attr('for')).length > 0) {
          var elem = $('input#' + $(self).attr('for'));
        } else if ($('input[name=' + $(self).attr('for') + ']').length > 0) {
          var elem = $('input[name=' + $(self).attr('for') + ']');
        }

        //apply to input
        if (elem && elem.length > 0) {
          ht.popover(elem, opts);
        }
      }
    }
  },
  document
);
