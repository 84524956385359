//combine multiple values into one hidden input val
ht.declare('input.siblingDependent', function () {
  var self = $(this);
  var events = $(this).metadata().siblingDependentOpts.selectors;
  var eventsLength = $(this).metadata().siblingDependentOpts.selectors.length;
  for (i = 0; i < eventsLength; i++) {
    $(events[i]).on('change', function () {
      ht.combineSiblingValues(self);
    });
  }
  // combine values even without a change event - Josh
  ht.combineSiblingValues(self);
});
