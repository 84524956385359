/***************************************************************************
 * handleAjaxAddressInit - when the page is loaded fresh, determine what
 * should display in a section
 **************************************************************************/
ht.handleAjaxAddressInit = function (evt) {
  if (ht.handleAnchorNav(evt) != null) {
    evt.value = '';
  }
  var opts = {addToHistory: false};
  // check if this address is tied to a specific element
  if (
    ht.parseUrlQuery(evt.value).ajaxNavId &&
    ht.parseUrlQuery(evt.value).ajaxNavId != $(this).attr('id')
  ) {
    opts.url = ht.translateAjaxUrl('', this); // load a default if one
    // is specified
  } else {
    opts.url = ht.translateAjaxUrl(evt.value, this);
  }
  $(this).trigger('ajaxNavigate', opts);
};
