//TODO: This should be wrapped in an ht.declare() probably
//set focus to input with class of focus... used on login screens
if ($('.focus').length > 0) {
  //there *should* only be once element with a class of focus per page, but in case check for more than 1
  if ($('.focus').length == 1) {
    //set tab index to 1 for an IE hack
    //$(".focus").attr("tabindex",1).focus();
    //removed tabindex set due to an issue where this breaks tab order in other browsers
    //if IE issue resurfaces, see Jereimah
    $('.focus').focus();
  } else {
    //set tab index to 1 for an IE hack, set focus on first element with the class of focus
    //$(".focus:eq(0)").attr("tabindex",1).focus();
    //removed tabindex set due to an issue where this breaks tab order in other browsers
    //if IE issue resurfaces, see Jereimah

    $('.focus:eq(0)').focus();
  }
}
