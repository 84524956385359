//TODO: replace with ht.toggleClasses (possibly removable)
//calendar expand all / close all
$('body').on('click', '.calExpand', function () {
  //show only open slot

  if (this.id == 'calOpen') {
    $('.filled').css('display', 'none');
    $('.avail').css('display', 'block');
    return false;
  } //show only filled slots
  else if (this.id == 'calFill') {
    $('.avail').css('display', 'none');
    $('.filled').css('display', 'block');
    return false;
  } //show all slots
  else {
    $('.filled').css('display', 'block');
    $('.avail').css('display', 'block');
    return false;
  }
});
