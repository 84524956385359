/***************************************************************************
 * translateAjaxUrl - function to take a given url and element and deal with
 * attaching an element id, and set it to the default, if appropriate
 */
ht.translateAjaxUrl = function (url, element) {
  var parts = url.match(ht.urlRegex);
  // get the path, file and query string for this request
  url = (parts[11] || '/') + (parts[14] ? '?' + parts[14] : '');
  if (url == '/' || $.trim(url) == '') {
    // if a slash or blank is
    // provided, load the default
    url = $(element).metadata().ajaxNavigateOpts
      ? $(element).metadata().ajaxNavigateOpts.defaultUrl
      : url;
  }
  var id = $(element).attr('id');
  if (id && id != '') {
    var newQ = $.extend({}, {ajaxNavId: id}, ht.parseUrlQuery(url));
    url = url.split('?')[0] + '?' + $.param(newQ);
  }
  return url;
};
