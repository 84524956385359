/***************************************************************************
 * applyTemplate looks at the metadata of an element and returns a clone of
 * this element, but with changes made as specified in the metadata
 **************************************************************************/
ht.applyTemplate = function (el) {
  var clone = $(el).clone(true, true);
  var mods = clone.data('templateModify') || clone.metadata().modify;

  if (mods && $.isArray(mods)) {
    $.each(mods, function (n, mod) {
      /*
       * s = selector
       * m = method
       * a = arguments
       * */
      var s = mod.s ? clone.find(mod.s) : clone;
      (m = s[mod.m]), (a = mod.a ? mod.a : []);
      if (m && s.length) {
        m.apply(s, a);
      }
    });
  }
  return clone;
};
