ht.setMarkerDefaults = function (opts) {
  var markerBaseOpts = {
    //used to build map perimeter
    bounds: new google.maps.LatLngBounds(),
    //create marker infowindow with a 300px width
    infoWindow: new google.maps.InfoWindow({maxWidth: 300}),
    //default marker shadow
    markerShadow: new google.maps.MarkerImage(
      '/images/ex/maps/markerShadow.png'
    )
  };
  //merge the base opts with the passed in opts
  opts = $.extend({}, markerBaseOpts, opts);
  return opts;
};
