//carousel scroller
$('body').on('click', '.carouselLink', function () {
  //grab the metadata
  var opts = $(this).metadata().carouselOpts;
  //count the elements
  var totalElem = $(opts.target).length;
  //determine direction
  if (opts.forward) {
    $(opts.targetWrapper).animate({}, 'slow', function () {
      //hide first visible carousel item
      $(opts.target + ':eq(0)').hide();
      //move the first carousel item to the end of the carousel item list
      $(opts.target + ':eq(0)').insertAfter(
        opts.target + ':eq(' + (totalElem - 1) + ')'
      );
      //make next carousel item visible
      $(opts.target + ':eq(' + (opts.visibileCount - 1) + ')').fadeIn(1500);
    });
  } else {
    $(opts.targetWrapper).animate({}, 'slow', function () {
      //hide last visible carousel item
      $(opts.target + ':eq(' + (opts.visibileCount - 1) + ')').hide();
      //move the last carousel item to the beginning of the carousel item list
      $(opts.target + ':eq(' + (totalElem - 1) + ')').insertBefore(
        opts.target + ':eq(0)'
      );
      //make the first carousel item visible
      $(opts.target + ':eq(0)').fadeIn(1500);
    });
  }
});
