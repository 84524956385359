/***************************************************************************
 * twist will turn a definition list into an expandable/collapsable element
 * with the dt receptive to clicks to show/hide the dd
 **************************************************************************/
ht.twist = function (e) {
  // If the originating element is particular types, let the event bubble normally
  var target = $(e.target);
  if (target.is('a:not(.twisty),input,button')) {
    return true;
  } else {
    // the twist will happen for both a click and when the element is
    // focused and the enter key is hit
    if (
      e.type == 'click' ||
      (e.type == 'keypress' && (e.keyCode ? e.keyCode : e.which) == 13)
    ) {
      $(this).toggleClass('twistClosed').next('dd').toggleClass('twistClosed');
      return false;
    }
  }
};
