// A checkbox group can have a soleSurvivor value checked OR any other values. (SM-3539 NSC requirement)
ht.declare('input.soleSurvivor', function () {
  var groupEl = $(this);
  var opts = groupEl.metadata().soleSurvivorOpts;
  var respondToChange = function (el) {
    var val = el.val();
    var parent = el.closest(opts.wrapperClass);
    if (val == opts.soleSurvivor) {
      parent.find(opts.targetClass).each(function () {
        var curVal = $(this).val();
        if (curVal != opts.soleSurvivor) {
          $(this).prop('checked', false);
        }
      });
    } else {
      parent.find(opts.targetClass).each(function () {
        var curVal = $(this).val();
        if (curVal == opts.soleSurvivor) {
          $(this).prop('checked', false);
        }
      });
    }
  };
  if (groupEl != undefined) {
    $(groupEl).on('change', function () {
      respondToChange(groupEl);
    });
  }
});
