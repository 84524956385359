ht.declare('div.managedNav', function () {
  var self = $(this);
  self.on('ajaxNavigate', ht.ajaxNavigate);
  if ($.address) {
    $.address.init(function (evt) {
      ht.handleAjaxAddressInit.apply(self, [evt]);
    });
    $.address.change(function (evt) {
      ht.handleAjaxAddressChange.apply(self, [evt]);
    });
  } else {
    ht.log('default address is undefined');
  }
});
