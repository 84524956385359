ht.declare('input.jsdate', function () {
  /**********IMPORTANT***********************************************
   *review the quick reference at http://keith-wood.name/datepick.html
   *for a complete list of options you can pass via metadata
   *********************************************************************/
  //set defaults
  window.htc = window.htc || {};
  window.htc.global = window.htc.global || {};
  window.htc.global.messages = window.htc.global.messages || {};
  window.i18nLabels = window.i18nLabels || {};
  const calendar = window.htc.global.messages.hasOwnProperty(
    'common.component.dateRangeInput.calendarimg'
  )
    ? window.htc.global.messages['common.component.dateRangeInput.calendarimg']
    : window.i18nLabels['common.component.dateRangeInput.calendarimg'];
  const flamingoCalendarTrigger = `<div />`;
  const opts = {
    showTrigger: htc?.screenIsFlamingoWhitelisted
      ? flamingoCalendarTrigger
      : calendar,
    showAnim: 'fadeIn',
    duration: 300,
    // needs blockMsg class so it can be used within Block UI modals
    // see http://bit.ly/1A1Woj7
    pickerClass: 'blockMsg',
    dateFormat: 'mm/dd/yyyy',
    // trigger change on element datepicker is attached to (CSM1-30061)
    onSelect: function () {
      $(this).change();
    }
  };
  //get metadata
  const md = $(this).data('datepickOpts') || $(this).metadata().datepickOpts;
  // if a pickerClass option was passed, we can't let it overwrite the blockMsg default
  if (md && md.pickerClass) {
    md.pickerClass = md.pickerClass + ' blockMsg';
  }
  //check for metadata
  if (md) {
    //overwrite default opts with metadata
    opts = $.extend(opts, md);
    //onselect submit
    if (opts.dateSubmit) {
      $.extend(opts, {
        onSelect: function () {
          $(opts.dateSubmit).trigger('submit');
        }
      });
    }
  }

  //call date pick
  $(this).datepick(opts);
});
