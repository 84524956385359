/***************************************************************************
 * getAjaxNavigateOpts - function that returns ajax opts object which
 * handles all of the details of submitting an ajax request to a remote
 * service and optionally inserting the results into the specified element.
 * It handles errors, filters the results and more. If metadata is present
 * in the specified element, then this is merged into the opts.
 * Additionally, an optional argument can specify more options that will
 * override everything else.
 **************************************************************************/
ht.getAjaxNavigateOpts = function (el, opts) {
  var self = $(el);
  var defaultAjaxOpts = {
    addToHistory: true,
    autoFocusOnLoad: false,
    defaultUrl: false,
    errorSelector: false,
    form: self.get(0).tagName.toUpperCase() == 'FORM' ? self : false,
    pendingSelector: false,
    resultSelector: false,
    printContentsOnLoad: false,
    clearLoaderOnComplete: false,
    showLoadingAnimation: true,
    showOnSuccess: true,
    successSelector: self,
    title: false,
    url: self.attr('href'),
    urlAlias: false,
    closeModalOnSuccess: false,
    disableOnLoadPositionScroll: false,
    beforeSend: function (xhr) {
      ht.setSessionHeader.apply(this, arguments);
      // housekeeping..
      if (self.data('pendingAsync')) {
        self.data('pendingAsync').abort();
      }
      self.data('pendingAsync', xhr);
      // show and hide as appropriate
      if (this.successSelector) {
        var success = $(this.successSelector);
        if (this.pendingSelector) {
          success.hide();
        } else if (this.showLoadingAnimation && this.closeModalOnSuccess) {
          ht.showLoading('body');
        } else if (this.showLoadingAnimation) {
          ht.showLoading(success);
        }
        // add to history if appropriate
        var historyUrl = this.urlAlias || this.url;
        // insert this element id into the query string if
        // appropriate
        var id = success.attr('id');
        if (id && id != '') {
          var newQ = $.extend({ajaxNavId: id}, ht.parseUrlQuery(historyUrl));
          historyUrl = historyUrl.split('?')[0] + '?' + $.param(newQ);
        }
        // even if this isn't added to history, set the value
        // for what is loaded
        success.data('currentAjaxUrl', historyUrl);
      }
      if (this.pendingSelector) {
        $(this.pendingSelector).parents().show('slow').end().show();
      }
      if (this.errorSelector) {
        $(this.errorSelector).hide();
      }
      return true;
    },
    beforeSubmit: function () {
      // we have to make sure that validation runs first
      var customValidationMethod = self.metadata().customValidationMethod;
      if ($(this.form).hasClass('applyValidate')) {
        if (customValidationMethod != null) {
          // run custom validation to ensure it passes
          var globalThis = (function () {
            return this;
          })();
          if ($.isFunction(globalThis[customValidationMethod])) {
            return globalThis[customValidationMethod]();
          } else {
            //if custom validation is broken, fall back to normal validation
            return $(this.form).validate().form();
          }
        } else {
          return $(this.form).validate().form();
        }
      } else {
        return true;
      }
    },
    success: function (result) {
      // Session Management Support
      if (ht.CentralExchange) {
        ht.CentralTopic('RenewSession').publish(false);
      }
      //close the modal
      if (this.closeModalOnSuccess) {
        $('.modalClose').trigger('click');
      }
      if (this.successSelector) {
        var returnData = opts.evaluateScript
          ? result
          : result.replace(/<script(.|\s)*?\/script>/g, '');
        var toInsert = $('<div />').append(returnData);
        if (this.resultSelector) {
          toInsert = toInsert.find(this.resultSelector);
        }
        if (this.replaceSelector) {
          $(this.successSelector)
            .replaceWith(toInsert)
            .trigger('AjaxElementLoad', {
              disableOnLoadPositionScroll: opts.disableOnLoadPositionScroll
            });
        } else {
          $(this.successSelector).html(toInsert).trigger('AjaxElementLoad', {
            disableOnLoadPositionScroll: opts.disableOnLoadPositionScroll
          });
        }
        if (ht.CentralExchange && this.updateTextSelector) {
          var textOpts = {};
          if (this.updateTextType == 'count') {
            textOpts = {
              type: this.updateTextType,
              textToUpdate: $(this.updateTextSelector),
              titleToUpdate: $(this.updateTitleSelector),
              count: $(this.countSelector).length
            };
            ht.CentralTopic('updateCount').publish(textOpts);
          } else {
            textOpts = {
              type: this.updateTextType,
              textToUpdate: $(returnData).find(this.updateTextSelector)
            };
            ht.CentralTopic('updateText').publish(textOpts);
          }
        }
      }
      //TODO: verify this is being used for ref/auth TJS
      ht.CentralTopic('ajaxComplete').publish(toInsert);
      if (this.pendingSelector) {
        $(this.pendingSelector).hide();
      }
      if (this.errorSelector) {
        $(this.errorSelector).hide();
      }
      if (this.successSelector && this.showOnSuccess) {
        $(this.successSelector).parents().addBack().show();
      }
      if (opts.ajaxSuccess != null && opts.ajaxSuccess !== undefined) {
        switch (typeof opts.ajaxSuccess) {
          case 'string':
            func = opts.ajaxSuccess + '()';
            eval(func);
            break;
          case 'object':
            opts.ajaxSuccess();
            break;
          case 'function':
            opts.ajaxSuccess();
            break;
          default:
            ht.log(
              "Could not identify given parameter for 'ajaxSuccess': " +
                opts.ajaxSuccess.toString()
            );
            ht.log(opts.ajaxSuccess);
        }
      }
      if (this.form) {
        $(this.form).trigger('ajaxFormSuccess');
      }
      if (this.autoFocusOnLoad && this.successSelector) {
        $(this.successSelector)
          .find('input,select,textarea')
          .filter(':visible:first')
          .focus();
      }
      if (this.printContentsOnLoad) {
        var loadingAssets = $('.printWhenLoaded');
        var assetCounter = loadingAssets.length;
        loadingAssets.on(
          'load',
          function () {
            if (--assetCounter === 0) {
              // decrement on load, until all are loaded
              window.print();
              $(this.successSelector).empty();
            }
          }.bind(this)
        );
      }
      if (this.clearLoaderOnComplete) {
        $('#htcLoadingDiv').remove();
      }
    },
    error: function (xhr, textStatus, errorThrown) {
      ht.log('ajax opts error: ', xhr, textStatus, errorThrown);
      // show and hide as appropriate
      if (this.errorSelector) {
        if (this.successSelector) {
          $(this.successSelector).hide();
        }
        if (this.pendingSelector) {
          $(this.pendingSelector).hide();
        }
        $(this.errorSelector).parents().addBack().show();
      }
    },
    complete: function (XMLHttpRequest, textStatus) {
      self.removeData('pendingAsync');
      var respText = XMLHttpRequest.responseText
        ? XMLHttpRequest.responseText
        : '';
      ht.localAjaxComplete.apply(
        $(this.successSelector).length > 0
          ? $(this.successSelector)
          : self.parent(),
        [respText, textStatus, XMLHttpRequest]
      );
      if (ht.viewSizes) {
        // if this is defined, then call it so that the page can resize if needed
        ht.viewSizes();
      }
      var success = $(this.successSelector);
      var historyUrl = success.data('currentAjaxUrl');

      // accomodate htc custom reponse headers so that :redirects
      // from spring behave correctly
      var htcCustomReponseHeader = XMLHttpRequest.getResponseHeader(
        'X_Custom_History_Url'
      );
      if (
        $.address != undefined &&
        htcCustomReponseHeader != null &&
        htcCustomReponseHeader != ''
      ) {
        success.data('currentAjaxUrl', htcCustomReponseHeader);
        $.address.value(htcCustomReponseHeader);
      } else {
        if (this.addToHistory && $.address != undefined) {
          // only change the address if
          // this is set to add to history
          $.address.value(historyUrl);
        }
      }
      //used to remove wrapping element
      //For example: if you have an anchor that makes an ajax call with a nested span
      //after the ajax call completes you can remove the anchor so the call isnt repeated with every click
      if (opts.unwrapSelector) {
        $(opts.unwrapSelector).unwrap();
      }
    }
  };
  opts = ht.getAjaxBaseOpts(
    $.extend({}, defaultAjaxOpts, self.metadata().ajaxNavigateOpts, opts)
  );
  return opts;
};
