//TODO: this should be rewritten with observer methodology ht.CentralTopic("popupDataExchange").publish
$('body').on('click', 'a.popupProducer, button.popupProducer', function () {
  var opts = $(this).metadata().popupProducerOpts;
  if (opts != null && opts.selector != null) {
    var evt = jQuery.Event('popupResult');
    evt.html = $(opts.selector).children().clone(true);
    $(document).trigger(evt);
    ht.CentralTopic('popupResult').publish(evt.html);
  }
  // Hide specified element, e.g., hide the 'select provider' link since only a single provider can be selected
  if (opts && opts.hideElement) {
    $(opts.hideElement).hide();
  }
  $(this).trigger('modalClose');
  return false;
});
