// IMPORTANT: This event code needs to be run after many other events
// are put into the queue so that
// if other actions need to be taken on elements that will be removed,
// they can run.
$('body').on(
  'click',
  '.js-removeElement, a.removeElement, button.removeElement, input[type=button].removeElement',
  function (e) {
    var self = $(this);
    // options can be passed as a data-remove-opts attribute or classname metadata
    var opts = $.extend({}, self.metadata(), self.data('removeOpts'));
    //you have to tell the DOM you are removing the element before you remove it for the subscribe to work properly
    ht.CentralTopic('elementRemoved').publish(self);
    if (opts.removeSelector) {
      $(opts.removeSelector).remove();
    }
    if (opts.removeFind) {
      self.find(opts.removeFind).remove();
    }
    if (opts.removeParent) {
      self.parent(opts.removeParent).remove();
    }
    if (opts.removeParents) {
      self.parents(opts.removeParents).remove();
    }
    if (opts.removeSelf) {
      self.remove();
    }

    if (opts.elementSwap) {
      if ($(opts.elementSwap.countSelector).length === 0) {
        ht.elementSwap(opts.elementSwap);
      }
    }

    //TODO: this does not belong here, remove this and us ht.toggle
    if (opts.toggleRemove && $(opts.toggleRemove).length == 1) {
      $(opts.toggleRemove).hide();
    }
    // show an element that might have previously been hidden by another function
    // e.g., popupProducer.js
    if (opts.showElement) {
      $(opts.showElement).show();
    }

    //Adding this to handle benefits show/hide
    //TODO: use ht.CentralTopic("elementRemoved").subscribe() and ht.toggleClass; to add this logic
    if (opts.reRun) {
      /*this is VERY specific to benefits.  Need to determine if this needs to be made reusable*/
      if ($('input[name=searchId]').length) {
        $('#benefits' + $(this).attr('id')).addClass('hide');
        $('#benefits' + $(this).attr('id')).removeClass('displayed');
      } else {
        $('.benefitContainer').removeClass('hide');
        $('.benefitContainer').removeClass('displayed');
      }
    }
    //END - benefits code
    return false;
  }
);
