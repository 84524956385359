// open json modals
$('body').on('click', '.jsonModalOpen', function (e) {
  e.preventDefault();
  var opts = $(this).data('jsonModalOpts');
  var href = $(this).attr('href');
  if (href) {
    opts.href = href;
  }
  ht.jsonModalOpen(opts);
});
