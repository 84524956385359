//toggleClass
//toggleElement- css seltor(s) for the element(s) to toggle
//toggleClasses- are the two classes to be toggled seperated by a space as in jQuery
//hardToggle- used if you want to explicity add/remove classes
ht.toggleClass = function (opts) {
  //font awesome hack to not include font awesome styles we need to pass a "." via meta data
  var classesToToggle = opts.toggleClasses.replace(/\./g, '');
  //splits the elements into an array
  var toggleClassArray = classesToToggle.split(' ');
  //there should only be two elements in the array, afterall it is a toggle
  if (toggleClassArray.length == 2) {
    //for toggling all elements with a class; for example expand/collapse all
    /*toggleClassArray[0] = toggleClassArray[0];
        toggleClassArray[1] = toggleClassArray[1].replace(/\./g,'');*/
    if (opts.hardToggle) {
      //NOTE: toggleClassArray[0] MUST be the class you want to target/remove; so set it first in the metadata
      if ($(opts.toggleElement).hasClass(toggleClassArray[0])) {
        $(opts.toggleElement + '.' + toggleClassArray[0])
          .removeClass(toggleClassArray[0])
          .addClass(toggleClassArray[1]);
      }
      //Normal toggle
    } else {
      //checks to see if the init class is the first element in the array
      if ($(opts.toggleElement).hasClass(toggleClassArray[0])) {
        //finds elements with that classes and toggles the classes
        $(opts.toggleElement + '.' + toggleClassArray[0]).toggleClass(
          classesToToggle
        );
      } else if ($(opts.toggleElement).hasClass(toggleClassArray[1])) {
        $(opts.toggleElement + '.' + toggleClassArray[1]).toggleClass(
          classesToToggle
        );
      }
    }
  }
};
