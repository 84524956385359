/* condition search js*/
$('.flip').on('click', function () {
  var opts = $(this).metadata().gender;
  var modelFront = '#body-' + opts + '-front';
  var modelBack = '#body-' + opts + '-back';
  if ($(modelFront).is(':visible')) {
    $(modelFront).fadeOut(150);
    $(modelBack).fadeIn(150);
    $(this).html('Front');
    view = 'front';
  } else {
    $(modelFront).fadeIn(150);
    $(modelBack).fadeOut(150);
    $(this).html('Back');
    view = 'back';
  }
  return false;
});
