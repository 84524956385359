/*
  an a with a class of 'appendLocalIsoDateTime' and has a data-date attribute set as an iso date
    will have its href get the date appended
  Freemarker example:
  Use iso_m_u built-in in order to get iso form
  <span
    class="appendLocalIsoDateTime"
    data-date="${asOfDate?string.iso_m_u}"
    data-format="MM/DD/YYYY">
  </span>
*/
ht.declare('a.appendLocalIsoDateTime', function () {
  var dateString = $(this).data().date;
  var dateFormat = $(this).data().format;
  var localizedDateString = moment(dateString).format(dateFormat);
  var href = $(this).attr('href');
  $(this).attr('href', href + '&asofsl=' + localizedDateString);
  return;
});
