// add event variables to tinyMCE
$('body').on('click', 'a.ftlvar', function () {
  //get metadata for value to be inserted
  var opts = $(this).metadata().ftlvarOpts;
  //if textAreaId was passed in the metaData set the focus to that textarea (Used for multiple textareas on same page)
  if (opts.id) {
    tinymce.get(opts.id).focus();
  }
  // insert variable in the tinyMCE textarea
  tinymce.activeEditor.execCommand(
    'mceInsertContent',
    false,
    '${ ' + opts.insertValue + '}'
  );
  return false;
});
