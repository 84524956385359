$('body').on('click', '.submitExternalForm', function (e) {
  e.preventDefault();
  var opts = $(this).metadata().submitExternalFormOpts;

  if (opts.formSelector) {
    if (opts.formAction) {
      $(opts.formSelector).attr('action', opts.formAction);
    }
    $(opts.formSelector).trigger('submit');
    $(this).trigger('modalClose');
  }
});
