//TODO: rename
$('body').on('click', 'a.formLink2', function () {
  if ($(this).metadata().condition == 'conditions') {
    ht.viewConditions($(this));
  }
  if ($(this).metadata().condition == 'specialties') {
    ht.viewSpecialities($(this));
  }
  var newParams = ht.parseUrlQuery(
    'index?' + $(this).parents('form').formSerialize()
  );
  var currentUrl = ht.parseUrlQuery($(this).attr('href'));
  var newQ = $.extend({}, currentUrl, newParams);
  var urlNew = $(this).attr('href').split('?')[0] + '?' + $.param(newQ);
  $(this).prop('href', urlNew);
  return true;
});
