if (ht.CentralExchange) {
  ht.declare('.updateText', function () {
    var self = this;
    var textOpts = $(self).metadata().updateTextOpts;

    ht.CentralTopic('updateText').subscribe(function (opts) {
      if (textOpts && textOpts.type == opts.type) {
        if ($(self).is('input')) {
          $(self).val($(opts.textToUpdate).val());
        } else {
          $(self).text($(opts.textToUpdate).text());
        }
      }
    });
  });
}
