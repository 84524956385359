ht.homeMarkerCheck = function (map, bounds, folderId) {
  loadGoogleMaps(this, function () {
    //grab lat and long of home address
    var lat = $('#newLat').val();
    var long = $('#newLong').val();

    if (lat != '' && long != '') {
      var markerBaseOpts = ht.setMarkerDefaults();
      var markerInfo = ht.setMarkerInfo(
        {lat: lat, long: long, folderId: folderId},
        'Home'
      );
      //build home marker
      var marker = new google.maps.Marker({
        map: map,
        position: markerInfo.latlng,
        icon: markerInfo.markerImg,
        shadow: markerBaseOpts.markerShadow,
        title: 'Home'
      });
      //extend the bounds to show home marker
      bounds.extend(markerInfo.latlng);
    }
  });
};
