/* clickable table rows... */
$('body').on('click', 'tr.clickable', function (e) {
  if (
    $(e.target).is('input') ||
    $(e.target).is('a') ||
    $(e.target).is('button') ||
    $(e.target).is('select')
  ) {
    return;
  } else {
    var opts = $(this).metadata().clickableOpts;
    var toClick = $(this).find(opts.target)[0];
    if (toClick) {
      toClick.click();
    }
  }
});
