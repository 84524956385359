/**
 * Calculate BMI.
 *
 * @param {Number} height - For non-metric, height in inches or as an array [feet, inches].
 *   For metric, height in meters or centimeters.
 * @param {Number} weight - In pounds of kilograms.
 * @returns {Number} - Calculated Body Mass Index measurement or 0 if something went wrong.
 */
ht.getBMI = function (height, weight, options) {
  var defaults = {
      metric: false,
      decimals: 0
    },
    opts = $.extend({}, defaults, options),
    bmi;
  // Convert [feet, inches] array for height to inches value.
  if (height.length === 2) {
    if (opts.metric) {
      throw new Error(
        'A getBMI height array must be [feet, inches], cannot be metric.'
      );
    }
    height = parseInt(height[0], 10) * 12 + parseInt(height[1], 10);
  }
  // If using metric and height value is greater than 10,
  // we can safely assume that centimeters were entered.
  if (opts.metric && height > 10) {
    height = height / 100;
  }
  bmi = weight / (height * height);
  if (!opts.metric) {
    bmi = bmi * 703;
  }
  // If something went wrong, return 0.
  if (isNaN(bmi)) {
    bmi = 0;
  }
  return bmi.toFixed(opts.decimals);
};
