if (ht.CentralExchange) {
  $('body').on(
    'click',
    "a[href*='logout=1'][href*='/app/index.page']",
    function () {
      ht.CentralTopic('HaltSessionHandling', 'memory').publish('halt');
      return true;
    }
  );
}
