if (ht.smartuishell) {
  /**
   * Make shell wide enough to accommodate
   * wide legacy tables
   */
  ht.syncLayoutDimensions = function () {
    // Don't do this stuff for Providence
    //FIXME: Don't put payor specific logic in js
    if (
      (htc.global.clientState.payer == '1002' &&
        htc.global.clientState.siteSpecial == 'MEDICARE') ||
      htc.global.clientState.payer == '1037' ||
      htc.global.clientState.payer == '1060' ||
      htc.global.clientState.payer == '1017' ||
      htc.global.clientState.payer == '1054'
    ) {
      return;
    }

    function getLeftNavWidth() {
      var $leftNav = $('.ht-Shell-nav--left').first();
      if (!$leftNav) return 0;
      return $leftNav.outerWidth(true);
    }

    var leftNavWidth = getLeftNavWidth();
    var $bodyWrapper = $('#Shell-wrapper');
    var $containersWithCentering = $('.syncLayoutDimensions___container');
    var $shell = $('#Shell-container');
    var $syncContainer = $containersWithCentering.length
      ? $containersWithCentering
      : $shell;
    var legacyFa = $('#legacyFunctionalArea').length ? true : false;
    var maxWidth = parseInt($('.ht-Shell-main').css('max-width'));

    // reset before calculating
    $('.syncLayoutDimensions___header').css({
      'max-width': ''
    });
    $('.syncLayoutDimensions___footer').css({
      'max-width': ''
    });
    $syncContainer.css({
      'max-width': ''
    });

    if (legacyFa) {
      // Change display to enable it to expand to full width
      $bodyWrapper.css('display', 'table');
      var tbls = $('#legacyFunctionalArea').find('tbody');
      if (tbls.length) {
        var largest = 0;
        for (var i = 0; i < tbls.length; i++) {
          var tblSize = $(tbls[i]).width();
          if (tblSize > largest) {
            largest = tblSize;
          }
        }
      }

      var largestAvailableSize = largest + leftNavWidth;
      var extraShellPadding =
        $syncContainer.outerWidth() - $syncContainer.width();
      largestAvailableSize += extraShellPadding;

      //if the max width is set on ht-Shell-main and it is smaller than the largest table do nothing
      if (Number.isInteger(maxWidth) && maxWidth < largestAvailableSize) {
        if ($bodyWrapper.hasClass('ht-Shell-wrapper--topNav')) {
          $bodyWrapper.css('display', 'block');
        } else {
          $bodyWrapper.css('display', 'flex');
        }
        return;
      }

      if (largestAvailableSize > $syncContainer.width()) {
        var computedWidth = largestAvailableSize + 60;
        $syncContainer.css({
          'max-width': computedWidth
        });
        //CSM1-11573: I added these 2 if checks to expand the header, and footer for MPHC. I had to modify their pageLayout file in order to get the
        //page to expand correctly when the tables were really wide.
        if ($('.syncLayoutDimensions___header').width() < computedWidth) {
          $('.syncLayoutDimensions___header').css({
            'max-width': computedWidth
          });
        }
        if ($('.syncLayoutDimensions___footer').width() < computedWidth) {
          $('.syncLayoutDimensions___footer').css({
            'max-width': computedWidth
          });
        }
      }
      if ($bodyWrapper.hasClass('ht-Shell-wrapper--topNav')) {
        $bodyWrapper.css('display', 'block');
      } else {
        $bodyWrapper.css('display', 'flex');
      }
    }
  };
}
