// Create Validation getter and setter methods for client intigrations
// Currently used in applyValidation.js

ht.formValidationState = {
  _value: 'notSubmitted',
  get: function () {
    return this._value;
  },
  set: function (isValid) {
    if (isValid) {
      this._value = 'isValid';
    } else {
      this._value = 'invalid';
    }
  },
  reset: function () {
    this._value = 'notSubmitted';
  }
};
