//This function takes a targeted <select> element and based on the <option> selected it will
//hide or display <option>s(most likely in a different related <select>).
//
//The sourceValue comes from a field in the selected <option> of the targeted <select> saved to it's 'data-filter'
//  attribute
//The opts reads in two arrays. The length of these arrays must be same length and the order of the elements matters:
//  targetSelector- List of <option> ids to hide or show
//  enableIfValueContains- List of strings that map to the <option>s. If this string is contained in the
//    sourceValue string then the corresponding option is displayed.
var toggleOptionDisable = function (target) {
  var opts =
    $(target).data('toggleOptionDisable') ||
    $(target).metadata().toggleOptionDisableOpts;
  var sourceValue = $(target).children('option:selected').attr('data-filter');

  //Makes sure that both the target option list has values and the ids list is the same length
  if (
    opts.enableIfValueContains.length > 0 &&
    opts.enableIfValueContains.length == opts.targetSelector.length
  ) {
    var selectedModified = false;
    //loop over the enableIfValueContains array to see if the current option target's value is
    //in included in the source value
    for (var i = 0; i < opts.enableIfValueContains.length; i++) {
      if (sourceValue.includes(opts.enableIfValueContains[i])) {
        //Unhide the current option and select it if it's the first unhidden
        $(opts.targetSelector[i]).prop('hidden', false);
        if (!selectedModified) {
          $(opts.targetSelector[i]).prop('selected', true);
          selectedModified = true;
        }
      } else {
        //Hide the current option
        $(opts.targetSelector[i]).prop('hidden', true);
        $(opts.targetSelector[i]).prop('selected', false);
      }
    }
  }
};

var loadOptionDisable = function (e) {
  var target = $('#submitReportSchedule').find('.toggleOptionDisable');
  if (target.length > 0) {
    toggleOptionDisable($('.toggleOptionDisable'));
  }
};

var changeOptionDisable = function (e) {
  e.preventDefault();
  toggleOptionDisable(e.target);
};
$('#submitReportSchedule').on(
  'change',
  '.toggleOptionDisable',
  changeOptionDisable
);
$(document).ready(loadOptionDisable);
