//calls function to open generic modal window using block ui plugin jquery_block_ui.js
$('body').on('click', '.modalOpen', function (event) {
  event.preventDefault();
  var self = $(this);
  //remove any remenants of twitter bootstrap tooltip. It can get on mobile and tablet
  $('.tooltip').remove();
  if ($('.tb-tooltip').length) {
    //hide tb-tooltips when modalOpen is called
    $('.tb-tooltip').tooltip('hide');
  }

  var opts = $.extend(true, self.metadata(), self.metadata().modalOpts);
  if (self.attr('href')) {
    opts.href = self.attr('href');
  }
  ht.modalOpen(opts);

  //TODO: this should be rewritten with observer methodology ht.CentralTopic("popupDataExchange").subscribe
  if (self.hasClass('popupConsumer')) {
    ht.popupConsumer(self);
  }
  return false;
});
