// A field can be required based upon other fields
ht.declare(
  'input.dependentRequired, select.dependentRequired, textarea.dependentRequired',
  function () {
    var dependentEl = $(this);
    var requiredRule = dependentEl.metadata().requiredRule;
    var updateRequired = function (el, rule) {
      var isRequired = false;

      if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function (obj, start) {
          for (var i = start || 0, j = this.length; i < j; i++) {
            if (this[i] === obj) {
              return i;
            }
          }
          return -1;
        };
      }
      //dependsNotValue added to force dependency if the value in the dependsOn is NOT a specific value
      if (requiredRule.dependsNotValue != undefined) {
        var dependsNotValues = requiredRule.dependsNotValue.split(',');
        $(rule.dependsOn)
          .filter(
            ':checked,:selected,:not(input[type=checkbox],input[type=radio])'
          )
          .each(function () {
            var dependsOnValue = $(this).val();
            if (dependsOnValue instanceof Array) {
              //added check here to handle multi-select. Have not seen need to add to other implementations yet.
              var arrayLength = dependsOnValue.length;
              for (var i = 0; i < arrayLength; i++) {
                isRequired =
                  isRequired ||
                  dependsNotValues.indexOf(dependsOnValue[i]) == -1;
              }
            } else if (dependsOnValue != '') {
              isRequired =
                isRequired || dependsNotValues.indexOf(dependsOnValue) == -1;
            }
          });
      } else if (requiredRule.dependsAnyValue != undefined) {
        $(rule.dependsOn)
          .filter(
            ':checked,:selected,:not(input[type=checkbox],input[type=radio])'
          )
          .each(function () {
            if ($(this).val() != null) {
              //allow no selection
              isRequired = isRequired || $(this).val() != '';
            }
          });
      } else {
        var dependsValues = requiredRule.dependsValue.split(',');
        $(rule.dependsOn)
          .filter(
            ':checked,:selected,:not(input[type=checkbox],input[type=radio])'
          )
          .each(function () {
            isRequired =
              isRequired || dependsValues.indexOf($(this).val()) > -1;
          });
      }
      //TODO: add reomove attribute
      var elementId = $(el).attr('id');
      if (isRequired) {
        $(el).addClass('required');
        $(el).attr('aria-required', 'true');
        $("label[for='" + elementId + "']")
          .not('label.error, label:has(em.required)')
          .append(
            "&nbsp;<em class='required ht1-InputRequiredAsterisk ht2-InputRequiredAsterisk'>*</em>"
          );
      } else {
        $(el).removeClass('required');
        $(el).removeClass('error');
        $("label[for='" + elementId + "']")
          .not('label.error')
          .children('em')
          .remove();
      }
      //remove for issues being cause in form, leaving commented out for now
      //ht.updateRequiredFields();
    };
    if (
      requiredRule != undefined &&
      requiredRule.dependsOn != undefined &&
      (requiredRule.dependsValue != undefined ||
        requiredRule.dependsNotValue != undefined ||
        requiredRule.dependsAnyValue != undefined)
    ) {
      updateRequired(dependentEl, requiredRule);
      $(requiredRule.dependsOn).on('change', function () {
        updateRequired(dependentEl, requiredRule);
      });
    }
  }
);
