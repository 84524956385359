// For CPS there is a category selection area with cards as choices. This code will only allow 1 card to be choosen
$('.cpsCategoryCheckbox').on('click', function () {
  if (this.checked) {
    $('.cpsCategoryCheckbox').prop('checked', false);
    this.checked = true;
  } else {
    $('.cpsCategoryCheckbox').mouseover(function () {
      $(this).css('background-color', '#ffffff');
    });
  }
});
