//create a promise for a callback once the map has loaded
var googleMapsLoaded = jQuery.Deferred();
//sets default map params
ht.setMapDefaults = function (opts) {
  var defaultMapOpts = {
    scaleControl: true,
    zoom: 12,
    //default map in the atlantic ocean
    center: new google.maps.LatLng(39.820913, -71.861572),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };

  opts = $.extend({}, defaultMapOpts, opts);
  return opts;
};
