if (ht.smartuishell) {
  //THIS must be onload otherwise jQuery runs before css is loaded
  $(window).on('load', ht.syncLayoutDimensions);
  $(function () {
    $(document).on('ajaxComplete', ht.syncLayoutDimensions);
    $(document).on('navChangeEvent', ht.syncLayoutDimensions);

    // Recalculate on window resize with deboucing
    var timeout = false;
    var delay = 250;
    $(window).on('resize', function () {
      // clear the timeout
      clearTimeout(timeout);
      // start timing for event "completion"
      timeout = setTimeout(ht.syncLayoutDimensions, delay);
    });
    $('img').on('load', ht.syncLayoutDimensions);
  });
}
