// find all elements that are content containers and load the content
// from them
ht.declare(
  '.contentContainer > form.contentParams, .contentContainer > a.contentParams',
  function () {
    if (navigator.cookieEnabled != false) {
      var self = $(this);
      var contentOpts = $(this).attr('action')
        ? {url: $(this).attr('action'), data: $(this).serializeArray()}
        : {url: $(this).attr('href')};
      var parent = $(this).parent();

      // we do not want to dispay errors for content
      contentOpts.complete = function (XMLHttpRequest, textStatus) {
        self.removeData('pendingAsync');
        var respText = XMLHttpRequest.responseText
          ? XMLHttpRequest.responseText
          : '';
        ht.localAjaxComplete.apply(
          this.successSelector ? $(this.successSelector) : self,
          [respText, textStatus, XMLHttpRequest]
        );
      };
      // check contentParamsOpts to see if we actually do want to show the loading div, since this is used in widgets in addition to ftl content places
      var contentParamsOpts = self.metadata().contentParamsOpts
        ? self.metadata().contentParamsOpts
        : {};
      contentOpts.showLoadingAnimation =
        contentParamsOpts.forceShowLoadingAnimation
          ? contentParamsOpts.forceShowLoadingAnimation
          : false;
      contentOpts.showOnSuccess = false;
      contentOpts.successSelector = parent;
      contentOpts.addToHistory = false;
      contentOpts = ht.getAjaxNavigateOpts(this, contentOpts);
      parent.data('contentOpts', contentOpts);
      $.ajax(contentOpts);
    }
  }
);
