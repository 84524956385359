ht.updateValidateErrors = function () {
  var context = $('form.applyValidate');
  context.find('label.error').each(function () {
    if ($(this).is(':visible')) {
      // make sure screen reader picks this up
      if ($(this).attr('role') != 'alert') {
        $(this).attr('role', 'alert');
      }
    } else {
      $(this).removeAttr('role');
    }
  });
  context.find(':input').each(function () {
    if ($(this).hasClass('error')) {
      $(this).addClass('errorStatus').attr('aria-invalid', 'true');
    } else {
      $(this).removeClass('errorStatus').removeAttr('aria-invalid', 'true');
    }
  });
};
