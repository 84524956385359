//used for mobile device to check screen orientation
ht.checkOrientation = function () {
  //create a timeout so logic is only ran when resize event finishes
  setTimeout(function () {
    //set screeb orientation based on width and height
    var screenOrientation = $(window).width() > $(window).height() ? 90 : 0;
    //if the scrennOrientation doesnt match whats in the body data attribute
    if (screenOrientation != $('body').data('orienatation')) {
      //assign it to the body data attribute
      $('body').data('orienatation', screenOrientation);
      //if pub/sub is supported
      if (ht.CentralExchange) {
        //fire off orientation change
        ht.CentralTopic('orientaitionChange').publish(screenOrientation);
      }
    }
  }, 250);
};
