//TODO: replace this with ht.toggle function (safe to remove once we remove calendaring)
$('#calendarFilter')
  .find('.timeRadio')
  .click(function () {
    if ($(this).val() == 'any') {
      $('li#timeFrame').removeClass('showInputs');
    } else {
      $('li#timeFrame').addClass('showInputs');
    }
  });
