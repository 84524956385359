$('body').on('click', '.stringAppend', function () {
  var stringToAppend = $(this).metadata().appendAttributes.stringToAppend;
  var newString =
    $($(this).metadata().appendAttributes.appendToID).val() +
    ' ' +
    stringToAppend +
    ' ';
  $($(this).metadata().appendAttributes.appendToID).val(newString);
  return false;
});
