// reloadable content
$(document).on(
  'reloadContent',
  'div.contentContainer.reloadable, span.contentContainer.reloadable',
  function () {
    if ($(this).data('contentOpts')) {
      $.ajax($(this).data('contentOpts'));
    }
  }
);

// reloadable managedNav
$(document).on('reloadContent', 'div.managedNav.reloadable', function () {
  if ($(this).data('currentAjaxUrl')) {
    $(this).trigger('ajaxNavigate', {url: $(this).data('currentAjaxUrl')});
  }
});
