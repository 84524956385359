/**
 * Find a Handlebars template that is present in the DOM, and compile it.
 * Optionally, pass data and render the compiled template in a specified element.
 *
 * @param {object} opts - Options
 *   @param {string} opts.source - jQuery selector for DOM source of Handlebars template.
 *   @param {object|string} [opts.data] - Data to pass to the Handlebars template. If using a string, use {{this}} in template
 *   @param {string} [opts.placementSelector] - jQuery selector for element to be populated
 *     with rendered template.
 *   @param {string} [opts.placementPosition] - Indication of where within the placementSelector
 *     to insert the rendered template. See ht.placement.
 * @returns {function} - Handlebars template
 *   (NOT a rendered string, but a function that will need to be called with a context).
 */
ht.compileHandlebars = function (opts) {
  if (!opts.source || !$(opts.source).length) {
    throw new Error(
      'ht.compileHandlebars requires a source option selecting a template in the DOM'
    );
  }

  var source = $(opts.source).html();
  var template = Handlebars.compile(source);

  return template;
};
