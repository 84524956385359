//this is designed to be a listener for form.findActiveTab ONLY
$('body').on('click', '.changeActiveTab', function () {
  //check to see if inputsOpts where put on the form for the hidden input
  var inputOpts = $('form.findActiveTab').metadata().activeTabOpts.inputOpts;
  if (inputOpts) {
    //use name define in inputOpts metadata
    $('form.findActiveTab input[name=' + inputOpts.name + ']').val(this.id);
  } else {
    //use default name
    $('form.findActiveTab input[name=activeTab]').val(this.id);
  }
});
