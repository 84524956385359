/**
 * Decide whether or not to hide a messageBox after a 5s delay,
 * then follow through on that decision.
 *
 * Called by ht.declare for every messageBox that is added to the page.
 * **If you create a messageBox directly with JS (not retrieved via AJAX)
 * and you want this autohiding functionality, you must call
 * ht.processDeclarations(yourNewMessage).**
 *
 * The messageBox will only hide if:
 * - It has the class 'success' and the option 'noAutoHide' is false.
 * - OR The option 'autoHide' is true.
 *
 * @param {HTMLElement} element - The messageBox to be flashed.
 */
ht.flashMessage = function (element) {
  // options set via data-messageBox-opts attribute
  var defaults = {
    noAutoHide: false,
    autoHide: false
  };
  var animOpts = {
    duration: 'slow',
    queue: false
  };
  var el = $(element);
  var opts = $.extend({}, defaults, el.data('messageboxOpts'));
  if (opts.autoHide || (el.hasClass('success') && !opts.noAutoHide)) {
    setTimeout(function () {
      el.find('.closeMessage').hide();
      el.fadeOut(animOpts).slideUp(animOpts);
    }, 5000);
  }
};
