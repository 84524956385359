// Return true/false depending on whether it is day light savings time.
Date.prototype.isDayLightSavingsTime = function () {
  // Daylight Savings Time always starts on the second Sunday of March and
  // ends on the first Sunday of November. See if the day is between these
  // dates.
  var startDaylightSavingsDate = new Date(
    '3/1/' + this.getFullYear() + ' 1:59:59 AM'
  );
  startDaylightSavingsDate = new Date(
    startDaylightSavingsDate.setDate(
      startDaylightSavingsDate.getDay() === 0
        ? 8
        : 15 - startDaylightSavingsDate.getDay()
    )
  );

  var endDaylightSavingsDate = new Date(
    '11/1/' + this.getFullYear() + ' 1:59:59 AM'
  );
  endDaylightSavingsDate = new Date(
    endDaylightSavingsDate.setDate(
      endDaylightSavingsDate.getDay() === 0
        ? 1
        : 8 - endDaylightSavingsDate.getDay()
    )
  );

  return this >= startDaylightSavingsDate && this <= endDaylightSavingsDate;
};
