// navigation buttons
$('body').on(
  'click',
  'button.navButton, input.navButton, a.navButton',
  function () {
    var opts = $(this).metadata(),
      url = opts.href,
      selector = opts.selector,
      resultSelector = opts.resultSelector ? ' ' + opts.resultSelector : '';

    if (opts.ajaxNavigateOpts) {
      opts = opts.ajaxNavigateOpts;
    } else {
      opts = {
        successSelector: opts.selector,
        resultSelector: opts.resultSelector,
        url: opts.href
      };
    }
    if (opts.successSelector) {
      $(opts.successSelector).trigger('ajaxNavigate', opts);
    } else {
      $(this).trigger('ajaxNavigate', opts);
    }
    return false;
  }
);
