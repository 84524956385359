// TODO: FLAGGED SELECTOR
//Check if capslock is on password field when key is pressed
$('input[type=password]').keypress(function (e) {
  //check if viewport meta tag is availible
  var isMobile =
    $('meta[name=viewport]').attr('content') != null ? true : false;
  //if the capslock error is already showing or if mobile device, return
  if ($(this).siblings('label').hasClass('error') || isMobile) {
    return;
  } else {
    //grab the letter/char
    var letter = String.fromCharCode(e.which);
    //get uppercase version of letter/char
    var uCase = letter.toUpperCase();
    //get lowercase version of letter/char
    var lCase = letter.toLowerCase();
    //is shift key pressed
    var shift = e.shiftKey;
    //is it a letter?
    if (uCase !== lCase) {
      //Capslock is on if:
      // 1)uppercase letter matches original letter but differs from the lowercase and shift is not pressed
      // 2)lowercase letter matches original letter and shift is pressed
      if (
        (uCase === letter && lCase !== letter && !shift) ||
        (lCase === letter && shift)
      ) {
        //add the warning message
        $(this)
          .parent()
          .append("<label class='error'>Caps Lock is on.</label>");

        //bind keydown event to detect if the capslock key is pressed, i.e. turned off
        $(this).on('keydown', function (e) {
          //is it the caps key?
          if (e.which == 20) {
            //remove error label
            $('label.error').remove();
            //unbind keydown to start process over
            $(this).off('keydown');
          }
        });
      }
    }
  }
});
