// any unhandled ajaxNavigate events should cause the whole page to
// change without ajax
$(document).bind('ajaxNavigate', function (evt, opts) {
  if (opts.form) {
    $(opts.form).trigger('submit');
  } else if (opts.url && $.trim(opts.url) != '') {
    window.location = ht.setSessionUrl(opts.url);
  } else {
    ht.log('unhandled ajaxNavigate event');
  }
});
