/*
* Wraps all <select />s inside <div class="ht-select-wrapper" /> so they can be styled (:before and :after
 pseudo attributes cannot be used on selects ).
* */
ht.declare('select:not([multiple])', function () {
  if (
    typeof htc != 'undefined' &&
    htc.global.shellMeta.hasCustomizableSelects
  ) {
    $(this).wrap("<div class='ht-select-wrapper'></div>");
  }
});
ht.declare('select[multiple]', function () {
  if (
    typeof htc != 'undefined' &&
    htc.global.shellMeta.hasCustomizableSelects
  ) {
    $(this).wrap("<div class='ht-selectmultiple-wrapper'></div>");
  }
});
