ht.memberIdMask = function () {
  var input = document.querySelectorAll(
    "[data-rule-masking-memberId='000000000-00']"
  )[0];

  if (!input) {
    return;
  }

  var memberIdMask = function memberIdMask(elm) {
    const LOWER_LIMIT_NUMBER = 48; // '0'
    const UPPER_LIMIT_NUMBER = 57; // '9'
    const HYPHEN_POSITION = 9;
    const MAX_MEMBER_ID_LEN = 12;

    elm.addEventListener('keypress', function (e) {
      // numbers only
      if (e.keyCode < LOWER_LIMIT_NUMBER || e.keyCode > UPPER_LIMIT_NUMBER) {
        e.preventDefault();
      }
      var len = elm.value.length;
      if (len === HYPHEN_POSITION) {
        elm.value += '-';
      }
      if (len >= MAX_MEMBER_ID_LEN) {
        e.preventDefault();
      }
    });
  };

  memberIdMask(input);
};
