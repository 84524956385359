// printLink will print the contents of a call to the url specified
// first make sure that the url reflects the needed params to trigger
// printing
$('body').on('click', 'a.printLink', function () {
  $('iframe#_printFrame').remove(); // remove old instances so that
  // the load will work correctly
  var printUrl = $(this).attr('href');
  var newQ = ht.parseUrlQuery(printUrl);
  printUrl = printUrl.split('?')[0] + '?' + $.param(newQ);
  /* Render print iframe with an absolute width and force it
   * off screen.
   * There was an issue with printing a page with google maps
   * loaded into a 100% relative width div in a 0 width iframe.
   * */
  $('body').append(
    '<iframe id="_printFrame" name="_printFrame" style="height:100%;width:' +
      window.innerWidth +
      'px;position: absolute;top: 0px; left: 0px; opacity:0; z-index:-1;" src="' +
      printUrl +
      '"/>'
  );
  $('#_printFrame').on('load', function () {
    //need to throw in a time out so IE has time to load
    setTimeout(function () {
      window.frames['_printFrame'].focus();
      window.frames['_printFrame'].print();
    }, 3000);
  });
  return false;
});
