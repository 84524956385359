ht.dateInputMask = function () {
  var input = document.querySelectorAll(
    "[data-rule-masking-MDY='MM/DD/YYYY']"
  )[0];
  var dateInputMask = function dateInputMask(elm) {
    const LOWER_LIMIT_NUMBER = 47;
    const UPPER_LIMIT_NUMBER = 57;
    const ALLOW_FIRST_SLASH_LEN = 1;
    const ALLOW_SECOND_SLASH_LEN = 3;
    const FIRST_ADD_SLASH_LEN = 2;
    const SECOND_ADD_SLASH_LEN = 5;
    const MAX_DATE_LEN = 10;

    elm.addEventListener('keypress', function (e) {
      // allow #'s only
      if (e.keyCode < LOWER_LIMIT_NUMBER || e.keyCode > UPPER_LIMIT_NUMBER) {
        e.preventDefault();
      }
      var len = elm.value.length;
      if (len !== ALLOW_FIRST_SLASH_LEN || len !== ALLOW_SECOND_SLASH_LEN) {
        if (e.keyCode == LOWER_LIMIT_NUMBER) {
          e.preventDefault();
        }
      }
      if (len === FIRST_ADD_SLASH_LEN || len === SECOND_ADD_SLASH_LEN) {
        elm.value += '/';
      }
      if (len >= MAX_DATE_LEN) {
        e.preventDefault();
      }
    });
  };

  dateInputMask(input);
};
