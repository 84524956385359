/***************************************************************************
 * getAjaxBaseOpts - function that returns barebones ajax opts object which
 * handles all of the details of submitting an ajax request to a remote
 * service.  This is intended for use where you are not navigating, but
 * just getting data to replace elements or other cases.  The optional opts
 * parameter is merged properly with the defaults.
 **************************************************************************/
ht.getAjaxBaseOpts = function (opts) {
  var defaultAjaxOpts = {
    dataType: 'html',
    async: true,
    cache: false,
    data: {ieFunTime: new Date().getTime()},
    iframe: false,
    evaluateScript: false,
    type: 'POST'
  };

  opts = $.extend({}, defaultAjaxOpts, opts);
  return opts;
};
