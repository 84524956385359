/* This function was designed for Cold Fusion Tab elements created by subnav.cfm

  Structure of subnav at the time of writing 2/11/19
    parentContainer
      - div #tabs
        - ul
          - li.activeState
*/
ht.toggleResponsiveTabClass = function (elm) {
  /* Make sure we are working with Tabs that are set to be responsive, and we are clicking on the active one */

  /* Get the direct parent container. This is used because there are mulitple tabs in a page with the same IDs */
  var parentContainer = elm
    .parent()
    .parent()
    .parent(); /* This is the simplest way to ensure we are getting the parent container three levels up */

  if (
    elm.hasClass('responsiveTab') &&
    elm.hasClass('activeState') &&
    parentContainer
  ) {
    /* Make sure we have the right parent, and that it's also set to be responsive */
    if (
      parentContainer.hasClass('isResponsiveTabs') ||
      parentContainer.hasClass('ht1-TabsContainer--responsive')
    ) {
      if (
        parentContainer.hasClass('isResponsiveTabs--closed') ||
        parentContainer.hasClass('ht1-is-closed')
      ) {
        parentContainer.removeClass('isResponsiveTabs--closed');
        parentContainer.removeClass('ht1-is-closed');
        parentContainer.toggleClass('isResponsiveTabs--opened');
        parentContainer.toggleClass('ht1-is-opened');
      } else {
        parentContainer.removeClass('isResponsiveTabs--opened');
        parentContainer.removeClass('ht1-is-opened');
        parentContainer.toggleClass('isResponsiveTabs--closed');
        parentContainer.toggleClass('ht1-is-closed');
      }
    }
  }
};
