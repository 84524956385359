//Converting HF plan benefits codes from unicode to text equivalent
ht.declare('.convertUnicodeToText', function () {
  if (htc.global.clientState.payer === '1069') {
    var currentElement = jQuery(this)[0];
    var currentText = currentElement.innerText;
    function unicodeToChar(text) {
      return text.replace(/\/[\d]{2,4}/g, function (match) {
        return String.fromCharCode(match.replace(/\//g, ''));
      });
    }
    var newText = unicodeToChar(currentText);
    currentElement.innerText = newText;
  }
});
