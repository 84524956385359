//add functionality to bootstrap ddlb
ht.declare('.dropdown > a', function () {
  var self = $(this);
  var parent = self.parent('.dropdown');
  //make bootstrap ddlb 508 compliant with tab
  self.on('keydown', function (e) {
    if (e.which == 9 && !self.parents('.dropdown').hasClass('open')) {
      self.trigger('click.bs.dropdown.data-api');
    }
  });
  //make bootstrap ddlb do hidey showey with tab
  parent.on('focusout', 'ul.dropdown-menu li:last-child', function () {
    self.trigger('click.bs.dropdown.data-api');
  });
});
