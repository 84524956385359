// ajaxForm
ht.declare('form.ajaxForm', function () {
  var opts = $(this).data('ajaxFormOpts') || $(this).metadata();
  if (
    !opts.successSelector &&
    (!opts.ajaxNavigateOpts || !opts.ajaxNavigateOpts.successSelector)
  ) {
    opts.successSelector = $(this).parents('div.managedNav:first');
    if (opts.successSelector.length != 1) {
      opts.successSelctor = undefined;
    }
  }
  $(this).ajaxForm(ht.getAjaxNavigateOpts($(this), opts));
  if (ht.CentralExchange) {
    ht.CentralTopic('ajaxFormReady').publish($(this));
  }
  return;
});
