/********************************************************************************
 * System-wide initialization.
 * The following code MUST RUN FIRST.  Do not put code above this section
 ********************************************************************************/
// first, make sure all of our requests are POSTs so that IE works
// also inject session security mechanisms
$.ajaxSetup({type: 'POST', beforeSend: ht.setSessionHeader});
ht.processDeclarations(document);
// Important for updating session status
if ($('.outagePage').length) {
  $.cookies.del('JSESSIONID');
}
try {
  if (ht.support.localStorage) {
    localStorage.setItem(
      'ht.CentralData.JSESSIONID',
      JSON.stringify($.cookies.get('JSESSIONID'))
    );
  }
} catch (e) {
  ht.log('localStorage error: ', e);
}
// start off with validation errors updated
ht.updateValidateErrors();
// We need an element to throw things like the autocomplete results into
// that is classed correctly
//FIXME:Can we just add this to the DOM without js???
document.body.appendChild(ht.smartuiLegacyAppendable);
