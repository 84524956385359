//FIXME: Does this truly need document.ready
// CSM1-5979: trim whitespace from email value for validation
$.validator.methods.email = function (value, element) {
  //begin ht custom code
  if (element && value) {
    value = value.trim();
    element.value = value;
  }
  //end ht custom code
  // http://emailregex.com/ HTML5 regex example chosen for accuracy and readability
  return (
    this.optional(element) ||
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  );
};
// if an equalTo element is also an email element, trim that value as well
$.validator.methods.equalTo = function (value, element, param) {
  //begin ht custom code
  if (element && value) {
    var thisElement = $(element);
    if (thisElement.data('rule-email')) {
      value = value.trim();
      element.value = value;
    }
  }
  //end ht custom code
  // Bind to the blur event of the target in order to revalidate whenever the target field is updated
  var target = $(param);
  if (this.settings.onfocusout && target.not('.validate-equalTo-blur').length) {
    target
      .addClass('validate-equalTo-blur')
      .on('blur.validate-equalTo', function () {
        $(element).valid();
      });
  }
  return value === target.val();
};
/***********************************************************************
 * validation related section
 * TODO:Add validation to its own file... perhaps additional_method.js, but we should probably keep ours seperate from theirs
 **********************************************************************/
// tax-id validator
$.validator.addMethod(
  'validateTaxID',
  function (value) {
    if (value == '') return true;
    if (value.match(/^[0-9]{4}$/)) return true;
    if (value.match(/^[0-9]{2}-?[0-9]{7}$/)) return true;
    return false;
  },
  ht.i18n.validate.StandardError
);
/*TODO: removing formatting because it's never used, we can look into adding this back,
 * but we would need to escape the regex before passing it to the format function ex:
 * data-rule-regexFormat='{"validateRegex":"^\\\\d&#39;{&#39;9&#39;}&#39; \\\\d&#39;{&#39;2&#39;}&#39;$"}'
 * vs
 * data-rule-regexFormat="^\\\\d'{'9'}' \\\\d'{'2'}'$"
 * ideally there should be a function for method for format and a method for regex
 * */
/*REMOVED LOGIC
 *  var validateFormat = params.validateFormat;
    var validateFormatReplacement = params.validateFormatReplacement;
    if((validateFormat != null && validateFormat != undefined)
        && (validateFormatReplacement != null && validateFormatReplacement != undefined)){
    value = value.replace(validateFormat, validateFormatReplacement);
    $(element).val(value);
}*/
// general regex replace validator
$.validator.addMethod(
  'regexFormat',
  function (value, element, regex) {
    if (regex != null && regex != undefined) {
      var testRegex = new RegExp(regex);
      return testRegex.test(value);
    }
    return true;
  },
  ht.i18n.validate.StandardError
);

//508 compliance, do not revalidate with every keystroke
$.validator.setDefaults({onkeyup: false});
// confirm that forms with data are not lost
$.validator.addMethod(
  'preventDataLoss',
  ht.validatePreventDataLoss,
  ht.i18n.validate.StandardError
);
// validate with an ajax post
$.validator.addMethod(
  'validateAsync',
  ht.validateAsync,
  ht.i18n.validate.StandardError
);
$.validator.addClassRules({
  tax: {validateTaxID: true},
  regexFormat: {regexFormat: true},
  preventDataLoss: {preventDataLoss: true},
  validateAsync: {validateAsync: true}
});
