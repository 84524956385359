ht.buildSingleMarker = function (opts) {
  loadGoogleMaps(this, function () {
    var map = new google.maps.Map(
      document.getElementById('modalMap'),
      ht.setMapDefaults()
    );
    var markerBaseOpts = ht.setMarkerDefaults();
    var markerInfo = ht.setMarkerInfo(
      {lat: opts.lat, long: opts.long, folderId: opts.folderId},
      opts.markerIndex,
      opts
    );

    // show home marker
    ht.homeMarkerCheck(map, markerBaseOpts.bounds, opts.folderId);

    var marker = new google.maps.Marker({
      map: map,
      position: markerInfo.latlng,
      icon: markerInfo.markerImg,
      label: markerInfo.label,
      shadow: markerBaseOpts.markerShadow
    });

    markerBaseOpts.bounds.extend(markerInfo.latlng);
    map.setCenter(markerInfo.latlng);

    // When redefining center, zoom gets reset on the default map, so we set it back to the default value 13
    // NOTE: Zoom = notches on the zoom bar... 13 is 13 clicks magnified, not 13 meters. This was very unclear in the API so wanted to make sure its
    // noted somewhere. kmb.
    map.setZoom(13);

    // if there is an infoId place its contents in the infoSection
    if (opts.infoId) {
      var modalDiv = $('#' + opts.infoId).html();

      // add click listener to show infowindow for markers
      google.maps.event.addListener(marker, 'click', function () {
        markerBaseOpts.infoWindow.setContent(modalDiv);
        markerBaseOpts.infoWindow.open(map, marker);
      });

      // show provider info at the bottom of modal
      $('#mapReset').hide();
      $('#' + opts.infoSection).html(modalDiv);
    }
  });
};
