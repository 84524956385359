// The following components are used to prevent CSRF attacks on the system
ht.setSessionUrl = function (targetUrl) {
  // don't modify this if it's just a placeholder
  var tokenVal = ht.getSessionToken();
  if (
    targetUrl.length == 0 ||
    targetUrl.charAt(0) == '#' ||
    tokenVal == undefined ||
    tokenVal == 'undefined'
  ) {
    return targetUrl;
  }
  var myHost = (window.location + '').match(ht.urlRegex)[8];
  /***************************************************************************
   * urlRegex - a regular expression that can be used to get various parts of
   * the url url = r[0]; protocol = r[2]; username = r[5]; password = r[7];
   * host = r[8] || ""; port = r[10]; pathname = r[11] || ""; querystring =
   * r[14] || ""; fragment = r[16] || "";
   **************************************************************************/
  var r = targetUrl.match(ht.urlRegex);

  var updatedUrl = targetUrl;
  // set blank to undefined for ternary operation below
  for (var i = 0; i < r.length; i++) {
    if (r[i] != undefined && $.trim(r[i]) == '') {
      r[i] = undefined;
    }
  }
  // note that we drop out username and password
  // we do not modify urls that end in .htm or .html because of healthwise
  if (
    (r[8] == undefined || r[8] == myHost) &&
    r[2] != 'javascript' &&
    r[2] != 'mailto' &&
    r[2] != 'tel' &&
    (r[11] == undefined || r[11].match(/\.html?$/) == null)
  ) {
    var newQ = $.extend({xsesschk: tokenVal}, ht.parseUrlQuery(targetUrl));
    updatedUrl =
      (r[2] ? r[2] + '://' : '') +
      (r[8] ? r[8] : '') +
      (r[10] ? ':' + r[10] : '') +
      (r[11] ? r[11] : '') +
      '?' +
      $.param(newQ) +
      (r[16] ? '#' + r[16] : '');
  }

  return updatedUrl;
};
