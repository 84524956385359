//changes the value of a select box on submit if the original selectors value has changed
ht.declare('input.changeSelectOnSubmit', function () {
  var self = $(this);
  var opts = self.metadata().sortOpts;
  var storedValue = $('body').data(self.attr('name'));

  $(this)
    .parents('form')
    .submit(function () {
      if (opts && self.val() != storedValue && self.val() != '') {
        ht.setDDLB(opts);
      }
    });
});
