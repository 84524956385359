$(document).on('change', '.toggleDisplayChange', function (e) {
  var opts =
    $(this).data('toggleDisplay') || $(this).metadata().toggleDisplayOpts;
  var sourceValue = e.target.value;
  var toggleLength = opts.toggleIfValueEqual.length;
  e.preventDefault();

  opts.elementToggle = false;
  if (toggleLength > 0) {
    //loop over the toggleIfValueEqual array to see if an element in the array matches the source value
    for (var i = 0; i < toggleLength; i++) {
      if (sourceValue == opts.toggleIfValueEqual[i]) {
        //if targetSelector is already hidden leave it hidden
        if ($(opts.targetSelector).is(':hidden')) {
          return;
        }
        opts.elementToggle = true;
        break;
      }
    }
  }
  ht.toggle(opts);

  if (opts.toggleClasses) {
    ht.toggleClass({
      toggleElement: opts.toggleElement,
      toggleClasses: opts.toggleClasses,
      hardToggle: opts.hardToggle ? opts.hardToggle : null
    });
  }
});
