//find active tab when form initially loads
//activeSelector example: #tabs ul li.activeState
//identifyingAttr can be a name or id to identify the active tab
ht.declare('form.findActiveTab', function () {
  var opts = $(this).metadata().activeTabOpts;
  if (opts) {
    var activeTab = $(opts.activeSelector).attr(opts.indentifyingAttr);
    //add additional class to all tabs to act as a listen to update the hidden (activeTab) form input
    $('#' + activeTab)
      .addClass('changeActiveTab')
      .siblings()
      .addClass('changeActiveTab');
    //default options for building hidden input
    var inputOpts = {
      type: 'hidden',
      name: 'activeTab',
      value: activeTab
    };

    //overwrite default options with opts passed via metadata
    inputOpts = $.extend({}, inputOpts, opts.inputOpts);
    //if the input exists overwrite the value,
    //otherwise build it and append it to the form
    if ($(this).has('input[name=' + inputOpts.name + ']').length > 0) {
      $('input[name=' + inputOpts.name + ']').val(activeTab);
    } else {
      $('<input>').attr(inputOpts).appendTo($(this));
    }
  }
});
