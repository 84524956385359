$('body').on('click', 'a.emailer', function () {
  var emailOpts = $(this).metadata().emailOpts;
  if (
    emailOpts != null &&
    emailOpts.subjectSelector != null &&
    emailOpts.bodySelector
  ) {
    var subjectText = $(emailOpts.subjectSelector).html();
    var bodyText = $(emailOpts.bodySelector).html().replace(/&amp;/g, '&');
    window.location.href =
      'mailto:?subject=' +
      subjectText +
      '&body=' +
      encodeURIComponent(bodyText);
  }
  return false;
});
